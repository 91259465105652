import { BaseService } from '../base.service';
import { CubeService } from '@/services/modules/cube.service';
import { ResponseWrapper, ErrorWrapper } from '../util';

export class BusinessPlanService extends BaseService {
  static get entity() {
    return 'businessPlan';
  }

  //CREATE NEW BUSINESSPLAN OBJECT USING DATA FROM CUBEJS
  static async calculateBusinessPlan(data) {
    const year = data.current_year;   //for this year's plan this is 2022 (2023 plan)
    //console.log(year);
    
    try {

        //getting data from cubes
        //console.log('getting data from cubes..');
        const [ 
            businessPlanSection,  
            businsessPlanFranchiseSales, 
            businessPlanAgentCount,  

            businessPlanKPI, 
            businessPlanFranchiseKPI, 
            businessPlanAgentCountKPI,

            viewOnlyModeSectionData,
            viewOnlyModeMonthlyAgentCountData,
            viewOnlyModeNewAndTerminatedAgentCountData,
            viewOnlyModeFranchiseSalesData,
            viewOnlyModeOfficeCountData
          ] = await Promise.all([
          //Section Data
          await CubeService.getBusinessPlanSectionDataByRegion(data.region_id, year-1),
          await CubeService.getBusinessPlanFranchiseSalesDataByRegion(data.region_id, year-1),
          await CubeService.getBusinessPlanAgentCountByRegion(data.region_id, year-1),
          //KPI Data
          await CubeService.getBusinessPlanKPIDataByRegion(data.region_id, year-3, year-2),
          await CubeService.getBusinessPlanFranchiseSalesKPIDataByRegion(data.region_id, year-3, year-2),
          await CubeService.getBusinessPlanAgentCountKPIDataByRegion(data.region_id, year-3, year-2),
          //view only mode data
          await CubeService.getBusinessPlanSectionDataByRegion(data.region_id, year),
          await CubeService.getBusinessPlanMonhtlyAgentData(data.region_id, year),
          await CubeService.getBusinessPlanAgentData(data.region_id, year),
          await CubeService.getBusinessPlanFranchiseSalesDataByRegion(data.region_id, year),
          await CubeService.getBusinessPlanOfficeCountData(data.region_id, year)


          //await CubeService.getBusinessPlanAgentCountOfficeCountKPIDataByRegion(data.region_id, year-3, year-2)
        ]);

        //console.log('kpi values:');
        //console.log(businessPlanKPI);

        //inject data received from cubes into backend data
         let dataWithCubeData = injectCubeData(data, businessPlanSection, businsessPlanFranchiseSales, businessPlanAgentCount, businessPlanKPI, businessPlanFranchiseKPI);
          
        //format values to the format the frontend component requires
        let formatted = formatValues(dataWithCubeData, viewOnlyModeSectionData, viewOnlyModeMonthlyAgentCountData, viewOnlyModeNewAndTerminatedAgentCountData, viewOnlyModeFranchiseSalesData, viewOnlyModeOfficeCountData);  
        //console.log('this is the formatted data:');
        //console.log(formatted);      
        return formatted;
      
      
    } catch (error) {
      console.log('error getting data from cubes...');
      console.log(error);
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static formatValuesPreSave(data, sectionData, kpiData){ 
    try{
      
      //console.log('data', data);
      //console.log('sectionData:', sectionData);
      //console.log('kpiData', kpiData);
    
      const currentYear = data.current_year -1;
      const nextYear = data.current_year;
    
      //merge data in to the 'data' format that the backend accepts
      //gci
      data.gci_current_year = constructBusinessPlanSection(data.gci_current_year, sectionData["GCI"][currentYear]);
      data.gci_next_year = constructBusinessPlanSection(data.gci_next_year, sectionData["GCI"][nextYear]);
      data.gci_yoy_growth_perc = constructBusinessPlanSection(data.gci_yoy_growth_perc, sectionData["GCI"]['YoY Growth %']);
    
      //transactions
      data.trans_current_year = constructBusinessPlanSection(data.trans_current_year, sectionData["Transactions"][currentYear]);
      data.trans_next_year = constructBusinessPlanSection(data.trans_next_year, sectionData["Transactions"][nextYear]);
      data.trans_yoy_growth_perc = constructBusinessPlanSection(data.trans_yoy_growth_perc, sectionData["Transactions"]['YoY Growth %']);
    
      //franchise sales
      data.franchise_sales_current_year = constructBusinessPlanSection(data.franchise_sales_current_year, sectionData['Franchise Sales'][currentYear]);
      data.franchise_sales_next_year = constructBusinessPlanSection(data.franchise_sales_next_year, sectionData['Franchise Sales'][nextYear]);
      data.franchise_sales_yoy_growth = constructBusinessPlanSection(data.franchise_sales_yoy_growth, sectionData['Franchise Sales']['YoY Growth']);
    
      //offices
      data.office_count_current_year = constructBusinessPlanSection(data.office_count_current_year, sectionData['Office Count (Open)'][currentYear]);
      data.office_count_openings = constructBusinessPlanSection(data.office_count_openings, sectionData['Office Count (Open)']['Openings']);
      data.office_count_terminations = constructBusinessPlanSection(data.office_count_terminations, sectionData['Office Count (Open)']['Terminations']);
      data.office_count_next_year = constructBusinessPlanSection(data.office_count_next_year, sectionData['Office Count (Open)'][nextYear]);
      data.office_count_yoy_growth = constructBusinessPlanSection(data.office_count_yoy_growth, sectionData['Office Count (Open)']['YoY Growth']);
    
      //agents
      data.agent_count_current_year = constructBusinessPlanSection(data.agent_count_current_year, sectionData['Agent Count'][currentYear]);
      data.agent_count_recruited = constructBusinessPlanSection(data.agent_count_recruited, sectionData['Agent Count']['Recruited']);
      data.agent_count_terminated = constructBusinessPlanSection(data.agent_count_terminated, sectionData['Agent Count']['Terminated']);
      data.agent_count_next_year = constructBusinessPlanSection(data.agent_count_next_year, sectionData['Agent Count'][nextYear]);
      data.agent_count_yoy_growth = constructBusinessPlanSection(data.agent_count_yoy_growth, sectionData['Agent Count']['YoY Growth']);
    
      //kpi stuff
      //production
      data.production_gci_kpi = kpiFromArray(data.production_gci_kpi, kpiData["Production"]["GCI"]);
      data.production_transactions_kpi = kpiFromArray(data.production_transactions_kpi, kpiData["Production"]["Transactions"]);
      data.production_gci_per_agent_kpi = kpiFromArray(data.production_gci_per_agent_kpi, kpiData["Production"]["GCI/Agent"]);
      data.production_ta_per_agents_kpi = kpiFromArray(data.production_ta_per_agents_kpi, kpiData["Production"]["TA/Agents"])
      data.production_gci_per_office_kpi = kpiFromArray(data.production_gci_per_office_kpi, kpiData["Production"]['GCI/Office']);
      data.production_agents_per_office_kpi = kpiFromArray(data.production_agents_per_office_kpi, kpiData["Production"]["Agents/Office"]);
      
      //offices
      data.offices_office_count_kpi = kpiFromArray(data.offices_office_count_kpi, kpiData["Offices"]["Office Count"]);
      data.offices_terminations_kpi = kpiFromArray(data.offices_terminations_kpi, kpiData["Offices"]["Terminations"]);
      data.offices_franchise_sales_kpi = kpiFromArray(data.offices_franchise_sales_kpi, kpiData["Offices"]['Franchise Sales']);
      data.offices_net_growth_kpi = kpiFromArray(data.offices_net_growth_kpi, kpiData["Offices"]['Net Growth']);
      data.offices_sales_per_net_growth_kpi = kpiFromArray(data.offices_sales_per_net_growth_kpi, kpiData["Offices"]['Sales/Net Growth']);
    
      //agents
      data.agents_agent_count_kpi = kpiFromArray(data.agents_agent_count_kpi, kpiData["Agents"]["Agent Count"]);
      data.agents_recruited_kpi = kpiFromArray(data.agents_recruited_kpi, kpiData["Agents"]["Recruited"]);
      data.agents_terminated_kpi = kpiFromArray(data.agents_terminated_kpi, kpiData["Agents"]['Terminated']);
      data.agents_net_growth_kpi = kpiFromArray(data.agents_net_growth_kpi, kpiData["Agents"]['Net Growth']);
      data.agents_rec_per_net_growth_kpi = kpiFromArray(data.agents_rec_per_net_growth_kpi, kpiData["Agents"]['Rec/Net Growth']);
    
      //now the data should be in the format that the backend expects
      //console.log('data has been formatted:');
      //console.log(data);
      return data;
    }
    catch(error){
      console.log('error getting data from cubes...');
      console.log(error);
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
    
  }

  static getSelectedYear() {
    try{
      // get date from localstore selectedYear
      let selectedYear = localStorage.getItem('selectedYear');
      if(selectedYear == null || selectedYear == undefined || (selectedYear <= 0))
        selectedYear = new Date().getFullYear();
  
      return selectedYear;
    }
    catch(error){
      console.log('error at getSelectedYear in businessPlan service');
      throw error;
    }
  }
}

//this function is for formatting the data from the backend to what Farai's component expects
function formatValues(data, viewOnlyModeSectionData, viewOnlyModeAgentCountData, viewOnlyModeNewAndTerminatedAgentCountData, viewOnlyModeFranchiseSalesData, viewOnlyModeOfficeCountData ){
  const formattedSectionData = {};

  //start values for the business plan
  const nextYear = data.current_year;
  const currentYear = data.current_year -1;
  

  const GCI = {};
  const transactions = {};
  const franchiseSales = {};
  const officeCountOpen = {};
  const agentCount = {};

  //construct the gci object
  GCI[currentYear] = constructBusinessPlanSectionForUI(data.gci_current_year)
  GCI[nextYear] = constructBusinessPlanSectionForUI(data.gci_next_year);
  GCI['YoY Growth %'] = constructBusinessPlanSectionForUI(data.gci_yoy_growth_perc);

  //construct the transactions object
  transactions[currentYear] = constructBusinessPlanSectionForUI(data.trans_current_year);{
  transactions[nextYear] = constructBusinessPlanSectionForUI(data.trans_next_year);
  transactions['YoY Growth %'] = constructBusinessPlanSectionForUI(data.trans_yoy_growth_perc);

  //construct 
  franchiseSales[currentYear] = constructBusinessPlanSectionForUI(data.franchise_sales_current_year);
  franchiseSales[nextYear] = constructBusinessPlanSectionForUI(data.franchise_sales_next_year);
  franchiseSales['YoY Growth'] = constructBusinessPlanSectionForUI(data.franchise_sales_yoy_growth);

  officeCountOpen[currentYear] = constructBusinessPlanSectionForUI(data.office_count_current_year);
  officeCountOpen['Openings'] = constructBusinessPlanSectionForUI(data.office_count_openings);
  officeCountOpen['Terminations'] = constructBusinessPlanSectionForUI(data.office_count_terminations);
  officeCountOpen[nextYear] =  constructBusinessPlanSectionForUI(data.office_count_next_year);
  officeCountOpen['YoY Growth'] = constructBusinessPlanSectionForUI(data.office_count_yoy_growth); 

  agentCount[currentYear] =constructBusinessPlanSectionForUI(data.agent_count_current_year);
  agentCount['Recruited'] = constructBusinessPlanSectionForUI(data.agent_count_recruited); 
  agentCount['Terminated'] = constructBusinessPlanSectionForUI(data.agent_count_terminated); 
  agentCount[nextYear] = constructBusinessPlanSectionForUI(data.agent_count_next_year); 
  agentCount['YoY Growth'] = constructBusinessPlanSectionForUI(data.agent_count_yoy_growth); 

  //
  formattedSectionData['GCI'] = GCI;
  formattedSectionData['Transactions'] = transactions;
  formattedSectionData['Franchise Sales'] = franchiseSales;
  formattedSectionData['Office Count (Open)'] = officeCountOpen;
  formattedSectionData['Agent Count'] = agentCount;
  
  const formattedKpiData = {};
  
  //construct the data objects
  const productionKpi = {};
  
  productionKpi['GCI'] = constructArrayFromObject(data.production_gci_kpi);
  productionKpi['Transactions'] = constructArrayFromObject(data.production_transactions_kpi);
  productionKpi['GCI/Agent'] = constructArrayFromObject(data.production_gci_per_agent_kpi);
  productionKpi['TA/Agents'] = constructArrayFromObject(data.production_ta_per_agents_kpi);
  productionKpi['GCI/Office'] = constructArrayFromObject(data.production_gci_per_office_kpi);
  productionKpi['Agents/Office'] = constructArrayFromObject(data.production_agents_per_office_kpi);

  const officesKpi = {};

  officesKpi['Office Count'] = constructArrayFromObject(data.offices_office_count_kpi);
  officesKpi['Franchise Sales'] = constructArrayFromObject(data.offices_franchise_sales_kpi);
  officesKpi['Terminations'] = constructArrayFromObject(data.offices_terminations_kpi);
  officesKpi['Franchise Sales'] = constructArrayFromObject(data.offices_franchise_sales_kpi);
  officesKpi['Net Growth'] = constructArrayFromObject(data.offices_net_growth_kpi);
  officesKpi['Sales/Net Growth'] = constructArrayFromObject(data.offices_sales_per_net_growth_kpi);


  const agentsKpi = {};

  agentsKpi['Agent Count'] = constructArrayFromObject(data.agents_agent_count_kpi);
  agentsKpi['Recruited'] = constructArrayFromObject(data.agents_recruited_kpi);
  agentsKpi['Terminated'] = constructArrayFromObject(data.agents_terminated_kpi);
  agentsKpi['Net Growth'] = constructArrayFromObject(data.agents_net_growth_kpi);
  agentsKpi['Rec/Net Growth'] = constructArrayFromObject(data.agents_rec_per_net_growth_kpi);


  formattedKpiData['Production'] = productionKpi;
  formattedKpiData['Offices'] = officesKpi;
  formattedKpiData['Agents'] = agentsKpi;

  //the view only mode has its own strucutre:
  /*
  {
    "ID": null,
    "year": "2023",
    "month": 2,
    "planGCI": null,
    "planAgentsRecruited": null,
    "planAgentsTerminated": null,
    "planAgentCount": null,
    "planFranchiseSales": null,
    "planOfficesClosed": null,
    "planOfficesOpened": null,
    "planGCIperAgent": null,

    "actualGCI": 0,                 x

    "actualAgentsRecruited": "0",   x 
    "actualAgentsTerminated": "0",  x
    "actualAgentCount": 160,        x      

    "actualFranchiseSales": 0,      x 

    "actualOfficesClosed": "0",     
    "actualOfficesOpened": "0",
    "actualGCIperAgent": "",
    "regionID": "29"
  },
  */
  let viewOnlyData = [];

  //console.log('formatting values for view only mode');
  //console.log('viewOnlySectionData', viewOnlyModeSectionData);
  //console.log('viewOnlyModeAgentCountData', viewOnlyModeAgentCountData);
  //console.log('viewOnlyModeNewAndTerminatedAgentCountData', viewOnlyModeNewAndTerminatedAgentCountData);
  //console.log('viewOnlyModeFranchiseSalesData', viewOnlyModeFranchiseSalesData);
  //console.log('viewOnlyModeOfficeCountData', viewOnlyModeOfficeCountData);
  
  //
  for(var i = 0; i < 12; i++){

    let month = convertMonthNumberToShorthand((i+1).toString());

    let gciActual = viewOnlyModeSectionData[i] == undefined ? null : viewOnlyModeSectionData[i]["BusinessplanRegionCube.gci"];
    let agentCount = viewOnlyModeAgentCountData[i] == undefined ? null : viewOnlyModeAgentCountData[i]["BusinessplanRegionCube.agentmonthcount"];
    viewOnlyData.push({
      regionID: data.region_id,
      ID: null,
      month: i+1,                 //Farai's component requires 1 indexed
      year: data.current_year,
      //business plan 'planned values
      planGCI: Number(data.gci_next_year[month]),
      planAgentsRecruited: Number(data.agent_count_recruited[month]),
      planAgentsTerminated: Number(data.agent_count_terminated[month]),
      planAgentCount : Number(data.agent_count_next_year[month]),
      planFranchiseSales: Number(data.franchise_sales_next_year[month]),
      planOfficesClosed: Number(data.office_count_terminations[month]),
      planOfficesOpened: Number(data.office_count_openings[month]),
      planGCIperAgent : Math.round(data.gci_next_year[month]/data.agent_count_next_year[month]),
      //cube actual values
      actualGCI: Number(gciActual),                 
      actualAgentsRecruited: viewOnlyModeNewAndTerminatedAgentCountData[i] == undefined ? null : Number(viewOnlyModeNewAndTerminatedAgentCountData[i]["billableTitleChanges.newAgentCount"]),   
      actualAgentsTerminated:  viewOnlyModeNewAndTerminatedAgentCountData[i] == undefined ? null : Number(viewOnlyModeNewAndTerminatedAgentCountData[i]["billableTitleChanges.terminationAgentCount"]),  
      actualAgentCount: agentCount,              
      actualFranchiseSales: viewOnlyModeFranchiseSalesData[i] == undefined  ? null : Number(viewOnlyModeFranchiseSalesData[i]["OfficeStatusChangesCube.processedCount"]),       
      actualOfficesOpened: viewOnlyModeOfficeCountData[i] == undefined ? null : Number(viewOnlyModeOfficeCountData[i]["OfficeStatusChangesCube.openingCount"]),     
      actualOfficesClosed: viewOnlyModeOfficeCountData[i] == undefined ? null : Number(viewOnlyModeOfficeCountData[i]["OfficeStatusChangesCube.terminationCount"]),  
      actualGCIperAgent: Math.round(gciActual/agentCount)

    })

  }

  
  //this is the return data
  return {
    originalData : data,
    sectionData: formattedSectionData,
    kpiData : formattedKpiData,
    viewOnlyData : viewOnlyData
  };

}
//this function formats the values in the store to the value that the backend accepts


}



//helper function to transform the data from the backend to the required format
function constructArrayFromObject(dataObject){
  try{
    const array = [];
    for(var i = 0; i < dataObject.data.length; i++){
      array.push({
        year : dataObject.data[i].year,
        value : dataObject.data[i].value,
        percent : dataObject.data[i].percent
      })
    }
    return array;
  }
  catch(error){
    console.log('error contructing array in businessPlan service');
    throw error;
  }
}

function kpiFromArray(kpiObject, array){
  try{
    const objToReturn  = kpiObject;

    for(var i = 0; i < objToReturn.data.length; i++){
        //year value in the object, find corresponding item in array
        let year = objToReturn.data[i].year;

        const arrayObj = array.find(element => element.year == year);
        if(arrayObj){
          objToReturn.data[i].value = arrayObj.value;
          objToReturn.data[i].percent = arrayObj.percent;
        }
        
    }
    
    return objToReturn;
  }
  catch(error){
    console.log('error at kpiFromArray in businessPlan service');
    throw error;
  }
}

function constructBusinessPlanSectionForUI(sectionData){
  try{

    const data = {
    "January" : sectionData.jan,
    "February": sectionData.feb,
    "March" : sectionData.mar,
    "April" : sectionData.apr,
    "May" : sectionData.may,
    "June": sectionData.jun,
    "July": sectionData.jul,
    "August": sectionData.aug,
    "September": sectionData.sept,
    "October": sectionData.oct,
    "November": sectionData.nov,
    "December": sectionData.dec
    };

    return data;
    }
  catch(error){
    console.log('error at constructBusinessPlanSectionForUI in businessPlan service');
    throw error;
  }
}

function constructBusinessPlanSection(sectionData, uiData){
  try{
  
    const dataToFormat = sectionData;
    dataToFormat.jan = uiData['January'];
    dataToFormat.feb = uiData['February'];
    dataToFormat.mar = uiData['March'];
    dataToFormat.apr = uiData['April'];
    dataToFormat.may = uiData['May'];
    dataToFormat.jun = uiData['June'];
    dataToFormat.jul = uiData['July'];
    dataToFormat.aug = uiData['August'];
    dataToFormat.sept = uiData['September'];
    dataToFormat.oct = uiData['October'];
    dataToFormat.nov = uiData['November'];
    dataToFormat.dec = uiData['December'];

    return dataToFormat;
    }
  catch(error){
    console.log('error at constructBusinessPlanSection in businessPlan service');
    throw error;
  }
}

function injectCubeData(data, sectionData, franchiseData, agentCountData, kpiData, kpiFranchiseData){
  try{


    //console.log('injecting cube data:');
    
    //console.log('data', data);
    //console.log('sectionData', sectionData);
    //console.log('franchiseData', franchiseData);
    //console.log('agentCountData', agentCountData);
    //console.log('kpiData', kpiData);
    //console.log('kpiFranchiseData', kpiFranchiseData);

    //do not overwrite data from these three months in the businessplan
    const monthsToSkip = ['oct','nov','dec'];

    //if any of the cube datasets come back as undefined, then don't calculate anything, return the data from the db
    if((sectionData == undefined)||(franchiseData == undefined) || (kpiData == undefined) || (kpiFranchiseData == undefined) ){
      //console.log('there is no cube data...');
      
      if(sectionData == undefined){
        console.log('sectionData is undefined..');
      }

      if(franchiseData == undefined){
        console.log('franchiseData is undefined..');
      }

      if(kpiData == undefined){
        console.log('kpiData is undefined..');
      }

      if(kpiFranchiseData == undefined){
        console.log('kpiFranchiseData is undefined..');
      }
      return data;
    }

    //1) first, do the section data
    for(var i = 0; i < sectionData.length; i ++){
      //month is now 'n value from 01 to 12 
      let month = sectionData[i]["BusinessplanRegionCube.transactiondate.month"].substring(5,7);
      let monthName = convertMonthNumberToShorthand(month);

      //skip october/november/december
      if(monthName && (monthsToSkip.includes(monthName) == false)){
        data.gci_current_year[monthName] = sectionData[i]["BusinessplanRegionCube.gcieur"];
        data.trans_current_year[monthName] = sectionData[i]["BusinessplanRegionCube.transaction"];
        data.office_count_current_year[monthName] = sectionData[i]["OfficeCountCube.officecount"];
      }
    }
  
    //2) do the franchise sales: the franchise sales is not the same as the "PerformanceCube.gciForPeriod" in step 1)
    //   the calculation is done differently in cubes for this
    for(var j = 0; j < franchiseData.length; j++){
      //month is now 'n value from 01 to 12 
      let month = franchiseData[j]["OfficeStatusChangesCube.statusdate.month"].substring(5,7);
      let monthName = convertMonthNumberToShorthand(month);

      //skip october/november/december
      if(monthName && (monthsToSkip.includes(monthName) == false)){
        data.franchise_sales_current_year[monthName] = franchiseData[j]["OfficeStatusChangesCube.processedCount"];
      }
    }

    //3) do the agent count
    for(var k = 0; k < agentCountData.length; k++){
      //month is now 'n value from 01 to 12 
      let month = agentCountData[k]["BusinessplanRegionCube.transactiondate.month"].substring(5,7);
      let monthName = convertMonthNumberToShorthand(month);

      //skip october/november/december
      if(monthName && (monthsToSkip.includes(monthName) == false)){
        data.agent_count_current_year[monthName] = agentCountData[k]["BusinessplanRegionCube.agentmonthcount"];
      }
    }

    //KPI data
    //office kpi calculations
    const kpiOfficeCountPreviousYear = kpiData[0] == undefined ? null : kpiData[0]["BusinessplanRegionCube.officeyearcnt"];
    const kpiOfficeCountCurrentYear = kpiData[1] == undefined ? null : kpiData[1]["BusinessplanRegionCube.officeyearcnt"];
    const kpiOfficeCountPercentageChange = (100*(kpiOfficeCountCurrentYear - kpiOfficeCountPreviousYear)/kpiOfficeCountPreviousYear);

    
    //OFFICE Count Data
    //if there is no franchise sales kpi data, set value to zero 
    let kpiOfficeFranchiseSalesPreviousYear = 0;
    let kpiOfficeFranchiseSalesCurrentYear = 0;
    if(kpiFranchiseData.length != 0){
      kpiOfficeFranchiseSalesPreviousYear = kpiFranchiseData[0]["OfficeStatusChangesCube.processedCount"];
      kpiOfficeFranchiseSalesCurrentYear = !kpiFranchiseData[1] ? 0 : kpiFranchiseData[1]["OfficeStatusChangesCube.processedCount"];
    }

    const kpiOfficeFranchiseSalesPercentageChange = (100*(kpiOfficeFranchiseSalesCurrentYear - kpiOfficeFranchiseSalesPreviousYear)/kpiOfficeFranchiseSalesPreviousYear);

    const kpiOfficeTerminationsPreviousYear = kpiData[0] == undefined ? null : kpiData[0]["BusinessplanRegionCube.offterminationcntForPeriod"];
    const kpiOfficeTerminationsCurrentYear = kpiData[1] == undefined ? null : kpiData[1]["BusinessplanRegionCube.offterminationcntForPeriod"];
    const kpiOfficeTerminationsPercentageChange = (100*(kpiOfficeTerminationsCurrentYear - kpiOfficeTerminationsPreviousYear)/kpiOfficeTerminationsPreviousYear);

    const kpiOfficeNetGrowth = kpiData[1] == undefined ? null : kpiData[1]["OfficeCountCube.numberOfOfficesYearAverage"] - kpiData[0]["OfficeCountCube.numberOfOfficesYearAverage"];

    const kpiOfficeSalesPerNetGrowth = kpiOfficeFranchiseSalesCurrentYear / kpiOfficeNetGrowth;
  
    //agents kpi calculations find first entry for business plan kpi
    const kpiAgentCountPreviousYear = kpiData[0] == undefined ? null : kpiData[0]["BusinessplanRegionCube.agentyearcnt"];
    const kpiAgentCountCurrentYear =  kpiData[1] == undefined ? null : kpiData[1]["BusinessplanRegionCube.agentyearcnt"];
    const kpiAgentCountPercentageChange = (100*(kpiAgentCountCurrentYear - kpiAgentCountPreviousYear)/kpiAgentCountPreviousYear);


    const kpiAgentsRecruitedPreviousYear =  kpiData[0] == undefined ? null :kpiData[0]["BusinessplanRegionCube.agntnewcntForPeriod"];
    const kpiAgensRecruitedCurrentYear =  kpiData[1] == undefined ? null : kpiData[1]["BusinessplanRegionCube.agntnewcntForPeriod"];
    const kpiAgentsRecruitedPercentageChange = (100*(kpiAgensRecruitedCurrentYear - kpiAgentsRecruitedPreviousYear)/kpiAgentsRecruitedPreviousYear);

    const kpiAgentsTerminatedPreviousYear =  kpiData[0] == undefined ? null : kpiData[0]["BusinessplanRegionCube.agntterminationcntForPeriod"];
    const kpiAgensTerminatedCurrentYear =  kpiData[1] == undefined ? null : kpiData[1]["BusinessplanRegionCube.agntterminationcntForPeriod"];
    const kpiAgentsTerminatedPercentageChange = (100*(kpiAgensTerminatedCurrentYear - kpiAgentsTerminatedPreviousYear)/kpiAgentsTerminatedPreviousYear);

    const kpiAgentsNetGrowth = kpiAgentCountCurrentYear - kpiAgentCountPreviousYear;
    const kpiAgentsRecPerNetGrowth = kpiAgensRecruitedCurrentYear / kpiAgentsNetGrowth;

    //productivity kpi calculations
    const kpiProductivityGciPreviousYear = kpiData[0] == undefined ? null : kpiData[0]["BusinessplanRegionCube.gciEurForPeriod"];
    const kpiProductivityGciCurrentYear =  kpiData[1] == undefined ? null : kpiData[1]["BusinessplanRegionCube.gciEurForPeriod"];
    const kpiProductivityGciPercentageChange = (100*(kpiProductivityGciCurrentYear - kpiProductivityGciPreviousYear)/kpiProductivityGciPreviousYear);

    const kpiProductivityTransactionsPreviousYear =  kpiData[0] == undefined ? null : kpiData[0]["BusinessplanRegionCube.transactionForPeriod"];
    const kpiProductivityTransactionsCurrentYear =   kpiData[1] == undefined ? null : kpiData[1]["BusinessplanRegionCube.transactionForPeriod"];
    const kpiProductivityTransactionsPercentageChange = (100*(kpiProductivityTransactionsCurrentYear - kpiProductivityTransactionsPreviousYear)/kpiProductivityTransactionsPreviousYear);

    const kpiProductivityGciPerAgentPreviousYear = (kpiProductivityGciPreviousYear / kpiAgentCountPreviousYear);
    const kpiProductivityGciPerAgentCurrentYear = (kpiProductivityGciCurrentYear / kpiAgentCountCurrentYear);
    const kpiProductivityGciPerAgentPercentageChange =(100*(kpiProductivityGciPerAgentCurrentYear - kpiProductivityGciPerAgentPreviousYear)/kpiProductivityGciPerAgentPreviousYear);

    const kpiProductivityTaPerAgentPreviousYear = (kpiProductivityTransactionsPreviousYear / kpiAgentCountPreviousYear);
    const kpiProductivityTaPerAgentCurrentYear = (kpiProductivityTransactionsCurrentYear / kpiAgentCountCurrentYear);
    const kpiProductivityTaPerAgentPercentageChange = (100*(kpiProductivityTaPerAgentCurrentYear - kpiProductivityTaPerAgentPreviousYear)/kpiProductivityTaPerAgentPreviousYear);

    const kpiProductivityGciPerOfficePreviousYear = (kpiProductivityGciPreviousYear /kpiOfficeCountPreviousYear);
    const kpiProductivityGciPerOfficeCurrentYear = (kpiProductivityGciCurrentYear / kpiOfficeCountCurrentYear);
    const kpiProductivityGciPerOfficePercentChange = (100*(kpiProductivityGciPerOfficeCurrentYear - kpiProductivityGciPerOfficePreviousYear)/kpiProductivityGciPerOfficePreviousYear);

    const kpiProductivityAgentsPerOfficePreviousYear = (kpiAgentCountPreviousYear / kpiOfficeCountPreviousYear);
    const kpiProductivityAgentsPerOfficeCurrentYear = (kpiAgentCountCurrentYear / kpiOfficeCountCurrentYear);
    const kpiProductivityAgentsPerOfficePercentChange = (100*(kpiProductivityAgentsPerOfficeCurrentYear - kpiProductivityAgentsPerOfficePreviousYear)/kpiProductivityAgentsPerOfficePreviousYear);

    //replace the data
    //Productivity KPI
    //GCI
    data.production_gci_kpi.data[0].value = kpiProductivityGciCurrentYear;
    data.production_gci_kpi.data[0].percent = kpiProductivityGciPercentageChange;
    //Transactions
    data.production_transactions_kpi.data[0].value = kpiProductivityTransactionsCurrentYear;
    data.production_transactions_kpi.data[0].percent = kpiProductivityTransactionsPercentageChange;
    //GCI/Agent
    data.production_gci_per_agent_kpi.data[0].value = kpiProductivityGciPerAgentCurrentYear;
    data.production_gci_per_agent_kpi.data[0].percent = kpiProductivityGciPerAgentPercentageChange;
    //TA/Agent
    data.production_ta_per_agents_kpi.data[0].value = kpiProductivityTaPerAgentCurrentYear;
    data.production_ta_per_agents_kpi.data[0].percent = kpiProductivityTaPerAgentPercentageChange;
    //GCI/Office
    data.production_gci_per_office_kpi.data[0].value = kpiProductivityGciPerOfficeCurrentYear;
    data.production_gci_per_office_kpi.data[0].percent = kpiProductivityGciPerOfficePercentChange;
    //Agents/Office
    data.production_agents_per_office_kpi.data[0].value = kpiProductivityAgentsPerOfficeCurrentYear;
    data.production_agents_per_office_kpi.data[0].percent = kpiProductivityAgentsPerOfficePercentChange;

    //Offices KPI
    //Office Count
    data.offices_office_count_kpi.data[0].value = kpiOfficeCountCurrentYear;
    data.offices_office_count_kpi.data[0].percent = kpiOfficeCountPercentageChange;
    //Franchise Sales
    data.offices_franchise_sales_kpi.data[0].value = kpiOfficeFranchiseSalesCurrentYear;
    data.offices_franchise_sales_kpi.data[0].percent = kpiOfficeFranchiseSalesPercentageChange;
    //Terminations
    data.offices_terminations_kpi.data[0].value = kpiOfficeTerminationsCurrentYear;
    data.offices_terminations_kpi.data[0].percent = kpiOfficeTerminationsPercentageChange;
    //Net Growth
    data.offices_net_growth_kpi.data[0].value = kpiOfficeNetGrowth;
    //Salers/net Growth
    data.offices_sales_per_net_growth_kpi.data[0].value = kpiOfficeSalesPerNetGrowth;

    //Agents KPI
    data.agents_agent_count_kpi.data[0].value = kpiAgentCountCurrentYear;
    data.agents_agent_count_kpi.data[0].percent = kpiAgentCountPercentageChange;
    //Agents Recruited
    data.agents_recruited_kpi.data[0].value = kpiAgensRecruitedCurrentYear;
    data.agents_recruited_kpi.data[0].percent = kpiAgentsRecruitedPercentageChange;
    //Agents Terminated
    data.agents_terminated_kpi.data[0].value = kpiAgensTerminatedCurrentYear;
    data.agents_terminated_kpi.data[0].percent = kpiAgentsTerminatedPercentageChange;
    //Net growth
    data.agents_net_growth_kpi.data[0].value = kpiAgentsNetGrowth;
    //Rec / net growth
    data.agents_rec_per_net_growth_kpi.data[0].value = kpiAgentsRecPerNetGrowth;


    return data;
  }
  catch(error){
    console.log('error injecting cube data..');
    console.log(error);
    throw error;
  }


}

function convertMonthNumberToShorthand(date){
  try{
    let monthShortHand = "";

    switch (date) {
      case "01":
      case "1":
        monthShortHand = "jan";
        break;
      case "02":
      case "2":
        monthShortHand = "feb";
        break;
      case "03":
      case "3":
        monthShortHand = "mar";
        break;
      case "04":
      case "4":
        monthShortHand = "apr";
        break;
      case "05":
      case "5":
        monthShortHand = "may";
        break;
      case "06":
      case "6":
        monthShortHand = "jun";
        break;
      case "07":
      case "7":
        monthShortHand = "jul";
        break;
      case "08":
      case "8":
        monthShortHand = "aug";
        break;
      case "09":
      case "9":
        monthShortHand = "sept";
        break;
      case "10":
        monthShortHand = "oct";
        break;
      case "11":
        monthShortHand = "nov";
        break;
      case "12":
        monthShortHand = "dec";
        break;
      default:
        monthShortHand = undefined;
    }

    return monthShortHand;
    }
  catch(error){
    console.log('error at convertMonthNumberToShorthand in businessPlan service');
    throw error;
  }
}