import { regions, accessRoles, remaxTitles, } from './filters';

const _userPermissions = [];
for (let i = 0; i < 10; i++) {
    _userPermissions[i] = {
        id: i + 1,
        region_id: i + 1,
        name: `Admin Audit ${i + 1}`,
        unique_id: 'AT2-P276345',
        remax_title: 'Territory Administrator',
        system_access_role: 'SysAdmin - k.schneider',
        region: 'RE/MAX Europe',
        entity: 'RE/MAX Europe',
        email: 'ldt@bwscloud.tech',
    };
}

export const userPermissions = _userPermissions;

export const userPermissionsFilters = [
    {
        name: 'RE/MAX titles',
        options: remaxTitles,
    },
    {
        name: 'Regions',
        options: regions,
    },
    {
        name: 'Access roles',
        options: accessRoles,
    }
];

export const currentUserPermission = {
    id: 1,
    person: 'Pasi Aalto, FI1-P103664, Regional Director, RE/MAX Finland (Head Office)',
    title: 'Regional Director',
    role: null,
};
