export const getDefaultState = () => ({
    office: {},
	officeInfo: null,
	regionInfo: null,
    franchiseAgreement: {},
	firstAgreementFee: null,
    loadingOffice: false,
    sessionPermissions: {
        permissions: {},
        personID: null,
        relationID: null,
        rightGroup: null,
        username: null,
    },
    pagePermissions: [],
    specializations: [],
    officeLevels: [],
    officeRankingLabels: [],
    regionBusinessDevelopers: [],
    latestOfficeShareSnapshot: null,
    countries: [],
    states: [],
    phonecodes: [],
    notesCategories: [],
    contactInformationEntryTypes: [],
    phoneCategories: [],
    emailCategories: [],
    statistics: {
        actualOpeningDate: null,
        countAgents: null,
        countBrokers: null,
        countStaff: null,
        europeRank: null,
        officeID: null,
        regionRank: null,
    },
    specialization: [],
    contactInformationEntry: [],
    currencyPayment: { iso: null, name: null, },
    snapshots: [],
    officeShareSnapshot: {
        ID: null,
        officeID: null,
        validFrom: null,
        modifiedAt: null,
        officeShares: [],
        snapshotID: null,
        loading: false,
    },
    isFranchiseAgreementCreate: false,
    mainOffices: [],
    regionFranchiseAgreement: [],
    developmentScheduleSnapshots: [],
    notes: {
        list: [],
        totalRows: 0,
        totalPages: 0,
        currentPage: 1,
        rowsPerPage: 35,
    },
    history: {
        list: [],
        totalRows: 0,
        totalPages: 0,
        currentPage: 1,
        rowsPerPage: 35,
    },
    groups: [],
    currencyLfa: { iso: null, name: null, },
    currencyReporting: { iso: null, name: null, },
    businessDevelopers: [],
    regionalPersonForFranchiseSalesRep: [],
    franchiseHistory: [],
    franchiseAgreementSegments: [],
    franchiseAgreement_devScheduleSnapshot: {
        ID: null,
        officeID: null,
        contractID: null,
        validFrom: null,
        term: null,
        officeDevelopmentSchedules: []
    },
    franchiseAgreement_segmentHistory: [],
    developmentScheduleBySnapshotID: {
        loading: false,
        snapshotID: null,
        list: [],
    },
    franchiseByHistorySnapshotID: {
        loading: false,
        snapshotID: null,
        data: null,
    },
    persons: {
        loading: false,
        list: [],
        only_active: '1',
    },
});
