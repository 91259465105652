import { BaseService } from '../../../services/base.service';
import { CubeService } from '../../../services/modules/cube.service';
import { OfficeEditorService } from '../../../services/modules/officeEditor.service';
import getFirstElement from '../../../helpers/getFirstElement';
import { getDefaultState } from './constants';
import { promiseAll } from '@/utils/promiseAll';

export default {
	namespaced: true,

	state: getDefaultState(),

	mutations: {
		setDefaultState(state) {
            const _defaultState = getDefaultState();
            Object.keys(_defaultState).forEach((key) => { state[key] = _defaultState[key]; });
        },

        setState(state, partialState) {
            const partial = JSON.parse(JSON.stringify(partialState));
            Object.keys(partial).forEach((key) => { state[key] = partial[key]; });
        },
	},

	actions: {
		async init({ commit, state }, params,) {
			const officeID = params.officeID;
			const year = params.year;
			const reportingPeriod = params.reportingPeriod; 

			//console.log('officeID:' , officeID);

			//console.log('year:', year);

			//the cards in the office dashboard show the data for the reporting period, and also for the previous year
			const reportingPeriodPreviousYear =  (Number(reportingPeriod.toString().substring(0,4)) - 1).toString() +  reportingPeriod.slice(-6);

			//console.log('reportingPeriod:', reportingPeriod);
			//console.log('reportingPeriodPreviousYear:', reportingPeriodPreviousYear);

			//agent productivity is always on the first of the month, 
			const agentProductivityPeriod = reportingPeriod.substring(0,7) +  '-01';
			const agentProductivityPeriodPreviousYear = (Number(agentProductivityPeriod.toString().substring(0,4)) - 1).toString() +  agentProductivityPeriod.slice(-6);

			//console.log('agentProductivityPeriod:', agentProductivityPeriod);
			//console.log('agentProductivityPeriodPreviousYear:', agentProductivityPeriodPreviousYear);

			//variable for use as the end of the previous year December
			const agentProductivityPeriodPreviousYearDec = (Number(agentProductivityPeriod.toString().substring(0,4)) - 1).toString()+'-12-31';

			//entry point for this component, makes calls to the backend and cubes to get the relevant data
			const [
				officeGCIData,
				officeGCIDataPreviousYear,
				officeAgentProductivityData,
				officeAgentProductivityDataPreviousYear,
				officeTeamMemberCountData,
				officeNewAgentCountData,
				agentProductivityTrend, 
				officeYtdGCIPerAgentTenure,
				officeYtdGCIPerAgentTenurePrev,
				officeYtdTerminationByTenure

				
			] = await promiseAll([
				CubeService.getGCIByOffice(officeID, reportingPeriod),
				CubeService.getGCIByOffice(officeID, reportingPeriodPreviousYear),

				CubeService.officeAgentProductivityData(officeID, agentProductivityPeriod),
				CubeService.officeAgentProductivityData(officeID, agentProductivityPeriodPreviousYear),

				CubeService.officeTeamMemberCountData(officeID, year),
				CubeService.getNewAgentDataByOffice(officeID, year),

				//bottom graphs, this is for the two donut graphs and the agent productivity one
				CubeService.getAgentProductivityByOffice(officeID, year),
				CubeService.getAgentGCIPerTenure(officeID, reportingPeriod),
				CubeService.getAgentGCIPerTenure(officeID, reportingPeriodPreviousYear),
				CubeService.getYTDTerminationsByOffice(officeID, reportingPeriod)
			]);

			const [
				office,
				officeFranchiseAgreement,
				snapshots,
				statistics,
				agentGrowthTrend, 
				officeGCITrend, 
				officeTransactionTrend, 
				officeSalesAndRentalsGCIData,
				officeSalesAndRentalsGCIDataPreviousYear,
				

				
			] = await promiseAll([
				BaseService.get(`office/${officeID}`),
				BaseService.get(`office/${officeID}/franchiseAgreement/loadByFranchiseID`),
				BaseService.get(`office/${officeID}/snapshots`, { sort: '-validFrom' }),
				OfficeEditorService.statistics(officeID),
				//trends are for previous three years
				CubeService.getAgentCountForYearByOffice(officeID, year),
				CubeService.getGCITrendByOffice(officeID, year),
				CubeService.getTransactionTrendByOffice(officeID, year),
				//side card data is for reporting period, but over two years
				CubeService.getSalesAndRentalsGCIByOffice(officeID, reportingPeriod),
				CubeService.getSalesAndRentalsGCIByOffice(officeID, reportingPeriodPreviousYear)
			]);

			const [
				officeSalesAndRentalsTransactionsData, 
				officeSalesAndRentalsTransactionsDataPreviousYear,
				officeAgentData,
				officeAgentDataPreviousYear,
				officeNewAndTerminatedAgentData,
				officeNewAndTerminatedAgentDataPreviousYear,
				officeGCIPerBrokerOwnerData,
				officeGCIPerBrokerOwnerDataPreviousYear,
		

				
			] = await promiseAll([
				CubeService.getSalesAndRentalsTransactionsByOffice(officeID, reportingPeriod),
				CubeService.getSalesAndRentalsTransactionsByOffice(officeID, reportingPeriodPreviousYear),

				//agent count for reporting period
				CubeService.getAgentCountDataByOffice(officeID, reportingPeriod),
				CubeService.getAgentCountDataByOffice(officeID, agentProductivityPeriodPreviousYearDec),

				CubeService.getNewAndTerminatedAgentDataByOffice(officeID, reportingPeriod),
				CubeService.getNewAndTerminatedAgentDataByOffice(officeID, reportingPeriodPreviousYear),

				CubeService.getGCIPerBrokerOwnerDataByOffice(officeID, reportingPeriod),
				CubeService.getGCIPerBrokerOwnerDataByOffice(officeID, reportingPeriodPreviousYear),

			]);

			/*
			console.log('got some data back');
			console.log(office);
			console.log(officeFranchiseAgreement);
			console.log(snapshots);
			console.log(statistics);
			console.log(agentGrowthTrend); 
			console.log(officeGCITrend); 
			console.log(officeTransactionTrend);
			console.log(officeSalesAndRentalsGCIData);							//8
			console.log(officeSalesAndRentalsGCIDataPreviousYear);				//9
			console.log(officeSalesAndRentalsTransactionsData);					//10
			console.log(officeSalesAndRentalsTransactionsDataPreviousYear);		//11
			console.log(officeAgentData);										//12
			console.log(officeAgentDataPreviousYear);							//13
			console.log(officeNewAndTerminatedAgentData);						//14
			console.log(officeNewAndTerminatedAgentDataPreviousYear);			//15
			console.log(officeGCIPerBrokerOwnerData);							//16
			console.log(officeGCIPerBrokerOwnerDataPreviousYear);				//17
			console.log(officeGCIData);
			console.log(officeGCIDataPreviousYear);
			console.log(officeAgentProductivityData);							//20 
			console.log(officeAgentProductivityDataPreviousYear);				//21
			console.log(officeTeamMemberCountData);								//22
			console.log(officeNewAgentCountData);								//23
			console.log(agentProductivityTrend);								//24
			console.log(officeYtdGCIPerAgentTenure);
			console.log(officeYtdTerminationByTenure);
			*/
			


			const snap = (snapshots?.data?.result || [])[0];
			let officeShareSnapshot = null;
			if (snap) {
				const officeShareSnap = await BaseService.get(`officeShareSnapshot/${snap.ID}`);
				officeShareSnapshot = officeShareSnap?.data?.result;
			}
			let officeData;
			if(office?.data?.result == undefined){
				officeData = office?.data;
			}else{
				officeData = office?.data?.result;
			}
			console.log(officeGCIPerBrokerOwnerDataPreviousYear);
			try {
				commit('setState', {
					office: officeData,
					officeFranchiseAgreement: officeFranchiseAgreement?.data?.result,
					officeShares: officeShareSnapshot?.officeShares || [],
					statistics : statistics?.data?.result,
					agentGrowthTrend : agentGrowthTrend,
					officeGCITrend : officeGCITrend, 
					officeTransactionTrend : officeTransactionTrend, 
					officeSalesAndRentalsGCIData : [getFirstElement(officeSalesAndRentalsGCIDataPreviousYear), getFirstElement(officeSalesAndRentalsGCIData)],
					officeSalesAndRentalsTransactionsData : [getFirstElement(officeSalesAndRentalsTransactionsDataPreviousYear), getFirstElement(officeSalesAndRentalsTransactionsData)], 
					officeAgentData : [getFirstElement(officeAgentDataPreviousYear), getFirstElement(officeAgentData)],
					officeNewAndTerminatedAgentData : [getFirstElement(officeNewAndTerminatedAgentDataPreviousYear), getFirstElement(officeNewAndTerminatedAgentData)],
					officeGCIPerBrokerOwnerData : [getFirstElement(officeGCIPerBrokerOwnerDataPreviousYear),getFirstElement(officeGCIPerBrokerOwnerData)],
					officeGCIData : [getFirstElement(officeGCIDataPreviousYear), getFirstElement(officeGCIData)],
					officeAgentProductivityData : [officeAgentProductivityDataPreviousYear, officeAgentProductivityData],
					officeTeamMemberCountData: officeTeamMemberCountData,
					officeNewAgentCountData : officeNewAgentCountData,
					agentProductivityTrend : agentProductivityTrend, 
					officeYtdGCIPerAgentTenure : officeYtdGCIPerAgentTenure,
					officeYtdGCIPerAgentTenurePrev : officeYtdGCIPerAgentTenurePrev,
					officeYtdTerminationsByTenure : officeYtdTerminationByTenure
					

				});
			} catch(e) { /**/ }
		},
		async checkAccess({commit}, params,){
			const officeID = params.officeID;
			const year = params.year;

			//const userHasAccess = CubeService.getAgentCountForYearByOffice(officeID, year);
			const userHasAccess = await CubeService.checkOfficeDashboardUserAccess(officeID, year);

			commit('setState', {
				accessAllowed: userHasAccess
			});

		}
	},
};
  