import queryString from 'query-string';
import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';

export class ContactMgmtService extends BaseService {
  static get entity() {
    return 'contactPerson';
  }

  static async getContactPersonList() {
    try {
      let response = await this.request().post(`/contactPersons/`);
      console.log(response);
      return new ResponseWrapper(response);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async selectContactPerson(id) {
    try {
      let response = await this.request.get(`${this.entity}/${id}`);
      return new ResponseWrapper(response);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async selectContactCompany(id) {
    try {
      let response = await this.request.get(`contactCompany/${id}`);
      return new ResponseWrapper(response);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
