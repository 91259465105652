export type PersonSelectOption =  {
	value: string | number;
	label: string | number;
	labelNoHTML?: string | number;
	data: any;
	display: {
		name: string;
		email: string;
	}
};

export function personToSelectOption(p: any): PersonSelectOption {
	let label = [`${p.lastName || ''} ${p.firstName || ''}`, p.uniquePersonID, p.displayTitle, p.primaryOfficeName].filter(s => s).join(', ');
	const labelNoHTML = label;
	if (p.personIsActive === 0) {
		label += ' - <span class="text-red-400">inactive</span>';
	} else {
		label += ' - <span class="text-green-400">active</span>';
	}
	return {
		value: p.personID || p.ID || p.id,
		label,
		labelNoHTML,
		data: p,
		display: {
			name: [p.firstName, p.lastName].join(', '),
			email: p.email,
		},
	};
}
