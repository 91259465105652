import { promiseAll } from '@/utils/promiseAll';
import { BaseService } from '../../../services/base.service';
import moment from 'moment';

export async function init(store, params: any = {}) {
	const { regionID, reportMonth, reportYear, reportDate, page } = params;

	store.commit('setState', { loading: true })
	
	try {
		const res = await promiseAll([
			BaseService.get(`region/${regionID}`),
			BaseService.get(`region/${regionID}/annualDuesPeriodAccessible`, { reportMonth, reportYear, }),
			BaseService.get(`region/${regionID}/currencyPayment`),
			BaseService.get(`region/${regionID}/annualDuesApplicableFeeReliefs`, {
				currentReportingDate: reportDate,
				sort: 'campaignName',
			}),
			BaseService.get(`region/${regionID}/monthlyAnnualDuesStatus`, {
				currentReportMonth: reportMonth,
				currentReportYear: reportYear,
			}),
			BaseService.get(`region/${regionID}/officesWithStatus`, { sort: 'name', }),
			monthlyAnnualDues(store, { 
				reportMonth,
				reportYear,
				currentPage: page || 1,
				//using to handle differences in Safari Browser
				//queryParams: { currentReportDate: moment(reportDate), }
				queryParams: { currentReportDate: reportDate, }
			}, regionID),			
			BaseService.post(`region/${regionID}/annualDuesReportCanBeReopend`, { reportMonth, reportYear, }),
			BaseService.get(`region/${regionID}/monthlyAnnualDuesBalance`, { currentReportDate: reportDate, }),

			BaseService.get('regions/count'),
			BaseService.get('currency/EUR'),
			BaseService.sessionPermissions(),
			changeHistory(store, { reportDate }, regionID),
		]);

		const permissions = { ...res[11]?.data?.permissions, };

		store.commit('setState', {
			loading: false,
			initParams: params,
			region: res[0]?.data?.result,
			annualDuesPeriodAccessible: res[1]?.data?.result,
			currencyPayment: res[2]?.data?.result,
			annualDuesApplicableFeeReliefs: (res[3]?.data?.result || []).map((item, i) => ({ ...item, countID: i + 1, })),
			...res[4]?.data?.result,
			offices: res[5]?.data?.result || [],
			...res[7]?.data?.result,
			monthlyAnnualDuesBalance: res[8]?.data?.result,
			isAnnualDuesRequestUpdate: !(permissions.ANNUAL_DUES_UPDATE && res[4]?.data?.result?.isAllowedToEdit),
		});
	} catch(e) { throw e; }
}

export async function monthlyAnnualDues({ commit, state }, params = {}, regionID?: any) {
	try {
		regionID = regionID || state.region?.ID;
		const { queryParams, reportMonth, reportYear, ..._params } = { ...params, } as any;
		const currentPage = _params.currentPage || 1;
		const rowsPerPage = _params.rowsPerPage || state.monthlyAnnualDues.rowsPerPage || 50;
		const qParams = { ...state.monthlyAnnualDues.queryParams, ...queryParams, };

		commit('setState', { monthlyAnnualDues: { ...state.monthlyAnnualDues, loading: true, queryParams: qParams, }, });

		const monthlyAnnualDuesPayload = {
			...state.monthlyAnnualDues.queryParams,
			...qParams,
			limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
		};

		const res = await BaseService.get(`region/${regionID}/monthlyAnnualDues`, monthlyAnnualDuesPayload);
		const totalRows = res?.data?.info?.numRows || 0;

		const list = res?.data?.result || [];

		const personIDs = list.map(p => p.personID).join(',');
		const annualDuesComments = await BaseService.get(`region/${regionID}/annualDuesComments`, { 
			reportYear: reportYear || state.initParams.reportYear, 
			reportMonth: reportMonth || state.initParams.reportMonth,
			personIDs,
		});

		commit('setState', {
			annualDuesComments: annualDuesComments?.data?.result ||[],
			monthlyAnnualDues: {
				..._params,
				currentPage,
				rowsPerPage,
				loading: false,
				totalRows,
				totalPages: Math.ceil(totalRows / rowsPerPage),
				list,
				queryParams: qParams,
			},
		});
	} catch(e) { 
		commit('setState', { monthlyAnnualDues: { ...state.monthlyAnnualDues, loading: false, }, });
		throw e; 
	}
}

export async function changeHistory({ commit, state }, params = {}, regionID?: any) {
	try {
		regionID = regionID || state.region?.ID;
		const { queryParams, reportDate, ..._params } = { ...params, } as any;
		const currentPage = _params.currentPage || 1;
		const rowsPerPage = _params.rowsPerPage || state.changeHistory.rowsPerPage || 35;
		const qParams = { 
			...state.changeHistory.queryParams, 
			...queryParams, 
			metaData: reportDate || state.changeHistory.queryParams?.metaData,
		};

		commit('setState', { changeHistory: { ...state.changeHistory, loading: true, queryParams: qParams, }, });

		const res = await BaseService.get(`region/${regionID}/monthlyAnnualDuesHistory`, {
			...qParams,
			//MAX-1129
			//limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
		});
		const totalRows = res?.data?.info?.numRows || 0;

		const list = res?.data?.result || [];

		commit('setState', {
			changeHistory: {
				..._params,
				currentPage,
				rowsPerPage,
				loading: false,
				totalRows,
				totalPages: Math.ceil(totalRows / rowsPerPage),
				list,
				queryParams: qParams,
			},
		});
	} catch(e) { 
		commit('setState', { changeHistory: { ...state.changeHistory, loading: false, }, });
		throw e; 
	}
}
