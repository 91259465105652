import { AnnualDuesService } from '@/services/modules/annualDues.service';
import { ListPatternFieldsData } from '@formatjs/ecma402-abstract';

export default {
  namespaced: true,
  state: {
    report: {
      list: [],
      totalRows: 0,
      rowsPerPage: 47,
    },
    monthlyReport: {
      list: [],
      totalRows: 0,
      totalPages: 0,
      currentPage: 1,
      rowsPerPage: 12,
    },
  },
  getters: {
    getReports: (state) => state.report,
    getMonthlyReports: (state) => state.monthlyReport,
  },
  mutations: {
    setReports(state, data) {
      state.report = data;
    },
    setMonthlyReports(state, data) {
      state.monthlyReport = data;
    },
  },
  actions: {
      async getReports({ commit, state }, params) {
        try {
          const rowsPerPage = params.rowsPerPage || state.report.rowsPerPage;
          let currentPage = params.currentPage || state.report.currentPage;
          if (!state.report.totalPages || currentPage < 1) currentPage = 1;

          const res = await AnnualDuesService.getReports({
            ...params,
            currentPage,
            rowsPerPage,
          });

          const totalRows = Number(res?.data?.info?.numRows || '0');
          const totalPages = Math.ceil(totalRows / rowsPerPage);
          currentPage = currentPage > totalPages ? totalPages : currentPage;

          commit('setReports', {
            list: res.data.result,
            totalRows,
            rowsPerPage,
            currentPage,
            totalPages,
          });

          return res;
        } catch (e) {
          throw e;
        }
      },
      async getMonthlyReports({ commit, state }, params) {
        try {
          const rowsPerPage = params.rowsPerPage || state.monthlyReport.rowsPerPage;
          let currentPage = params.currentPage;
          if (!state.monthlyReport.totalPages || currentPage < 1) currentPage = 1;
        
          const res = await AnnualDuesService.getMonthlyReports({
            ...params,
            currentPage,
            rowsPerPage,
          });
        
          const totalRows = Number(res?.data?.info?.numRows || '0');
          const totalPages = Math.ceil(totalRows / rowsPerPage);
          currentPage = currentPage > totalPages ? totalPages : currentPage;
        
          commit('setMonthlyReports', {
            list: res.data.result,
            totalRows,
            rowsPerPage,
            currentPage,
            totalPages,
          });
        
          return res;
        } catch (e) {
          throw e;
        }
      },
    },
};
