import { promiseAll } from '@/utils/promiseAll';
import { BaseService } from '../../../services/base.service';

export async function init(store, params) {
	try {
		await promiseAll([
			groups(store, {}),
			regionDropdown(store, {}),
		]);
	} catch(e) { throw e; }
}

export async function groups({ commit, state }, params) {
	try {
		const { queryParams, ..._params } = { ...params, } as any;
		const currentPage = _params.currentPage || 1;
		const rowsPerPage = _params.rowsPerPage || 35;
		const qParams = { ...state.groups.queryParams, ...queryParams, };

		commit('setState', { groups: { ...state.groups, loading: true, queryParams: qParams, }, });

		const res = await BaseService.get('groups', {
			...qParams,
			//MAX-1129
			//limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
		});
		const totalRows = res?.data?.info?.numRows || 0;
		commit('setState', {
			selected: [],
			groups: {
				..._params,
				currentPage,
				rowsPerPage,
				loading: false,
				totalRows,
				totalPages: Math.ceil(totalRows / rowsPerPage),
				list: res?.data?.result || [],
				queryParams: qParams,
			},
		});
	} catch(e) { 
		commit('setState', { groups: { ...state.groups, loading: false, }, });
		throw e; 
	}
}

export async function regionDropdown({ commit, state }, params) {
	try {
		const res = await BaseService.get('regions/dropdown');
		commit('setState', {
			selected: [], 
			regionDropdown: res?.data?.result || [], 
		});
	} catch(e) { 
		commit('setState', { groups: { ...state.regionDropdown, loading: false, }, });
		throw e; 
	}
}
