import { getDefaultState } from './constants';
import { SystemAccess } from '../../../services/modules/systemAccess.service.js';

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    setDefaultState(state) {
      const _defaultState = getDefaultState();
      Object.keys(_defaultState).forEach((key) => {
        state[key] = _defaultState[key];
      });
    },

    setState(state, partialState) {
      const partial = JSON.parse(JSON.stringify(partialState));
      Object.keys(partial).forEach((key) => {
        state[key] = partial[key];
      });
    },

    resetPersonAwardsFilters(state) {
      const list = state.personAwards.list;
      state.personAwards = {
        ...getDefaultState().personAwards,
        list,
      };
    },
  },

  actions: {
    async getSystemAccessRoles(_, params) {
      try {
        const res = await SystemAccess.getSystemAccessRoles(params);
        const data = res || [];
        return data;
      } catch (e) {
        throw e;
      }
    },

    async getSystemAccessAdminRoles(_, ID) {
      try {
        const res = await SystemAccess.getSystemAccessAdminRoles(ID);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },

    async getUserPermissions(_, params) {
      try {
        const res = await SystemAccess.getUserPermissions(params);
        const data = res || [];
        return data;
      } catch (e) {
        throw e;
      }
    },

    async getAdminSysAccessTitles(_, ID) {
      try {
        const res = await SystemAccess.getAdminSysAccessTitles(ID);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },

    async getAdminSysAccessAvailableEntities(_, ID) {
      try {
        const res = await SystemAccess.getAdminSysAccessAvailableEntities(ID);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },

    async getSystemAccessTiers(_, params) {
      try {
        const res = await SystemAccess.getSystemAccessTiers(params);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },

    async getSystemPackagesByTierID(_, { ID, params }) {
      try {
        const res = await SystemAccess.getSystemPackagesByTierID(ID, params);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },
    async getSystemPackagesByID(_, { ID, params }) {
      try {
        const res = await SystemAccess.getSystemPackagesByID(ID, params);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },
    async getSystemAccessByID(_, { ID, params }) {
      try {
        const res = await SystemAccess.getSystemAccessByID(ID, params);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },
    async getRolesDropdown(_) {
      try {
        const res = await SystemAccess.getRolesDropdown();
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },
    async getExceptRegions(_, ID) {
      try {
        const res = await SystemAccess.getExceptRegions(ID);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },
    async getTitlesDropdown(_, ID) {
      try {
        const res = await SystemAccess.getTitlesDropdown(ID);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },
    async getRolesDropdownByID(_, ID) {
      try {
        const res = await SystemAccess.getRolesDropdownByID(ID);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },

    async saveSystemRole(_, payload) {
      try {
        const res = await SystemAccess.saveSystemRole(payload);
        const data = res || [];
        return data;
      } catch (e) {
        throw e;
      }
    },

    async saveSystemAccess(_, payload) {
      try {
        const res = await SystemAccess.saveSystemAccess(payload);
        const data = res || [];
        return data;
      } catch (e) {
        throw e;
      }
    },

    async saveAdminAccess(_, payload) {
      try {
        const res = await SystemAccess.saveAdminAccess(payload);
        const data = res || [];
        return data;
      } catch (e) {
        throw e;
      }
    },

    async updateSystemRole(_, { ID, payload }) {
      try {
        const res = await SystemAccess.updateSystemRole(ID, payload);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },

    async updateSystemAccess(_, { ID, payload }) {
      try {
        const res = await SystemAccess.updateSystemAccess(ID, payload);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },

    async deleteSystemRole(_, id) {
      try {
        const res = await SystemAccess.deleteSystemRole(id);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },
    async deleteSystemPermission(_, id) {
      try {
        const res = await SystemAccess.deleteSystemPermission(id);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },
  },
};
