import { getDefaultState } from './constants';
import { GlobalSearchService } from '@/services/modules/globalSearch.service';
import { init } from './init';

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    setDefaultState(state) {
      const _defaultState = getDefaultState();
      Object.keys(_defaultState).forEach((key) => {
        state[key] = _defaultState[key];
      });
    },

    setState(state, partialState) {
      const partial = JSON.parse(JSON.stringify(partialState));
      Object.keys(partial).forEach((key) => {
        state[key] = partial[key];
      });
    },
  },
  actions: {
    init(store, params) {
      return init(store, params);
    },

    async search({ commit, state }, params) {
      try {
        commit('setState', { globalSearch: { ...state.results, loading: true } });
        const res = await GlobalSearchService.search(params);
        commit('setState', { globalSearch: { loading: false, results: res, query: params } });
        return res;
      } catch {
        commit('setState', { globalSearch: { ...state.results, loading: false } });
      }
    },

    async searchByType({ commit, state }, params) {
      const currentPage = params.currentPage || 1;
      const rowsPerPage = 10;
      try {
        commit('setState', { typeSearch: { ...state.results, loading: true } });
        const res = await GlobalSearchService.searchByType(params);
        const totalRows = res?.data?.totalcount || 0;
        commit('setState', {
          typeSearch: {
            results: res,
            currentPage,
            rowsPerPage,
            loading: false,
            totalRows,
            totalPages: Math.ceil(totalRows / rowsPerPage),
          },
        });
        return res;
      } catch {
        commit('setState', { typeSearch: { ...state.results, loading: false } });
      }
    },

    async searchByContactType({ commit, state }, params) {
      const currentPage = params.currentPage || 1;
      const rowsPerPage = 10;
      try {
        commit('setState', { typeSearch: { ...state.results, loading: true } });
        //console.log('api');
        const res = await GlobalSearchService.searchByContactType(params);
        const totalRows = res?.data?.totalcount || 0;
        commit('setState', {
          typeSearch: {
            results: res,
            currentPage,
            rowsPerPage,
            loading: false,
            totalRows,
            totalPages: Math.ceil(totalRows / rowsPerPage),
          },
        });
        return res;
      } catch {
        commit('setState', { typeSearch: { ...state.results, loading: false } });
      }
    },
  },
};
