import TC from '../template_utilities/templateColumns';
import NC from '../template_utilities/PeriodicalNestedColumns';
import panel from './settingspanel.vue';
export const agent_performance_periodical_per_office_optimized: any = {
  cubeParameters: {
    measures: [
      'PerformanceCube.gciForPeriod',
      'PerformanceCube.transactionForPeriod',
      'PerformanceCube.volumeForPeriod',
      'PerformanceCube.teamIDMeasure',
      'PerformanceCube.gciForPeriodRxd',
      'PerformanceCube.gciForPeriodEur',
      'PerformanceCube.volumeForPeriodRxd',
      'PerformanceCube.volumeForPeriodEur',
    ],
    order: [
      ['personCube.idperson', 'asc'],
      ['OfficeCube.officename', 'asc'],
      ['personCube.lastname', 'asc'],
      ['personCube.firstname', 'asc'],
    ],
    dimensions: [
      'personCube.idperson',
      // 'PerformanceCube.officeid',
      // 'PerformanceCube.regionid',
      'RegionCube.region',
      'OfficeCube.uniqueofficeid',
      'OfficeCube.officeConstituentId',
      'OfficeCube.officename',
      'personCube.teamllcid',
      'personCube.lastname',
      'personCube.firstname',
      'personCube.uniquepersonid',
      'personCube.tenuredays',
      'personCube.primarytitle',
      'personCube.isactive',
      'personCube.reportingcurrencyiso',
      'personCube.idllcperson',
      'personCube.display_title',
      'personCube.titleprimary',
      'personCube.title',
    ],
    mainCubeParameter: 'PerformanceCube',
    filterParameter: 'PerformanceCube.transactiondate',
    timeParameter: 'PerformanceCube.transactiondate',
  },
  joinDimension: 'teamIDMeasure',
  nestingDimension: 'officeid',
  alternativeCubeParameters: {
    measures: [],
    dimensions: ['TeamChangesCube.teamIDMeasure', 'TeamCube.teamllcid', 'TeamCube.teamname'],
    mainCubeParameter: 'TeamChangesCube',
    timeParameter: 'TeamChangesCube.teamrecdate',
  },
  titleLevelParameters: {
    measures: [
      'TitleLevelPerformance.gciForPeriod',
      'TitleLevelPerformance.volumeForPeriodRxd',
      'TitleLevelPerformance.volumeForPeriodEur',
      'TitleLevelPerformance.transactionForPeriod',
      'TitleLevelPerformance.volumeForPeriod',
      'TitleLevelPerformance.sumCommissionResidentialSale',
      'TitleLevelPerformance.avgRateRxd',
      'TitleLevelPerformance.gciForPeriodRxd',
      'TitleLevelPerformance.gciForPeriodEur',
      'TitleLevelPerformance.avgRateEur',
    ],
    dimensions: [
      'TitleLevelPerformance.regionid',
      'RegionCube.region',
      'personCube.idperson',
      'personCube.TeamID',
      'personCube.subregionname',
      'personCube.teamllcid',
      'personCube.lastname',
      'personCube.firstname',
      // 'TeamCube.teamname',
      // 'TeamCube.teamid',
      // 'TeamCube.teamllcid',
      'personCube.uniquepersonid',
      'personCube.tenuredays',
      'personCube.primarytitle',
      'personCube.isactive',
      'personCube.reportingcurrencyiso',
      'personCube.idllcperson',
      'personCube.display_title',
      'personCube.titleprimary',
      'personCube.title',
      'OfficeCube.officename',
      'OfficeCube.uniqueofficeid',
      'OfficeCube.officeConstituentId',
      'OfficeCube.statusoffice',
      'OfficeCube.officeid',
    ],
    filters: [
      {
        or: [
          {
            or: [
              {
                operator: 'lt',
                member: 'TitleLevelPerformance.gci',
                values: ['0'],
              },
              {
                operator: 'gt',
                member: 'TitleLevelPerformance.gci',
                values: ['0'],
              },
            ],
          },
          {
            operator: 'lt',
            member: 'TitleLevelPerformance.titlerank',
            values: ['4'],
          },
        ],
      },
    ],
    mainCubeParameter: 'TitleLevelPerformance',
    filterParameter: 'TitleLevelPerformance.date',
    timeParameter: 'TitleLevelPerformance.date',
  },

  columns: [
    TC.region,
    TC.subRegion,
    TC.officeName,
    TC.officeUniqueID,
    TC.officeConstituentID,
    TC.teamName,
    TC.teamllcid,
    TC.lastName,
    TC.firstName,
    TC.uniqueID,
    TC.personConstituentID,
    TC.tenureDays,
    TC.primaryTitle,
    TC.status,
  ],
  nestedColumns: NC.agentPerformanceCombined,
  pivot: {
    x: [
      'personCube.region',
      'personCube.subregionname',
      'personCube.primaryofficename',
      'personCube.officeUniqueid',
      'OfficeCube.officeConstituentId',
      'TeamCube.teamname',
      'TeamCube.teamllcid',
      'personCube.lastname',
      'personCube.firstname',
      'personCube.uniquepersonid',
      'personCube.idllcperson',
      'personCube.tenuredays',
      'personCube.primarytitle',
      'personCube.isactive',
      'PerformanceCube.officeid',
      'personCube.reportingcurrencyiso',
    ],
    y: ['PerformanceCube.transactiondate'],
  },
  settingsPanel: panel,
};
