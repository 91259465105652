
import BreadcrumbsComponent from '../components/BreadcrumbsComponent.vue';

export default {
  components: {
    BreadcrumbsComponent,
  },
  computed: {
  },
  methods: {
  },
};
