import queryString from 'query-string';
import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';
import { BusinessPlanService } from './businessPlan.service';

export class RegionService extends BaseService {
  static get entity() {
    return 'region';
  }

  static async getRegionsTable({ currentPage, rowsPerPage, ...query }) {
    currentPage = currentPage || 1;
    rowsPerPage = rowsPerPage || 35;
    try {
      let response = await this.request().get(`regions?${queryString.stringify({ 
        limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
        sort: 'name',
        ...query, 
      })}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async searchRegionByName(page, limit, searchValue) {
    try {
      //make a call to the existing backend to search for a region by name with pagination, results are sorted by name
      //regionId : number
      //page : page number
      //limit : number of records per page
      //searchTerm : search term to search for a region

      //make get request to 'regions' endpoint
      const response = await this.get('regions', {
          limit: `${(page - 1) * limit},${limit}`,
          sort: 'name',
          'textFilter[name]': searchValue || undefined,
        });

      return new ResponseWrapper(response, response.data); 
      
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
  }
}

  static async getManagingDirectors(id) {
    try {
      let response = await this.request().get(`${this.entity}/${id}/managingDirectors`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async franchiseAgreement(id) {
    try {
      const response = await this.request().get(`${this.entity}/${id}/franchiseAgreement`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getRegionalPersons(id, onlyActive) {
    try {
      const response = await this.request().get(`${this.entity}/${id}/regionalPerson?only_active=${onlyActive ? 1 : 0}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async insertRegionalPersons(entity, id) {
    try {
      const response = await this.request().post(`${this.entity}/${id}/regionalPerson`, entity);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async updateRegionalPersons(entity, id) {
    try {
      const response = await this.request().put(`/regionalPerson/${id}`, entity);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async UpdateAnnualDuesAgreement(entity, id) {
    try {
      const response = await this.request().post(`${this.entity}/${id}/saveAnnualDuesForAgreement`, entity);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async insertNote(entity, id) {
    try {
      const response = await this.request().post(`${this.entity}/${id}/note`, entity);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async insertFranchiseAgreement(entity, id) {
    try {
      const response = await this.request().post(`${this.entity}/${id}/franchiseAgreement`, entity);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  static async insertCompanySnapShot(entity, id) {
    try {
      const response = await this.request().post(`${this.entity}/${id}/managingCompanySnapShot`, entity);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  static async getDevelopmentSchedule(id) {
    try {
      const response = await this.request().get(`/developmentScheduleSnapshot/${id}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  static async getPerformanceScheduleList(id) {
    console.log('hit getPerformanceScheduleList i regionService...');
    try {
      const response = await this.request().get(`/performanceScheduleSnapshot/${id}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  static async getFeeRelief(id) {
    try {
      const response = await this.request().get(`${this.entity}/${id}/feeRelief`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  //Filter getFeeRelief results on type
  static async getFeeReliefFilter(filterTerm, id) {
    try {
      const response = await this.request().get(`${this.entity}/${id}/feeRelief?filter%5BreliefType%5D%5Bin%5D=${filterTerm}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  static async getNoteList(id) {
    try {
      const response = await this.request().get(`${this.entity}/${id}/note`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  static async getRegionHistory(id) {
    try {
      const response = await this.request().get(`${this.entity}/${id}/history`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  static async getAllWithSearch(searchTerm) { 
    try {
      const response = await this.request().get(`${this.entity}s?textFilter%5Bname%5D=${searchTerm}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  //make this pass filter values not just isActive.
  static async getAllWithFilter(filterTerm) {
    try {
      const response = await this.request().get(`${this.entity}s?filter%5BisActive%5D%5Bin%5D=${filterTerm}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  static async getEmailCommunicationTagList() {
    try {
      const response = await this.request().get(`emailCommunicationTags`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getManagingCompanies(id) {
    try {
      const response = await this.request().get(`${this.entity}/${id}/managingCompanySnapshot`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  static async insertAndDeleteDevelopmentSchedules(entity, id, snapshot) {
    try {
      const response = await this.request().post(`${this.entity}/${id}/developmentScheduleSnapshot`, { developmentSchedules: entity, validFrom: snapshot });
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  static async insertAndDeletePerformanceSchedules(entity, id, snapshot) {
    try {
      let arr = [];
      entity.forEach((obj, i) => {
        arr[i] = {
          year: obj.year.toString(),
          feeReductionRules: obj.feeReductionRules ? obj.feeReductionRules : '',
          planGCI: obj.planGCI ? obj.planGCI : '',
          planNumAssociates: obj.planNumAssociates ? obj.planNumAssociates : '',
          planNumFranchises: obj.planNumFranchises ? obj.planNumFranchises : '',
        };
      });
      const response = await this.request().post(`${this.entity}/${id}/performanceScheduleSnapshot`, { performanceSchedules: arr, validFrom: snapshot });
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  static async getDevSnapShotList(id) {
    try {
      const response = await this.request().get(`${this.entity}/${id}/developmentSchedule/snapshots`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  static async getPerformanceSnapShotList(id) {
    try {
      const response = await this.request().get(`${this.entity}/${id}/performanceSchedule/snapshots`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  static async getBusinessPlan(id, year) {
    try {
      console.log('hit getBusinessplan in regions service...');

      //this makes a get request to: ..../v1/region/businessPlan/getBusinessPlan?id=29
      //this is thew module where the business plan is located in the backend
      //const response = await this.request().get(`${this.entity}/${id}/businessPlan?textFilter%5Byear%5D=${year}&sort=month`);
      let response = await this.request().get(`${this.entity}/${id}/businessPlan/getBusinessPlan?regionID=${id}`);
      
      //if a business plan is non-existent, the backend will create a new record with zero values
      //the businessplan service will then check if there are zero-values in the cubejs data section, if so
      //it will recalculte the values, otherwise, it just returns the values 
      const businessPlanData = await BusinessPlanService.calculateBusinessPlan(response.data.data);
      return new ResponseWrapper(response, businessPlanData);

      
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  //save businessplan to database
  static async insertBusinessPlan(id, payload) {
    try {
      let arr = [];
      payload.forEach((obj, i) => {
        arr[i] = {
          planAgentCount: obj.planAgentCount,
          planAgentsTerminated: obj.planAgentsTerminated,
          planAgentsRecruited: obj.planAgentsRecruited,
          planFranchiseSales: obj.planFranchiseSales,
          planGCI: obj.planGCI,
          planGCIperAgent: obj.planGCIperAgent,
          planOfficesClosed: obj.planOfficesClosed,
          planOfficesOpened: obj.planOfficesOpened,
          regionID: obj.regionID,
          month: obj.month,
          year: obj.year,
        };
      });
      const response = await this.request().post(`${this.entity}/${id}/businessPlanEntries`, arr);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async insertFeeRelief(entity, id) {
    try {
      const response = await this.request().post(`${this.entity}/${id}/feeRelief`, entity);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async updateFeeRelief(entity, id) {
    try {
      const response = await this.request().put(`feeRelief/${id}`, entity);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async deleteFeeRelief(id) {
    try {
      const response = await this.request().delete(`feeRelief/${id}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async updateNote(entity, id) {
    try {
      const response = await this.request().put(`note/${id}`, entity);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async deleteNote(id) {
    try {
      const response = await this.request().delete(`note/${id}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async franchiseAgreementOverride(id) {
    try {
      const response = await this.request().post(`${this.entity}/${id}/franchiseAgreementOverride`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getRegions() {
    try {
      const response = await this.request().get(`regions`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  static async checkIfSubRegion(id) {
    try {
      const response = await this.request().get(`region/${id}/regionHasSubregion`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getRegionsDropdown() {
    try {
      const response = await this.request().get(`regions/dropdown?sort=entryValue`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

}
