export const getDefaultState = () => ({
  person: {},
  shouldDoInsertCheck: false,
  insertCheckResults: [],
  designations: [],
  language: [],
  emailCommunicationTag: [],
  primaryTitleHistories: [],
  contactInformationEntry: [],
  licenseNumbers: [],
  currencyReporting: {},
  ranks: {},
  statistics: {},
  offices: [],
  region: {},
  canEditPersonTerminationReason: false,
  awards: [],
  amountOfYearsGettingClubAwards: null,
  active: [],
  teamLeader: [],
  relationWithInactive: [],
  isInActiveOfficeShare: false,
  states: [],
  teamHistories: [],
  systemAccesses: [],
  team: [],
  session: {
    permissions: {},
    personID: null,
    relationID: null,
    rightGroup: null,
    username: null,
  },
  notes: {
    list: [],
    totalRows: 0,
    totalPages: 0,
    currentPage: 1,
    rowsPerPage: 35,
  },
  primaryTitle: null,
  remaxTitles: {
    list: [],
    activeAndFuture: [],
    only_active: 1,
    startDate: undefined,
    endDate: undefined,
  },
  editHistory: {
    list: [],
    totalRows: 0,
    totalPages: 0,
    currentPage: 1,
    rowsPerPage: 35,
  },

  terminationReasons: [],
  personSuffix: [],
  specializations: [],
  billingInformation: [],
  designationsDropdown: [],
  languages: [],
  emailCommunicationTags: [],
  allCountries: [],
  europeanCountries: [],
  phonecodes: [],
  phoneCategories: [],
  emailCategories: [],
  contactInformationEntryTypes: [],
  personalNotesCategories: [],
  loadingRegionsDropdown: false,
  regionsDropdown: [],
  profilePic: '',
});
