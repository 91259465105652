export function displayBigNumber(n: string | number, opts?: {
	separator?: string;
	decimals?: number;
}) {
    n = Number(`${n}`.replace(/[^a-z0-9.]+/gi, ''));
    if (isNaN(n)) n = '0';
    n = `${Number(n).toFixed(opts?.decimals >= 0 ? opts?.decimals : 2)}`;
    const parts = n.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, opts?.separator || " ");
    return parts.join(".");
}
