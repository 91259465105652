import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';

export class CommonService extends BaseService {
  static get entity() {
    return '';
  }

  static async getExchangeRate() {
    try {
      const response = await this.request().get(`/newestCurrencyExchangeRates`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
