import { CompanyService } from '@/services/modules/company.service';
import { ListPatternFieldsData } from '@formatjs/ecma402-abstract';
import * as officeData from '../mock-data/office';

export default {
  namespaced: true,
  state: {
    companies: {
      list: [],
      totalRows: 0,
      totalPages: 0,
      currentPage: 1,
      rowsPerPage: 35,
    },
    regionsDropdown: [],
    currentCountry: {},
    companyList: [],
    countryList: [],
    officeList: {
      list: [],
      totalRows: 0,
      totalPages: 0,
      currentPage: 1,
      rowsPerPage: 35,
    },
    loadingData: false,
    savingCompany: false,
    currentCompany: {},
    noteList: {
      list: [],
      totalRows: 0,
      totalPages: 0,
      currentPage: 1,
      rowsPerPage: 35,
    },
    history: {
      list: [],
      totalRows: 0,
      totalPages: 0,
      currentPage: 1,
      rowsPerPage: 35,
    },
    loadingOfficeShareSnapshotData: false,
    companyShareList: [],
    snapshotDropdown: [],
    currentSnapshot: null,
    ownerTypes: [],
    loadingRegionsDropdown: false,
  },
  getters: {
    getCountryList: (state) => state.countryList,
    getCompaniesList: (state) => state.companies.list,
    getHistory: (state) => state.history,
    getNotes: (state) => state.noteList,
    getOfficeList: (state) => state.officeList,
  },
  mutations: {
    setRegionsDropdown(state, data) {
      state.regionsDropdown = data;
    },
    setCompanies(state, data) {
      state.companies = { ...state.companies, ...data };
    },
    SET_CURRENT_COUNTRY(state, country) {
      state.currentCountry = country;
    },
    SET_COUNTRY_LIST(state, countryList) {
      state.countryList = countryList;
    },
    SET_COMPANY_LIST(state, companyList) {
      state.companyList = companyList;
    },
    setOfficeLoading(state, loadingState) {
      state.loadingData = loadingState;
    },
    setSavingCompany(state, saving) {
      state.savingCompany = saving;
    },
    SET_CURRENT_COMPANY(state, company) {
      state.currentCompany = company;
    },
    setOwnerTypes(state, data) {
      state.ownerTypes = data;
    },
    SET_NOTE_LIST(state, data) {
      state.noteList = data;
    },
    SET_HISTORY(state, data) {
      state.history = data;
    },
    SET_OFFICE_LIST(state, data) {
      state.officeList = data;
    },
    setLoadingOfficeShareSnapshotData(state, loading) {
      state.loadingOfficeShareSnapshotData = loading;
    },
    setOfficeShareSnapshotData(state, data) {
      state.companyShareList = { ...state.companyShareList, ...data };
    },
    clearOfficeShareData(state, data) {
      state.companyShareList = data;
    },
    setSnapshotDropdown(state, data) {
      state.snapshotDropdown = data;
      state.currentSnapshot = data && data.length ? data[0].ID : null;
    },
    setCurrentSnapshot(state, data) {
      state.currentSnapshot = data;
    },
    setState(state, partialState) {
      const partial = JSON.parse(JSON.stringify(partialState));
      Object.keys(partial).forEach((key) => {
        state[key] = partial[key];
      });
    },
  },
  actions: {
    async getAll({ commit, state }, params) {
      try {
        const rowsPerPage = params.rowsPerPage || state.companies.rowsPerPage;
        let currentPage = params.currentPage || state.companies.currentPage;
        if (!state.companies.totalPages || currentPage < 1) currentPage = 1;

        const res = await CompanyService.getAll({
          ...params,
          currentPage,
          rowsPerPage,
        });

        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        currentPage = currentPage > totalPages ? totalPages : currentPage;

        commit('setCompanies', {
          list: res.data.result,
          totalRows,
          rowsPerPage,
          currentPage,
          totalPages,
        });

        return res;
      } catch (e) {
        throw e;
      }
    },

    async getRegionsDropdown({ commit }) {
      try {
        commit('setState', { loadingRegionsDropdown: true });
        const res = await CompanyService.getRegionsDropdown();
        commit('setState', {
          loadingRegionsDropdown: false,
          regionsDropdown: res?.data?.result || [],
        });
      } catch (e) {
        commit('setState', { loadingRegionsDropdown: false });
        throw e;
      }
    },

    async getById({ commit }, id) {
      const res = await CompanyService.get(`company/${id}`);
      if (res.data.result) {
        commit('SET_CURRENT_COMPANY', res.data.result);
      } else {
        commit('SET_CURRENT_COMPANY', res.data);
      }
      return res;
    },
    getCompanyWithRegionID({ commit }, params = {}) {
      return CompanyService.getCompanyWithRegionID(params);
    },

    async getNotes({ commit, state }, _params: any = {}) {
      const { company_id, ...params } = _params;
      try {
        const rowsPerPage = params.rowsPerPage || state.noteList.rowsPerPage || 35;
        let currentPage = params.currentPage || state.noteList.currentPage || 1;

        const res = await CompanyService.notes(company_id || state?.company?.ID, {
          ...params,
          limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
        });

        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        currentPage = currentPage > totalPages ? totalPages : currentPage;
        commit('SET_NOTE_LIST', {
          list: res?.data?.result || [],
          totalRows,
          rowsPerPage,
          currentPage,
          totalPages,
        });
        return res;
      } catch (e) {
        throw e;
      }
    },

    async saveNote({ commit }, { company_id, entity }) {
      let res = null;
      if (entity.ID) {
        res = await CompanyService.updateNote(entity, company_id);
      } else {
        res = await CompanyService.insertNote(entity, company_id);
      }

      return res;
    },
    async deleteNote({ commit }, { company_id, note_id }) {
      await CompanyService.deleteNote(note_id);
      const listRes = await CompanyService.notes(company_id);
      commit('SET_NOTE_LIST', listRes.data.result);
      return listRes;
    },
    async getHistory({ commit, state }, _params: any = {}) {
      const { company_id, ...params } = _params;
      try {
        const rowsPerPage = params.rowsPerPage || state.history.rowsPerPage || 35;
        let currentPage = params.currentPage || state.history.currentPage || 1;

        const res = await CompanyService.history(company_id || state?.company?.ID, {
          ...params,
          limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
        });

        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        currentPage = currentPage > totalPages ? totalPages : currentPage;

        commit('SET_HISTORY', {
          list: res?.data?.result || [],
          totalRows,
          rowsPerPage,
          currentPage,
          totalPages,
        });

        return res;
      } catch (e) {
        throw e;
      }
    },
    // async getCompanyHistory({ commit }, params) {
    //   const res = await CompanyService.getCompanyHistory(params.company_id);
    //   commit('SET_HISTORY', res.data.result);
    //   return res;
    // },
    async getOfficeList({ commit, state }, _params: any = {}) {
      const { company_id, ...params } = _params;
      try {
        const rowsPerPage = params.rowsPerPage || state.noteList.rowsPerPage || 35;
        let currentPage = params.currentPage || state.noteList.currentPage || 1;

        const res = await CompanyService.getOfficeList(company_id || state?.company?.ID, {
          ...params,
          limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
        });

        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        currentPage = currentPage > totalPages ? totalPages : currentPage;
        commit('SET_OFFICE_LIST', {
          list: res?.data?.result || [],
          totalRows,
          rowsPerPage,
          currentPage,
          totalPages,
        });
        return res;
      } catch (e) {
        throw e;
      }
    },
    async getOfficeShareList({ commit, state }, params: { snapshot_id: string | number }) {
      try {
        commit('setLoadingOfficeShareSnapshotData', true);
        let currentSnap;
        if (state.currentSnapshot) {
          currentSnap = state.currentSnapshot;
        }
        const res = await CompanyService.getOfficeShareList(currentSnap ? currentSnap : params?.snapshot_id);
        const data = res?.data?.result || [];
        commit('setOfficeShareSnapshotData', data);
        commit('setLoadingOfficeShareSnapshotData', false);
        return res;
      } catch (e) {
        commit('setLoadingOfficeShareSnapshotData', false);
        throw e;
      }
    },
    getOwnerTypes({ commit }, params: { office_id: string | number }) {
      return new Promise((resolve) =>
        setTimeout(() => {
          const data = officeData.ownerTypes;
          commit('setOwnerTypes', data);
          resolve(data);
        }, 1500)
      );
    },
    async getSnapshotDropdown({ commit }, params: { company_id: string | number }) {
      try {
        const res = await CompanyService.getSnapshotDropdown(params?.company_id);
        commit('setSnapshotDropdown', res?.data?.result);
        commit('setCurrentSnapshot', res?.data?.result[0].ID);
      } catch (e) {
        throw e;
      }
    },
    async insertCompanySnapshot({ commit }, params: { company_id: string | number; payload }) {
      try {
        return await CompanyService.insertCompanySnapshot(params?.company_id, params.payload);
      } catch (e) {
        throw e;
      }
    },
    async updateCompanySnapshot({ commit }, params: { snapshotID: string | number; payload }) {
      try {
        return await CompanyService.updateCompanySnapshot(params?.snapshotID, params.payload);
      } catch (e) {
        throw e;
      }
    },
    deleteOne({ commit }, payload) {
      return CompanyService.remove(payload);
    },

    async countries({ commit }) {
      const res = await CompanyService.countries();
      commit('SET_COUNTRY_LIST', res?.data?.result);
      return res;
    },

    async saveCompany({ commit }, params) {
      commit('setSavingOffice', true);
      let data = null;
      try {
        if (params.ID) {
          const res = await CompanyService.update(params.ID, params);
          data = res;
        } else {
          delete params.ID;
          const res = await CompanyService.create(params);
          data = res?.data?.result?.model;
        }
        commit('setSavingOffice', false);
      } catch (e) {
        commit('setSavingOffice', false);
        throw e;
      }
      return data;
    },
  },
};
