import { promiseAll } from '@/utils/promiseAll';
import { init, region, regionCompanies, preApprovedOfficeNames, officeDetails, getDefaultState, contractLog } from './utils';
import { BaseService } from '../../../services/base.service';

export default {
	namespaced: true,

	state: getDefaultState(),

	mutations: {
		setDefaultState(state) {
            const _defaultState = getDefaultState();
            Object.keys(_defaultState).forEach((key) => { state[key] = _defaultState[key]; });
        },

        setState(state, partialState) {
            const partial = JSON.parse(JSON.stringify(partialState));
            Object.keys(partial).forEach((key) => { state[key] = partial[key]; });
        },
	},

	actions: {
		init(store, params: any = {}) { return init(store, params); },

		loadRegionData(store, regionID) { return region(store, regionID); },

		regionCompanies(store, regionID) { return regionCompanies(store, regionID); },

		preApprovedOfficeNames(store, regionID) { return preApprovedOfficeNames(store, regionID); },

		officeDetails(store, officeID) { return officeDetails(store, officeID); },

		contractLog(store, opts: any = {}) { return contractLog(store, opts); },
	},
};
  