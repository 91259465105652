export function mergeArrays(array1, array2, property) {
  const mergedArray = [];

  for (const obj1 of array1) {
    let foundMatch = false;
    for (const obj2 of array2) {
      if (Number(obj1[property]) === obj2[property]) {
        const mergedObj = { ...obj1, ...obj2 };
        mergedArray.push(mergedObj);
        foundMatch = true;
      }
    }
    if (!foundMatch) {
      mergedArray.push(obj1);
    }
  }

  return mergedArray;
}

export function mergeArraysForNestedPeriodicals(array1, array2, property, timeDimension) {
  const mergedArray = [];
  const map1 = new Map();
  const map2 = new Map();
  let templateObj = {};

  // Extract keys from both arrays to create a comprehensive template object
  array1.concat(array2).forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (
        key === 'transactiondate' ||
        key === 'transactiondate.month' ||
        key === 'recruitingdate' ||
        key === 'recruitingdate.month' ||
        key === 'recruitingdate.year' ||
        key === 'transactiondate.year'
      ) {
        return;
      }

      templateObj[key] = '0'; // Initialize all keys to null
    });
  });

  // Normalize array2 objects and prepare map2
  array2.forEach((obj) => {
    const normalizedObj = { ...obj };
    if ('recruitingdate' in obj) {
      normalizedObj['transactiondate'] = obj['recruitingdate'];
      delete normalizedObj['recruitingdate'];
    }
    if ('recruitingdate.month' in obj) {
      normalizedObj['transactiondate.month'] = obj['recruitingdate.month'];
      delete normalizedObj['recruitingdate.month'];
    }
    if ('recruitingdate.year' in obj) {
      normalizedObj['transactiondate.year'] = obj['recruitingdate.year'];
      delete normalizedObj['recruitingdate.year'];
    }

    const timeKey = findTimeDimensionKeyForNestedFn(normalizedObj, timeDimension);
    const key = `${normalizedObj[property]}_${normalizedObj[timeKey]}`;
    map2.set(key, normalizedObj);
  });

  // Process array1, augment objects with missing keys from the template
  array1.forEach((obj1) => {
    const timeKey = findTimeDimensionKeyForNestedFn(obj1, timeDimension);
    const key = `${obj1[property]}_${obj1[timeKey]}`;
    const augmentedObj1 = { ...templateObj, ...obj1 };
    map1.set(key, augmentedObj1); // Store augmented object in map1

    if (map2.has(key)) {
      mergedArray.push({ ...augmentedObj1, ...map2.get(key) }); // Merge if matched
    } else {
      mergedArray.push(augmentedObj1); // Include augmented object from array1
    }
  });

  // Add or merge objects from array2 that weren't matched or already processed
  map2.forEach((obj2, key) => {
    if (!map1.has(key)) {
      const augmentedObj2 = { ...templateObj, ...obj2 };
      mergedArray.push(augmentedObj2); // Include augmented object from array2
    }
  });

  return mergedArray;
}

function findTimeDimensionKeyForNestedFn(obj, timeDimension) {
  // Implement the logic to find the appropriate time dimension key ('transactiondate' or 'transactiondate.month')
  // This is a placeholder function. You might already have this implemented.
  return Object.prototype.hasOwnProperty.call(obj, timeDimension) ? timeDimension : 'transactiondate'; // Example logic
}

export function mergeArraysForPeriodicals(array1, array2, property, timeDimension) {
  const mergedArray = [];
  const map2 = new Map();

  array2.forEach((obj) => {
    const timeKey = findTimeDimensionKey(obj, timeDimension);
    const key = `${obj[property]}_${obj[timeKey]}`;
    map2.set(key, obj);
  });

  array1.forEach((obj1) => {
    const timeKey = findTimeDimensionKey(obj1, timeDimension);
    const key = `${obj1[property]}_${obj1[timeKey]}`;
    if (map2.has(key)) {
      mergedArray.push({ ...obj1, ...map2.get(key) });
    } else {
      mergedArray.push(obj1);
    }
  });

  return mergedArray;
}

const findTimeDimensionKey = (obj, timeDimension) => {
  return Object.keys(obj).find((key) => key.endsWith(`.${timeDimension}`));
};
