import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["Popover"], null, {
    default: _withCtx(() => [
      _createVNode($setup["Float"], {
        placement: "bottom-start",
        offset: 3,
        shift: 6,
        flip: 10,
        arrow: "",
        portal: "",
        enter: "transition duration-200 ease-out",
        "enter-from": "opacity-0 -translate-y-1",
        "enter-to": "opacity-100 translate-y-0",
        leave: "transition duration-150 ease-in",
        "leave-from": "opacity-100 translate-y-0",
        "leave-to": "opacity-0 -translate-y-1"
      }, {
        default: _withCtx(() => [
          _createVNode($setup["PopoverButton"], {
            class: _normalizeClass(["flex items-center focus:ring-transparent ring-0 outline-none", [$props.centerButton ? 'm-auto' : ''] ]),
            onMouseleave: $setup.onMouseLeave,
            onMouseenter: $setup.onMouseEnter
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3 /* FORWARDED */
          }, 8 /* PROPS */, ["class"]),
          _createVNode($setup["PopoverPanel"], {
            class: _normalizeClass(["rounded-md focus:outline-none border", [
                    $props.background === 'light' ? 'text-gray-800' : 'text-white',
                ]]),
            style: _normalizeStyle(`box-shadow:0 0 4px rgba(0,0,0,0.3);background:${$props.background === 'light' ? 'rgba(255,255,255,0.9)' : 'rgba(17,36,69,0.9)'};border-color:transparent;`)
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "TooltipContent")
            ]),
            _: 3 /* FORWARDED */
          }, 8 /* PROPS */, ["class", "style"])
        ]),
        _: 3 /* FORWARDED */
      })
    ]),
    _: 3 /* FORWARDED */
  }))
}