export function normalizeOfficeStatus(statusValue) {
  let status = statusValue;
  if (Array.isArray(status)) {
    status.forEach((stati, index) => {
      switch (stati) {
        case "1":
        case 1:
          status[index] = 'Active';
          break;
        case "4":
        case 4:
          status[index] = 'Renewal Pending';
          break;
        case "6":
        case 6:
          status[index] = 'In Renewal';
          break;
        case "2":
        case 2:
          status[index] = 'On hold';
          break;
        case "3":
        case 3:
          status[index] = 'In Process';
          break;
        case "0":
        case 0:
          status[index] = 'Inactive';
          break;

        default:
          break;
      }
    });
  } else {
    switch (status) {
      case "1":
      case 1:
        status = 'Active';
        break;
      case "4":
      case 4:
        status = 'Renewal Pending';
        break;
      case "6":
      case 6:
        status = 'In Renewal';
        break;
      case "2":
      case 2:
        status = 'On Hold';
        break;
      case "3":
      case 3:
        status = 'In Process';
        break;
      case "0":
      case 0:
        status = 'Inactive';
        break;

      default:
        break;
    }
  }
  return status;
}


export function normalizeOfficeStatusWithColor(statusValue) {
  let status = statusValue;
    switch (status) {
      case 'Active':
        status = '<div class="text-green-500">Active</div>';
        break;
      case 'Renewal Pending':
        status = '<div class="text-blue-500">renewal pending</div>';
        break;
      case 'In Renewal':
        status = '<div class="text-green-300">in renewal</div>';
        break;
      case 'On Hold':
        status = '<div class="text-orange-200">on hold</div>';
        break;
      case 'In Process':
        status = '<div class="text-orange-500">in process</div>';
        break;
      case 'Inactive':
        status = '<div class="text-red-500">inactive</div>';
        break;

      default:
        break;
    }
  return status;
}
