import queryString from 'query-string';
import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';

export class TeamEditorService extends BaseService {
	static get entity() { return 'team'; }

	static team(teamID, params) { return this.get(`team/${teamID}`, params); }

	static teamLeader(teamID, params) { return this.get(`team/${teamID}/teamLeader`, params); }

	static persons(teamID, params) { return this.get(`team/${teamID}/persons`, params); }

	static history(teamID, params) { return this.get(`team/${teamID}/history`, params); }

	static office(officeID, params) { return this.get(`office/${officeID}`, params); }
}
