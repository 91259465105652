import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25e70463"),n=n(),_popScopeId(),n)
const _hoisted_1 = { "data-auto-complete-box": "true" }
const _hoisted_2 = { class: "w-full relative" }
const _hoisted_3 = {
  key: 0,
  class: "block text-xs font-bold mb-1"
}
const _hoisted_4 = { class: "mt-1 relative border border-gray-500 overflow-hidden rounded-md shadow-sm" }
const _hoisted_5 = ["placeholder", "disabled"]
const _hoisted_6 = { class: "absolute w-full z-50 bg-white border border-gray-300 mt-1 max-height-48 overflow-hidden overflow-y-scroll rounded-md shadow-md" }
const _hoisted_7 = { class: "py-1" }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($props.label)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($props.label), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          onKeyup: $setup.onKeyUp,
          onFocus: _cache[0] || (_cache[0] = () => { $setup.showOptions = true; }),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($props.modelValue) = $event)),
          class: _normalizeClass(["w-full px-2 py-2 focus:outline-none focus:ring-0 focus:ring-offset-0 text-sm", [$props.disabled ? 'bg-gray-100 cursor-not-allowed' : '']]),
          autocomplete: "off",
          placeholder: $props.placeholder,
          disabled: $props.disabled
        }, null, 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_5), [
          [_vModelText, $props.modelValue]
        ])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_6, [
        _createElementVNode("ul", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: item.value
            }, [
              (item.value)
                ? (_openBlock(), _createElementBlock("li", {
                    key: 0,
                    onClick: () => $setup.onSelectItem(item.value),
                    class: "px-3 py-2 cursor-pointer hover:bg-gray-200 text-xs"
                  }, _toDisplayString(item.label || item.value), 9 /* TEXT, PROPS */, _hoisted_8))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ], 512 /* NEED_PATCH */), [
        [_vShow, $setup.items.length && $setup.showOptions]
      ])
    ])
  ]))
}