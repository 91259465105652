import { promiseAll } from '@/utils/promiseAll';
import { getDefaultState } from './constants';
import { init, changeHistory, persons, team, } from './init';
import { TeamEditorService } from '../../../services/modules/teamEditor.service';

export default {
	namespaced: true,

	state: getDefaultState(),

	mutations: {
		setDefaultState(state) {
            const _defaultState = getDefaultState();
            Object.keys(_defaultState).forEach((key) => { state[key] = _defaultState[key]; });
        },

        setState(state, partialState) {
            const partial = JSON.parse(JSON.stringify(partialState));
            Object.keys(partial).forEach((key) => { state[key] = partial[key]; });
        },
	},

	actions: {
		init(store, teamID) { return init(store, teamID); },

		team(store, _params) { 
			const { teamID, ...params } = { ..._params } as any;
			return team(store, teamID || store.state.team?.ID, params); 
		},

		persons(store, params) { return persons(store, params); },

		history(store, params) { return changeHistory(store, params); },

		async save(store, { team }) {
			try {
				const teamID = store.state.team?.ID;
				const promises = [];
				if (teamID) {
					promises.push(TeamEditorService.put(`team/${teamID}`, team));
				} else {
					promises.push(TeamEditorService.post('teams', team));
				}
				
				store.commit('setState', { savingTeam: true, });

				const [saveTeamResponse] = await promiseAll(promises);

				if (teamID) await init(store, teamID);

				store.commit('setState', { savingTeam: false, });

				return { saveTeamResponse };
			} catch(e) { 
				store.commit('setState', { savingTeam: false, }); 
				throw e; 
			}
		}
	},
};
  