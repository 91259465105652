import TC from '../template_utilities/templateColumns';
import NC from '../template_utilities/PeriodicalNestedColumns';
import panel from './settingspanel.vue';

export const regional_office_counts_periodical: any = {
  cubeParameters: {
    measures: [
      'OfficeStatusChangesCube.terminationCount',
      'OfficeStatusChangesCube.openingCount',
      'OfficeStatusChangesCube.processedCount',
    ],
    dimensions: ['OfficeStatusChangesCube.regionid', 'RegionCube.region'],
    mainCubeParameter: 'OfficeStatusChangesCube',
    timeParameter: 'OfficeStatusChangesCube.statusdate',
    containsCurrency: false
  },
  nestingDimension: 'regionid',
  columns: [TC.region],
  nestedColumns: NC.RegionalOfficeCountsPeriodical,
  pivot: {
    x: ['measures'],
    y: ['OfficeStatusChangesCube.statusdate'],
  },
  settingsPanel: panel,
};
