import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString($props.isExpanded + $props.isCreateNew), 1 /* TEXT */),
    _createVNode($setup["settingsBasePanel"], {
      onExport: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('export'))),
      onCloseReport: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeReport'))),
      "on!expand": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('!expand'))),
      isExpanded: $props.isExpanded,
      reportTemplateData: $props.reportTemplateData,
      NewTemplateDetailsToSave: $props.NewTemplateDetailsToSave,
      regions: $props.regions,
      containerHeight: 'h-60',
      isExporting: $props.isExporting,
      isCreateNew: $props.isCreateNew,
      isReportDataLoaded: $props.isReportDataLoaded
    }, null, 8 /* PROPS */, ["isExpanded", "reportTemplateData", "NewTemplateDetailsToSave", "regions", "isExporting", "isCreateNew", "isReportDataLoaded"])
  ], 64 /* STABLE_FRAGMENT */))
}