export const getDefaultState = () => ({
	selected: [],
	personAwards: {
		list: [],
		loading: false,
		totalRows: 0,
		currentPage: 1,
		totalPages: 1,
		rowsPerPage: 35,
		queryParams: {
			sort: '-achievedDate,personDisplayName',
		},
	},
	awardsHistory: {
		list: [],
		loading: false,
		totalRows: 0,
		currentPage: 1,
		totalPages: 1,
		rowsPerPage: 35,
		queryParams: {
			sort: '-changeDate',
		},
	},
	regions: [],
	awardsDropdown: [],
	awardTypes: [],
});
