import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_errorModal = _resolveComponent("errorModal")!
  const _component_UiToastList = _resolveComponent("UiToastList")!
  const _component_UiRoot = _resolveComponent("UiRoot")!

  return (_openBlock(), _createBlock(_component_UiRoot, null, {
    default: _withCtx(() => [
      _createCommentVNode(" <VueSimpleContextMenu\n      element-id=\"CustomContextMenu2\"\n      :options=\"CustomContextMenuOptions\"\n      ref=\"CustomContextMenu2\"\n      @option-clicked=\"CustomContextMenuClick\"\n    >\n    </VueSimpleContextMenu> "),
      _createVNode(_component_router_view),
      _createVNode(_component_errorModal),
      _createVNode(_component_UiToastList)
    ]),
    _: 1 /* STABLE */
  }))
}