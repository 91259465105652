export const getDefaultState = () => ({
  globalSearch: {
    query: '',
    results: {},
    loading: false,
  },
  typeSearch: {
    results: [],
    loading: false,
    totalRows: 0,
    currentPage: 1,
    totalPages: 1,
    rowsPerPage: 10,
  },
});
