import TC from '../template_utilities/templateColumns';
import panel from './settingspanel.vue';
import moment from 'moment';
const currentDate = moment().format('YYYY-MM-DD');
const currentYear = moment().format('YYYY');
export const offices: any = {
  cubeParameters: {
    measures: ['PerformanceCube.gciCareer'],
    order: {
      'OfficeCube.officeid': 'asc',
    },
    dimensions: [
      'OfficeCube.officeid',
      'RegionCube.reportingcurrency',
      'RegionCube.regionid',
      'OfficeCube.officename',
      'OfficeCube.regionname',
      'OfficeCube.statusoffice',
      'OfficeCube.specializationnames',
      'OfficeCube.zip',
      'OfficeCube.city',
      'OfficeCube.accountmanagerid',
      'OfficeCube.Conversion',
      'OfficeCube.datesigned',
      'OfficeCube.datefirstlicense',
      'OfficeCube.franchiseterm',
      'OfficeCube.daterenewal',
      'OfficeCube.datecontractreceived',
      'OfficeCube.b1FormReceived',
      'OfficeCube.country',
      'OfficeCube.datecontractprocessed',
      'OfficeCube.datecontractsenttollc',
      'OfficeCube.franchisesize',
      'OfficeCube.ActualPaymentREU',
      'OfficeCube.IdealFeeREU',
      'OfficeCube.datescheduledopen',
      'OfficeCube.datecurrentlicense',
      'OfficeCube.dateactualopen',
      'OfficeCube.dateofficetermination',
      'OfficeCube.contractokdate',
      'OfficeCube.paymentcurrency',
      'OfficeCube.totalagentcount',
      'OfficeCube.officeConstituentId',
      'OfficeCube.franchise_sales_rep_id',
      'OfficeCube.franchisesalesrep',
      'OfficeCube.email',
      'OfficeCube.phone',
      'OfficeCube.franchisetype',
      'OfficeCube.isSatelliteOffice',
      'OfficeCube.externalOfficeId',
      'OfficeCube.subregionname',
      'OfficeCube.officeTerm',
      'OfficeCube.remax_office_id',
      'OfficeCube.contractOk',
      'OfficeCube.paymentAccepted',
      'OfficeCube.currentfranchisefee',
      'OfficeCube.plannedrenewal',
      'OfficeCube.feesPaidDate',
      'OfficeCube.corporateName',
      'OfficeCube.datahubofficeid',
      'OfficeCube.uniqueofficeid',
      'OfficeCube.totalAddress',
    ],
    mainCubeParameter: 'OfficeCube',
    filterParameter: 'OfficeCube.statusoffice',
    timeParameter: 'PerformanceCube.transactiondate',
    statusKey: 'OfficeCube.statusoffice',
  },
  joinDimension: 'officeid',
  alternativeCubeParameters: {
    measures: ['PerformanceCube.gciForPeriod'],
    dimensions: ['OfficeCube.officeid'],
    mainCubeParameter: 'OfficeCube',
    statusKey: 'OfficeCube.statusoffice',
    timeParameter: 'PerformanceCube.transactiondate',
    timeDimensions: [
      {
        dimension: 'PerformanceCube.transactiondate',
        dateRange: [`${currentYear}-01-01`, `${currentYear}-12-31`],
      },
    ],
  },

  columns: [
    TC.officeRegionName,
    TC.subRegion,
    TC.officeName,
    TC.corporateName,
    TC.officeUniqueID,
    TC.statusOffice,
    TC.mainOffice,
    TC.SatelliteOffice,
    TC.officeSpecialization,
    TC.officeStreet,
    TC.ZIP,
    TC.city,
    TC.country,
    TC.email,
    TC.phone,
    TC.rankingsAwardsExcluded,
    TC.gciCareer,
    TC.gciYearly,
    TC.officeLegacyID,
    TC.officeConstituentID,
    TC.officeExternalIDOffice,
    TC.officeInternalID,
    TC.conversion,
    TC.franchiseSalesRep,
    TC.franchiseSalesRepUniqueID,
    TC.signedDate,
    TC.currentLicenseDate,
    TC.firstLicenceDate,
    TC.term,
    TC.renewalDate,
    TC.contractReceived,
    TC.contractOK,
    TC.contractOkDate,
    TC.contractProcess,
    TC.contractSendToLLC,
    TC.franchiseSize,
    TC.currentFranchiseFee,
    TC.plannedRenewalFee,
    TC.scheduledOpeningDate,
    TC.actualOpeningDate,
    TC.terminationDate,
    TC.feesPaidInFull,
    TC.b1FormRecieved,
    TC.actualFeePaid,
    TC.actualToREU,
    TC.paymentAccepted,
    TC.paymentAcceptedDate,
    TC.totalAgentCount,
  ],
  settingsPanel: panel,
};
