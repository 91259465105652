export const SEARCH_KEY = 'textFilter[name,corporateName,uniqueOfficeID,remaxOfficeID,city,countAgents,regionName]';
export const STATE_FILTER_KEY = 'filter[officeIsActive][in]';
export const REGION_FILTER_KEY = 'filter[regionName][in]';
export const CREATE_PERMISSIONS = [
  'OFFICE_CREATE',
  'OFFICE_REQUEST_CREATE'
];
export const EDIT_PERMISSIONS = [
  'OFFICE_GENERAL_UPDATE',
  'OFFICE_REGION_AND_IDS_UPDATE',
  'OFFICE_ADDRESS_UPDATE',
  'OFFICE_CONTACT_UPDATE',
  'OFFICE_PERSONS_REQUEST_UPDATE',
  'OFFICE_PERSONS_UPDATE',
  'OFFICE_MULTI_BROKERAGE_REQUEST_UPDATE',
  'OFFICE_MULTI_BROKERAGE_UPDATE',
  'OFFICE_OWNERSHIP_UPDATE',
  'OFFICE_OWNERSHIP_REQUEST_UPDATE',
  'OFFICE_FINANCE_UPDATE',
  'OFFICE_FINANCE_REQUEST_UPDATE',
  'OFFICE_NOTES_UPDATE',
];
export const DELETE_PERMISSIONS = [
  'OFFICE_DELETE', 
  'OFFICE_REQUEST_DELETE'
];
