import { SearchService } from '@/services/modules/search.service';

export default {
  namespaced: true,
  state: {
    persons: [],
    companies: [],
    offices: [],
    searchingPersons: false,
    searchingCompanies: false,
    searchingOffices: false,
  },
  mutations: {
    setOffices(state, data) {
      state.offices = data;
    },

    setPersons(state, data) {
      state.persons = data;
    },

    setCompanies(state, data) {
      state.companies = data;
    },  

    setSearchingOffices(state, searching) {
      state.searchingOffices = searching;
    },

    setSearchingPersons(state, searching) {
      state.searchingPersons = searching;
    },

    setSearchingCompanies(state, searching) {
      state.searchingCompanies = searching;
    },   
  },
  actions: {
    async persons({ commit }, params) {
      try {
        commit('setSearchingPersons', true);
        const res = await SearchService.persons(params);
        const data = res?.data?.result || [];
        commit('setPersons', data);
        commit('setSearchingPersons', false);
        return data;
      } catch(e) { commit('setSearchingPersons', false); throw e; }
    },

    async companies({ commit }, params) {
      try {
        commit('setSearchingCompanies', true);
        const res = await SearchService.companies(params);
        const data = res?.data?.result || [];
        commit('setCompanies', data);
        commit('setSearchingCompanies', false);
        return data;
      } catch(e) { commit('setSearchingCompanies', false); throw e; }
    },

    async offices({ commit }, params) {
      try {
        commit('setSearchingOffices', true);
        const res = await SearchService.offices(params);
        const data = res?.data?.result?.officeShares || [];
        commit('setOffices', data);
        commit('setSearchingOffices', false);
        return data;
      } catch(e) { commit('setSearchingOffices', false); throw e; }
    },
  },
};
