export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    widthExtraSmall: 320,
    widthSmall: 640,
    widthTablet: 1024,
    isSideBarOpen: true,
    errorList: [],
  },

  mutations: {
    SET_WINDOW_WIDTH(state, value) {
      state.windowWidth = value;
    },
    toggleSideBar(state) {
      state.isSideBarOpen = !state.isSideBarOpen;
    },
    setErrorList(state, value) {
      state.errorList = value;
    },
  },

  getters: {
    getSideBarState: (state) => state.isSideBarOpen,
    isExtraSmall: (state) => state.windowWidth <= state.widthExtraSmall,
    isSmall: (state) => state.windowWidth <= state.widthSmall,
    isTablet: (state) => state.windowWidth <= state.widthTablet,
    isDesktop: (state) => state.windowWidth > state.widthTablet,
    getErrorList: (state) => state.errorList,
  },
};
