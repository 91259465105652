export const getDefaultState = () => ({
  notification: [],
  loading: false,
  unreadNotifications: 0,
  viewAll: {
    notification: [],
    totalRows: 0,
    currentPage: 1,
    totalPages: 1,
    rowsPerPage: 35,
    loading: false,
    typeFilter: '',
    searchValue: '',
  },
});
