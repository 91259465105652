import TC from '../template_utilities/templateColumns';
import panel from './settingspanel.vue';

export const agent_performance_aggregated_per_team: any = {
  cubeParameters: {
    measures: [
      'PerformanceCube.gciForPeriod',
      'PerformanceCube.transactionForPeriod',
      'PerformanceCube.volumeForPeriod',
      'PerformanceCube.gciForPeriodRxd',
      'PerformanceCube.gciForPeriodEur',
      'PerformanceCube.volumeForPeriodRxd',
      'PerformanceCube.volumeForPeriodEur',
    ],
    order: [
      ['personCube.idperson', 'asc'],
      ['OfficeCube.officename', 'asc'],
      ['personCube.lastname', 'asc'],
      ['personCube.firstname', 'asc'],
    ],
    dimensions: [
      'PerformanceCube.regionid',
      'RegionCube.region',
      'personCube.idperson',
      'personCube.TeamID',
      'personCube.subregionname',
      'personCube.teamllcid',
      'personCube.lastname',
      'personCube.firstname',
      'TeamCube.teamname',
      'TeamCube.teamid',
      'TeamCube.teamllcid',
      'personCube.uniquepersonid',
      'personCube.tenuredays',
      'personCube.primarytitle',
      'personCube.isactive',
      'personCube.reportingcurrencyiso',
      'personCube.idllcperson',
      'personCube.display_title',
      'personCube.titleprimary',
      'personCube.title',
      'OfficeCube.officename',
      'OfficeCube.uniqueofficeid',
      'OfficeCube.officeConstituentId',
      'OfficeCube.statusoffice',
    ],
    mainCubeParameter: 'PerformanceCube',
    filterParameter: 'PerformanceCube.transactiondate',
    timeParameter: 'PerformanceCube.transactiondate',
  },
  titleLevelParameters: {
    measures: [
      'TitleLevelPerformance.gciForPeriod',
      'TitleLevelPerformance.transactionForPeriod',
      'TitleLevelPerformance.volumeForPeriod',
      'TitleLevelPerformance.sumCommissionResidentialSale',
    ],
    dimensions: [
      'TitleLevelPerformance.regionid',
      'TitleLevelPerformance.raterxd',
      'TitleLevelPerformance.rateeur',
      'RegionCube.region',
      'personCube.idperson',
      'personCube.TeamID',
      'personCube.subregionname',
      'personCube.teamllcid',
      'personCube.lastname',
      'personCube.firstname',
      'TeamCube.teamname',
      'TeamCube.teamid',
      'TeamCube.teamllcid',
      'personCube.uniquepersonid',
      'personCube.tenuredays',
      'personCube.primarytitle',
      'personCube.isactive',
      'personCube.reportingcurrencyiso',
      'personCube.idllcperson',
      'personCube.display_title',
      'personCube.titleprimary',
      'personCube.title',
      'OfficeCube.officename',
      'OfficeCube.uniqueofficeid',
      'OfficeCube.officeConstituentId',
      'OfficeCube.statusoffice',
    ],
    mainCubeParameter: 'TitleLevelPerformance',
    filterParameter: 'TitleLevelPerformance.date',
    timeParameter: 'TitleLevelPerformance.date',
  },

  columns: [
    TC.region,
    TC.subRegion,
    TC.officeName,
    TC.officeUniqueID,
    TC.officeConstituentID,
    TC.teamName,
    TC.teamllcid,
    TC.lastName,
    TC.firstName,
    TC.uniqueID,
    TC.personConstituentID,
    TC.tenureDays,
    TC.primaryTitle,
    TC.status,
    TC.gciPeriodStandard,
    TC.transactionsPeriodStandard,
    TC.volumePeriodStandard,
  ],
  settingsPanel: panel,
};






// import TC from '../template_utilities/templateColumns';
// import panel from './settingspanel.vue';

// export const agent_performance_aggregated_per_team: any = {
//   cubeParameters: {
//     measures: [
//       'TitleLevelPerformance.sumCommissionResidentialSale',
//       'TitleLevelPerformance.sumCommission',
//       'TitleLevelPerformance.sumTransaction',
//       'TitleLevelPerformance.sumVolume',
//       'TitleLevelPerformance.avgRateRxd',
//       'TitleLevelPerformance.avgRateEur',
//       'PerformanceCube.gciForPeriod',
//       'PerformanceCube.transactionForPeriod',
//       'PerformanceCube.volumeForPeriod',
//       'PerformanceCube.gciForPeriodRxd',
//       'PerformanceCube.gciForPeriodEur',
//       'PerformanceCube.volumeForPeriodRxd',
//       'PerformanceCube.volumeForPeriodEur',
//     ],
//     order: [
//       ['personCube.idperson', 'asc'],
//       ['OfficeCube.officename', 'asc'],
//       ['personCube.lastname', 'asc'],
//       ['personCube.firstname', 'asc'],
//     ],
//     dimensions: [
//       'PerformanceCube.regionid',
//       'RegionCube.region',
//       'personCube.idperson',
//       'personCube.TeamID',
//       'personCube.subregionname',
//       'personCube.teamllcid',
//       'personCube.lastname',
//       'personCube.firstname',
//       'TeamCube.teamname',
//       'TeamCube.teamid',
//       'TeamCube.teamllcid',
//       'personCube.uniquepersonid',
//       'personCube.tenuredays',
//       'personCube.primarytitle',
//       'personCube.isactive',
//       'personCube.reportingcurrencyiso',
//       'personCube.idllcperson',
//       'personCube.display_title',
//       'personCube.titleprimary',
//       'personCube.title',
//       'OfficeCube.officename',
//       'OfficeCube.uniqueofficeid',
//       'OfficeCube.officeConstituentId',
//       'OfficeCube.statusoffice',
//     ],
//     mainCubeParameter: 'PerformanceCube',
//     filterParameter: 'PerformanceCube.transactiondate',
//     timeParameter: 'PerformanceCube.transactiondate',
//   },

//   columns: [
//     TC.region,
//     TC.subRegion,
//     TC.officeName,
//     TC.officeUniqueID,
//     TC.officeConstituentID,
//     TC.teamName,
//     TC.teamllcid,
//     TC.lastName,
//     TC.firstName,
//     TC.uniqueID,
//     TC.personConstituentID,
//     TC.tenureDays,
//     TC.primaryTitle,
//     TC.status,
//     TC.gciPeriodStandard,
//     TC.transactionsPeriodStandard,
//     TC.volumePeriodStandard,
//   ],
//   settingsPanel: panel,
// };
