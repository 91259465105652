import * as reportTemplateList from '../templates';
import { cloneDeep } from 'lodash';

export function initializePeriodicalGenerate(template) {
  try {
    let responseObject: any = {
      selectedColumns: [],
      nestedColumns: [],
      columnOptions: [],
      columnDefinitions: [],
    };
    const templateData = template.templateData;

    responseObject.nestedColumns = cloneDeep(reportTemplateList[templateData.templateKey].nestedColumns);
    responseObject.columnDefinitions = cloneDeep(reportTemplateList[templateData.templateKey].columns);
    const yearPattern = /\b\d{4}\b/;
    responseObject.selectedColumns = template.selectedColumns;
    responseObject.columnOptions = [
      ...responseObject.columnDefinitions.filter((item) => !yearPattern.test(item.name)),
      ...reportTemplateList[templateData.templateKey].nestedColumns,
    ];

    return responseObject;
  } catch (e) {
    /**/
  }
}
