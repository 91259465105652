import * as mockData from '@/store/mock-data';
import * as types from '@/types';

export default {
    namespaced: true,

    state: {
        loadingPackages: false,
        packages: [],
    },

    actions: {
        async getPackages({ commit }) {
            commit('setPackagesLoading', true);
            // make api call...
            const res = await new Promise(resolve => {
            setTimeout(() => {
                commit('setPackagesData', mockData.packages);
                commit('setPackagesLoading', false);
                resolve(mockData.packages);
                }, 0);
            });
            return res;
        },
    },

    mutations: {
        setPackagesLoading(state, loadingState) {
            state.loadingPackages = loadingState;
        },

        setPackagesData(state, data: types.Package[] = []) {
            state.packages = data;
        },
    },
};
