import TC from '../template_utilities/templateColumns';
import PTC from './otherChangesColumns';
import panel from './settingspanel.vue';

export const person_other_changes: any = {
  cubeParameters: {
    recurring: true,
    filterParameter: 'TitleLevelPerformance.date',
    timeParameter: 'TitleLevelPerformance.date',
  },
  ExcelSheetsTitles: ['Terminations', 'Transfers', 'Reactivations', 'Other Changes'],
  ExcelSheets: [
    [
      TC.personConstituentIDRecurring,
      TC.firstName,
      TC.lastName,
      TC.terminationDateRecurring,
      TC.terminationReasonRecurring,
      TC.officeConstituentIDRecurring,
      TC.officeNameRecurring,
      TC.internalOfficeIDRecurring,
      TC.PrimaryTitleRecurring,
      TC.internalRecurring,
      TC.region_name_recurring,
      TC.legacyMemberIDRecurring,
      TC.unique_person_id,
      TC.statusMemberRecurring,
      TC.commencementDatePersonOtherChanges,
      TC.tenureRecurring,
      TC.gciYearRecurring,
      TC.officeUniqueIDRecurring,
      TC.statusOfficePersonOtherChanges,
      TC.entered_by,
      TC.entered_on,
      TC.changeTypeRecurring,
    ],
    [
      TC.personConstituentIDRecurring,
      TC.firstName,
      TC.lastName,
      TC.transferDateRecurring,
      TC.officeConstituentIDRecurring,
      TC.officeNameRecurring,
      TC.internalOfficeIDRecurring,
      TC.PrimaryTitleRecurring,
      TC.internalRecurring,
      TC.region_name_recurring,
      TC.legacyMemberIDRecurring,
      TC.unique_person_id,
      TC.formerStatusRecurring,
      TC.statusMemberRecurring,
      TC.formerTitleRecurring,
      TC.formerTitleValidToRecurring,
      TC.officeUniqueIDRecurring,
      TC.formerOfficeUniqueIDRecurring,
      TC.statusCurrentOfficeRecurring,
      TC.actualOpeningDateRecurring,
      TC.SatelliteOfficeRecurring,
      TC.entered_by,
      TC.entered_on,
      TC.changeTypeRecurring,
    ],
    [
      TC.personConstituentIDRecurring,
      TC.firstName,
      TC.lastName,
      TC.reactivationDateRecurring,
      TC.officeConstituentIDRecurring,
      TC.officeNameRecurring,
      TC.internalOfficeIDRecurring,
      TC.PrimaryTitleRecurring,
      TC.internalRecurring,
      TC.region_name_recurring,
      TC.legacyMemberIDRecurring,
      TC.unique_person_id,
      TC.statusMemberRecurring,
      TC.formerTitleRecurring,
      TC.formerTitleValidToRecurring,
      TC.officeUniqueIDRecurring,
      TC.statusCurrentOffice,
      TC.SatelliteOfficeRecurring,
      TC.entered_by,
      TC.entered_on,
      TC.changeTypeRecurring,
    ],
    [
      PTC.prevConstituentMemberIDRecurring,
      PTC.newConstituentMemberIDRecurring,
      PTC.constituentMemberIDRecurringOutcome,
      PTC.prevPersonUniqueID,
      PTC.newPersonUniqueID,
      PTC.personUniqueIDOutcome,
      PTC.prevPersonLegacyID,
      PTC.newPersonLegacyID,
      PTC.personLegacyIDOutcome,
      PTC.prevfirstName,
      PTC.newfirstName,
      PTC.firstNameOutcome,
      PTC.prevLastName,
      PTC.newLastName,
      PTC.lastNameOutcome,
      PTC.prevPrimaryTitle,
      PTC.newPrimaryTitle,
      PTC.primaryTitleOutcome,
      PTC.prevTitleValidTo,
      PTC.newTitleValidFrom,
      PTC.titleValidFromOutcome,
      PTC.prevStatusMember,
      PTC.newStatusMember,
      PTC.statusMemberOutcome,
      PTC.prevADA,
      PTC.newADA,
      PTC.ADAOutcome,
      PTC.prevCommencementDate,
      PTC.newCommencementDate,
      PTC.commencementDateOutcome,
      PTC.prevTeamStatus,
      PTC.newTeamStatus,
      PTC.teamStatusOutcome,
      PTC.prevTeamStatusDate,
      PTC.newTeamStatusDate,
      PTC.teamStatusDateOutcome,
      PTC.prevTeamName,
      PTC.newTeamName,
      PTC.teamNameOutcome,
      PTC.prevTeamID,
      PTC.newTeamID,
      PTC.teamIDOutcome,
      PTC.prevOfficeUniqueID,
      PTC.newOfficeUniqueID,
      PTC.officeUniqueIDOutcome,
      PTC.prevOfficeConstituentID,
      PTC.newOfficeConstituentID,
      PTC.officeConstituentIDOutcome,
      PTC.prevOfficeLegacy,
      PTC.newOfficeLegacy,
      PTC.officeLegacyOutcome,
      PTC.prevOfficeName,
      PTC.newOfficeName,
      PTC.officeNameOutcome,
      PTC.prevFranchiseSize,
      PTC.newFranchiseSize,
      PTC.franchiseSizeOutcome,
      PTC.prevGender,
      PTC.newGender,
      PTC.genderOutcome,
      PTC.prevSocialSecurityNumber,
      PTC.newSocialSecurityNumber,
      PTC.socialSecurityNumberOutcome,
      PTC.prevBirthDate,
      PTC.newBirthDate,
      PTC.birthDateOutcome,
      PTC.prevPrimaryEmail,
      PTC.newPrimaryEmail,
      PTC.primaryEmailOutcome,
      PTC.prevPrimaryPhone,
      PTC.newPrimaryPhone,
      PTC.primaryPhoneOutcome,
      PTC.prevRegion,
      PTC.newRegion,
      PTC.regionOutcome,
      PTC.enteredBy,
      PTC.enteredOn,
      TC.changeTypeRecurring,
    ],
  ],
  settingsPanel: panel,
};
