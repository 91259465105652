import { people } from './people';
import { remaxTitles, regions, statuses, } from './filters';

export { people };

export const peopleFilters = [
    {
        name: 'RE/MAX titles',
        options: remaxTitles,
    },
    {
        name: 'Regions',
        options: regions,
    },
    {
        name: 'Status',
        options: statuses,
    }
];
