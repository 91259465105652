import queryString from 'query-string';
import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';

export class ReportingService extends BaseService {
  static get entity() {
    return '';
  }

  static async getRegionsReport({ currentPage, rowsPerPage, ...query }) {
    currentPage = currentPage || 1;
    rowsPerPage = rowsPerPage || 35;
    try {
      let response = await this.request().get(
        `reportingRegionsList?${queryString.stringify({
          //MAX-1129
          //limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
          sort: 'name',
          ...query,
        })}`
      );
      return new ResponseWrapper(response, response.data.result);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async newestCurrencyExchangeRates(params) {
    try {
      let response = await this.request().get(
        `newestCurrencyExchangeRates?${queryString.stringify({
          ...params,
        })}`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getCurrentMonthReportForOffice(officeID, params) {
    try {
      let response = await this.request().get(
        `/office/${officeID}/reportingListingTransactions?${queryString.stringify({
          ...params,
        })}`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getRegionOverviewByMonth(regionID, params) {
    try {
      let response = await this.request().get(
        `/reportingRegionOfficeList/${regionID}?${queryString.stringify({
          ...params
        })}`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getUpdateMonthlyReportingPerformanceTables(params){
    try {
      let response = await this.request().post(`/reporting/isRefreshingCronjob`, params);
      return response.data.result;
    }catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getReportingRegionOverview(regionID, params) {
    try {
      let response = await this.request().get(
        `/selectReportingRegionOverviewSums/${regionID}?${queryString.stringify({
          ...params,
        })}`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async saveReport(officeID, payload) {
    try {
      let response = await this.request().post(`/office/${officeID}/reportingOffice`, payload);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getChangeHistory(officeID, params) {
    try {
      let response = await this.request().get(
        `/office/${officeID}/monthlyReportingHistory?${queryString.stringify({
          ...params,
        })}`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getRegionalYearsInformationForReporting(regionID) {
    try {
      let response = await this.request().get(`region/${regionID}/RegionalYearsInformationForReporting`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getReportingRegionMonthsList(regionID, params) {
    try {
      let response = await this.request().get(
        `reportingRegionMonthsList/${regionID}?${queryString.stringify({
          ...params,
        })}`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  static async closeReportingPeriod(regionID, params) {
    try {
      let response = await this.request().put(`/region/${regionID}/closeReportingForPeriod`, params);
      return new ResponseWrapper(response, "The reporting period has been closed");
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async uploadFiles(regionID, date, formData) {
    try {
      let response = await this.post(`/reporting/${regionID}/multiUpload/${date}`, formData);
      console.log(formData);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getAllOfficeReporting(regionID, params) {
    try {
      let response = await this.request().get(
        `reporting/${regionID}/getReportingDataForZipExcelExport?${queryString.stringify({
          ...params,
        })}`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
