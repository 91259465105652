import { ExchangeRatesService } from '../../services/modules/exchangeRates.service';


export default {
  namespaced: true,
  state: {
    exchangeRates :{
      list: [],
      totalRows: 0,
      totalPages: 0,
      currentPage: 1,
      rowsPerPage: 35,
  },
  exchangeRatesMonth :{
    list:[],
    totalRows: 0,
    totalPages: 0,
    currentPage: 1,
    rowsPerPage: 35,
  },
  exchangeRatesDay :{
    list:[],
     totalRows: 0,
      totalPages: 0,
      currentPage: 1,
      rowsPerPage: 35,
  },
  internationalDollarList :{
    list: [],
    totalRows: 0,
    totalPages: 0,
    currentPage: 1,
    rowsPerPage: 35,
},

  internationalDollarForRegionByYear :{
    list: [],
    totalRows: 0,
    totalPages: 0,
    currentPage: 1,
    rowsPerPage: 35,
},

  followingYear: null,

    newestCurrencyExchangeRates: [],
    //exchangeRatesMonth: [],
  },

  mutations: {
    setExchangeRatesForDay(state, data) {
      state.exchangeRatesDay = { ...state.exchangeRatesDay, ...data };
    },

    setExchangeRatesForMonth(state, data) {
      state.exchangeRatesMonth = { ...state.exchangeRatesMonth, ...data };
    },

    setExchangeRates(state, data) {
      state.exchangeRates = { ...state.exchangeRates, ...data };
    },

    setInternationalDollarList(state, data) {
      state.internationalDollarList = { ...state.internationalDollarList, ...data };
    },

    setInternationalDollarForRegionByYear(state, data) {
      state.internationalDollarForRegionByYear = { ...state.internationalDollarForRegionByYear, ...data };
    },

    setFollowingYear(state, data) {
      state.followingYear = data;
    },

  },

  
  actions: {
    async exchangeRatesOverview ({ commit, state}, params) {
      try {
        const rowsPerPage = params.rowsPerPage || state.exchangeRates.rowsPerPage;
        let currentPage = params.currentPage;
        if (!state.exchangeRates.totalPages && currentPage < 1) currentPage = 1;

        const res = await ExchangeRatesService.exchangeRatesOverview({ ...params, currentPage, rowsPerPage, });

        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        currentPage = currentPage > totalPages ? totalPages : currentPage;

        commit('setExchangeRates', {
          list: res.data.result,
          totalRows,
          rowsPerPage,
          currentPage,
          totalPages,
        });
        return res;
      } catch (e) {
        throw e;
      }
    },
    
    async getExchangeRateByMonth({commit, state}, params: { year: string | number; month: string | number; rowsPerPage: string | number; currentPage: string | number; }) {
      try {
        const rowsPerPage = params.rowsPerPage || state.exchangeRatesMonth.rowsPerPage;
        let currentPage = params.currentPage;

        if (!state.exchangeRatesMonth.totalPages && currentPage < 1) currentPage = 1;
        
        const res = await ExchangeRatesService.getExchangeRateByMonth(params.year, params.month, {currentPage, rowsPerPage});
        const data = res?.data?.result || [];

        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        currentPage = currentPage > totalPages ? totalPages : currentPage;


        commit('setExchangeRatesForMonth', {
            list: res.data.result,
            totalRows,
            rowsPerPage,
            currentPage,
            totalPages,
          });
        return data;
      } catch (e) {
        throw e;
      }
    },



    async getExchangeRateByDay({commit, state}, params: { year: string | number; month: string | number; isocurrency: string | number; rowsPerPage: string | number; currentPage: string | number;}) {
      try {

        const rowsPerPage = params.rowsPerPage || state.exchangeRatesMonth.rowsPerPage;
        let currentPage = params.currentPage;

        if (!state.exchangeRatesDay.totalPages && currentPage < 1) currentPage = 1;

        const res = await ExchangeRatesService.getExchangeRateByDay(params.year, params.month, params.isocurrency, {currentPage, rowsPerPage});
        const data = res?.data?.result || [];

        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        currentPage = currentPage > totalPages ? totalPages : currentPage;

        commit('setExchangeRatesForDay', {
            list: res.data.result,
            totalRows,
            rowsPerPage,
            currentPage,
            totalPages,
          });
        return data;
      } catch (e) {
        throw e;
      }
    },


    async internationalDollarList ({ commit, state}, params) {
      try {
        const rowsPerPage = params.rowsPerPage || state.internationalDollarList.rowsPerPage;
        let currentPage = params.currentPage;
        if (!state.internationalDollarList.totalPages && currentPage < 1) currentPage = 1;

        const res = await ExchangeRatesService.getInternationalDollarList({ ...params, currentPage, rowsPerPage, });

        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        currentPage = currentPage > totalPages ? totalPages : currentPage;

        commit('setInternationalDollarList', {
          list: res.data.result,
          totalRows,
          rowsPerPage,
          currentPage,
          totalPages,
        });
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getInternationalDollarForRegionByYear ({ commit, state}, params) {
      try {
        const rowsPerPage = params.rowsPerPage || state.internationalDollarList.rowsPerPage;
        let currentPage = params.currentPage;
        if (!state.internationalDollarList.totalPages && currentPage < 1) currentPage = 1;

        const res = await ExchangeRatesService.getInternationalDollarForRegionByYear({ ...params, currentPage, rowsPerPage, });

        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        currentPage = currentPage > totalPages ? totalPages : currentPage;

        commit('setInternationalDollarForRegionByYear', {
          list: res.data.result,
          totalRows,
          rowsPerPage,
          currentPage,
          totalPages,
        });
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getFollowingYear({commit, state}) {
      try {
        const res = await ExchangeRatesService.getFollowingYear();
        commit('setFollowingYear', res.data.result.year);
      } catch (e) {
        throw e;
      }
    },

    }
};
