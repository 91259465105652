import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex justify-between items-center mb-4",
  style: {"min-height":"38px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadcrumbsComponent = _resolveComponent("BreadcrumbsComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BreadcrumbsComponent),
    _renderSlot(_ctx.$slots, "content")
  ]))
}