import { ContactMgmtService } from '@/services/modules/contactMgmt.service';
import { promiseAll } from '@/utils/promiseAll';
// import { getDefaultState } from './constants';
// import { init } from './init';

export default {
  namespaced: true,
  state: {
    contactPersonsList: [],
    contactPersons: {},
    contactCompany: {},
  },
  getters: {
    getContactPersonsList: (state) => state.contactPersonsList,
    selectContactPerson: (state) => state.contactPerson,
    selectContactCompany: (state) => state.contactCompany,
  },
  mutations: {
    GET_CONTACT_PERSON_LIST(state, data) {
      state.contactPersonsList = { ...state.contactPersonsList, ...data };
    },
  },
  actions: {
    async getContactPersonList({ commit }) {
      try {
        const res = await ContactMgmtService.getContactPersonList();
        commit('GET_CONTACT_PERSON_LIST', res);
        return res;
      } catch (e) {
        throw e;
      }
    },
  },

  async selectContactPerson({ commit }, id) {
    try {
      const res = await ContactMgmtService.selectContactPerson(id);
      return res;
    } catch (e) {
      throw e;
    }
  },

  async selectContactCompany({ commit }, id) {
    try {
      const res = await ContactMgmtService.selectContactCompany(id);
      return res;
    } catch (e) {
      throw e;
    }
  },
};
