import { renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["Popover"], null, {
    default: _withCtx(() => [
      _createVNode($setup["Float"], {
        placement: "bottom-start",
        offset: 3,
        shift: 6,
        flip: 10,
        arrow: "",
        portal: "",
        enter: "transition duration-200 ease-out",
        "enter-from": "opacity-0 -translate-y-1",
        "enter-to": "opacity-100 translate-y-0",
        leave: "transition duration-150 ease-in",
        "leave-from": "opacity-100 translate-y-0",
        "leave-to": "opacity-0 -translate-y-1"
      }, {
        default: _withCtx(() => [
          _createVNode($setup["PopoverButton"], { class: "flex items-center text-gray-600 focus:ring-transparent ring-0 outline-none" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "PopoverButtonContent")
            ]),
            _: 3 /* FORWARDED */
          }),
          _createVNode($setup["PopoverPanel"], { class: "bg-white border border-gray-200 rounded-md shadow-lg focus:outline-none" }, {
            default: _withCtx(() => [
              _createCommentVNode(" <FloatArrow class=\"absolute -z-10 bg-white w-5 h-5 rotate-45 border border-gray-200\" /> "),
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3 /* FORWARDED */
          })
        ]),
        _: 3 /* FORWARDED */
      })
    ]),
    _: 3 /* FORWARDED */
  }))
}