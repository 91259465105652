import { mergeArrays, mergeArraysForPeriodicals, mergeArraysForNestedPeriodicals } from './index';
import * as reportTemplateList from '../templates';
import store from '@/store';
import { normalizeDateFrom, normalizeDateTo, normalizeAgentResponse, AddMissingMonthsToPivotArray } from '../helpers';
import { cloneDeep } from 'lodash';
export async function getReportData(templateParam, filterObject = null) {
  try {
    let template = cloneDeep(templateParam);

    let responseObject: any = {};
    let regionID = template.regionID;
    if (template.regionID < 0) {
      regionID = null;
    }
    if (template.status) {
      if (template.status.length < 1) {
        template.status = null;
      }
    }
    if (template.templateID === 13) {
      let res = await store.dispatch('reports/getMonthlyAgentProduction', {
        query: {
          regionId: regionID,
          endDate: normalizeDateTo(template.validTo),
        },
      });
      if (!Array.isArray(res)) {
        if (typeof res === 'object' && res !== null) {
          res = [];
        }
      }
      responseObject.data = res;
      return responseObject;
    }
    if (template.templateID === 47) {
      let res = await store.dispatch('reports/getRecurring', {
        query: {
          reportId: template.ID,
          template: 'dailyNewMembers',
        },
      });
      if (!Array.isArray(res)) {
        if (typeof res === 'object' && res !== null) {
          res = [];
        }
      }
      responseObject.data = res;
      return responseObject;
    }
    if (template.templateID === 35) {
      let res = await store.dispatch('reports/getRecurring', {
        query: {
          reportId: template.ID,
          template: 'officeStatusChanges',
        },
      });
      if (!Array.isArray(res)) {
        if (typeof res === 'object' && res !== null) {
          res = [];
        }
      }
      responseObject.data = res;
      return responseObject;
    }
    if (template.templateID === 49) {
      let res = await store.dispatch('reports/getRecurring', {
        query: {
          reportId: template.ID,
          template: 'personOtherChanges',
        },
      });
      if (!Array.isArray(res)) {
        if (typeof res === 'object' && res !== null) {
          res = [];
        }
      }
      responseObject.data = res;
      return responseObject;
    }
    if (template.templateID === 43) {
      if (template.status) {
        if (Array.isArray(template.status) && template.status.length === 1) {
          template.status.forEach((value, index) => {
            template.status[index] = value === '1' ? true : false;
          });
        } else {
          template.status = null;
        }
      }
      let res = await store.dispatch('reports/getAgentReportDataForAllRegions', {
        regionId: regionID,
        status: template.status ? template.status : null,
        pageNumber: filterObject?.currentPage,
        rows: filterObject?.pageSize,
        search: filterObject?.searchTerm,
      });
      if (!res.rowCount) {
        res = [];
        responseObject.data = res;
        responseObject.totalRows = 0;
        return responseObject;
      }
      const normalizedRes = res?.rows.map((obj) => {
        return normalizeAgentResponse(obj);
      });

      responseObject.data = normalizedRes;
      responseObject.totalRows = res.rowCount;
      return responseObject;
    }

    const cubeQuery = {
      regionID: regionID,
      status: template.status,
      currency: template.currency,
      validFrom: normalizeDateFrom(template.validFrom),
      validTo: normalizeDateTo(template.validTo),
      office: template.office,
      officeName: template.officeName,
      periodFlag: template.periodFlag,
      limit: filterObject?.pageSize || null,
      offset: (filterObject?.currentPage - 1) * filterObject?.pageSize,
      searchTerm: filterObject?.searchTerm,
      dimensions: filterObject?.dimensions,
      export: filterObject?.export,
    };

    const templateV = reportTemplateList[template.templateKey];
    let paramsIdentifier = 'cubeParameters';

    let threeYearsAgo = new Date();
    threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 3);
    let fromDate = new Date(cubeQuery.validFrom);
    if (reportTemplateList[template.templateKey].titleLevelParameters && threeYearsAgo < fromDate) {
      paramsIdentifier = 'titleLevelParameters';
    }
    let alternativeReportData;
    const reportData = await store.dispatch('reports/getReportData', {
      cubeTemplate: cubeQuery,
      template: templateV,
      cubeParamsIdentifier: paramsIdentifier,
    });
    const responseArr1 = reportData.data.map((obj) => {
      return Object.keys(obj).reduce((acc, key) => {
        let newKey = key.split('.').slice(1).join('.');
        if (newKey === 'reportingcurrency') {
          newKey = 'reportingcurrencyiso';
        }
        acc[newKey] = obj[key];
        return acc;
      }, {});
    });

    // Initialize the result object
    let mergedArr = [];
    let nestingDimension = reportTemplateList[template.templateKey].nestingDimension
      ? reportTemplateList[template.templateKey].nestingDimension
      : 'regionid';
    // const ArrayWithMissingMonth = AddMissingMonthsToPivotArray(mergedArr[0], cubeQuery.validTo);
    let timeDimensionInRange = [];
    let timeDimension;
    if (cubeQuery.periodFlag === 2) {
      timeDimension = 'year';
      timeDimensionInRange = getYearsInRange(cubeQuery.validFrom, cubeQuery.validTo);
    } else {
      timeDimension = 'month';
      timeDimensionInRange = getMonthsInRange(cubeQuery.validFrom, cubeQuery.validTo);
    }
    if (!reportTemplateList[template.templateKey].alternativeCubeParameters) {
      if (isPeriodicalReport(template.templateID)) {
        mergedArr = nestObjectsByParamId(nestingDimension, responseArr1, timeDimensionInRange, cubeQuery.periodFlag);
        responseObject.data = mergedArr;
      } else {
        responseObject.data = responseArr1;
      }
      responseObject.totalRows = reportData.totalRows;
      return responseObject;
    }

    alternativeReportData = await store.dispatch('reports/getReportData', {
      cubeTemplate: cubeQuery,
      template: templateV,
      cubeParamsIdentifier: 'alternativeCubeParameters',
    });

    const responseArr2 = alternativeReportData.data.map((obj) => {
      return Object.keys(obj).reduce((acc, key) => {
        let newKey = key.split('.').slice(1).join('.');
        if (newKey === 'reportingcurrency') {
          newKey = 'reportingcurrencyiso';
        }
        acc[newKey] = obj[key];
        return acc;
      }, {});
    });
    let joinDimension = reportTemplateList[template.templateKey].joinDimension;
    if (isPeriodicalReport(template.templateID)) {
      let mergedCubeResponses;
      if (template.templateID === 20) {
        mergedCubeResponses = mergeArraysForNestedPeriodicals(
          responseArr1,
          responseArr2,
          joinDimension ? joinDimension : 'regionid',
          timeDimension
        );
      } else {
        mergedCubeResponses = mergeArraysForPeriodicals(
          responseArr1,
          responseArr2,
          joinDimension ? joinDimension : 'regionid',
          timeDimension
        );
      }
      mergedArr = nestObjectsByParamId(nestingDimension, mergedCubeResponses, timeDimensionInRange, cubeQuery.periodFlag);
      responseObject.data = mergedArr;
    } else {
      let mergedCubeResponses = mergeArrays(responseArr1, responseArr2, joinDimension ? joinDimension : 'regionid');
      responseObject.data = mergedCubeResponses;
    }

    return responseObject;
  } catch (e) {
    /**/
  }
}

function nestObjectsByParamId(paramid, data, timeDimensionRange, periodFlag) {
  const nestedData = {};

  data.forEach((item) => {
    const officeId = item[paramid];
    const idperson = item.idperson;

    // Create a unique key based on both officeId and idperson
    const key = officeId + '-' + idperson;

    if (!nestedData[key]) {
      nestedData[key] = [];
    }

    nestedData[key].push(item);
  });
  if (periodFlag === 2) return fillMissingYears(Object.values(nestedData), timeDimensionRange);
  return fillMissingMonths(Object.values(nestedData), timeDimensionRange);
}

function fillMissingYears(data, years) {
  // Function to create a zero-value entry with dynamic keys
  const createZeroValueEntry = (template, year, dateKey) => {
    const zeroValueEntry = { ...template };

    // Set all keys containing "gci", "transaction", "volume", "terminationCount", "openingCount", "processedCount" to "0.00"
    Object.keys(zeroValueEntry).forEach((key) => {
      if (
        !key.includes(`${dateKey}.year`) &&
        (key.includes('gci') ||
          key.includes('transaction') ||
          key.includes('volume') ||
          key.includes('terminationAgentCount') ||
          key.includes('newAgentCount'))
      ) {
        zeroValueEntry[key] = '0.00';
      }
    });

    // Set the transaction date to the missing month
    zeroValueEntry[`${dateKey}.year`] = `${year}T00:00:00.000`;
    return zeroValueEntry;
  };

  return data.map((innerArray) => {
    const updatedArray = [...innerArray];
        // Identify the key for the date
        let dateKey = "transactiondate";
    
        if (updatedArray[0].date) {
          dateKey = "date";
        }
    
        if (updatedArray[0].statusdate) {
          dateKey = "statusdate";
        }

    const existingYears = new Set(
      updatedArray
        .filter((item) => item[`${dateKey}.year`] && typeof item[`${dateKey}.year`] === 'string')
        .map((item) => item[`${dateKey}.year`].split('-')[0])
    );

    years.forEach((year) => {
      if (!existingYears.has(year)) {
        // If the year is not present, use the last element to create a template
        const template = updatedArray.length > 0 ? updatedArray[updatedArray.length - 1] : {};
        const zeroValueEntry = createZeroValueEntry(template, year, dateKey);
        updatedArray.push(zeroValueEntry);
      }
    });

    // Sort the array by transactiondate.year
    updatedArray.sort((a, b) => a[`${dateKey}.year`].localeCompare(b[`${dateKey}.year`]));

    return updatedArray;
  });
}

function fillMissingMonths(data, months) {
  // Function to create a zero-value entry with dynamic keys
  const createZeroValueEntry = (template, month, dateKey) => {
    const zeroValueEntry = { ...template };

    // Set all keys containing "gci", "transaction", "volume", "terminationCount", "openingCount", "processedCount" to "0.00"
    Object.keys(zeroValueEntry).forEach((key) => {
      if (
        !key.includes(`${dateKey}.month`) &&
        (key.includes('gci') ||
          key.includes('transaction') ||
          key.includes('volume') ||
          key.includes('terminationCount') ||
          key.includes('openingCount') ||
          key.includes('processedCount') ||
          key.includes('newAgentCount') ||
          key.includes('terminationAgentCount'))
      ) {
        zeroValueEntry[key] = '0';
      }
    });

    // Set the transaction date to the missing month
    zeroValueEntry[`${dateKey}.month`] = `${month}T00:00:00.000`;
    return zeroValueEntry;
  };

  return data.map((innerArray) => {
    const updatedArray = [...innerArray];

    // Identify the key for the date
    let dateKey = "transactiondate";
    
    if (updatedArray[0].date) {
      dateKey = "date";
    }

    if (updatedArray[0].statusdate) {
      dateKey = "statusdate";
    }

    const existingMonths = new Set(
      updatedArray
        .filter((item) => item[`${dateKey}.month`] && typeof item[`${dateKey}.month`] === 'string')
        .map((item) => item[`${dateKey}.month`].split('T')[0])
    );

    months.forEach((month) => {
      if (!existingMonths.has(month)) {
        // If the month is not present, use the last element to create a template or a base template if none exists
        const template = updatedArray.length > 0 ? updatedArray[updatedArray.length - 1] : { [`${dateKey}.month`]: '' };
        const zeroValueEntry = createZeroValueEntry(template, month, dateKey);
        updatedArray.push(zeroValueEntry);
      }
    });

    // Sort the array by the dateKey
    updatedArray.sort((a, b) => a[`${dateKey}.month`].localeCompare(b[`${dateKey}.month`]));

    return updatedArray;
  });
}

function getYearsInRange(startDate, endDate) {
  const startYear = new Date(startDate).getFullYear();
  const endYear = new Date(endDate).getFullYear();
  const years = [];

  for (let year = startYear; year <= endYear; year++) {
    years.push(year.toString());
  }

  return years;
}

export function getMonthsInRange(startDateObj, endDateObj) {
  const start = new Date(startDateObj);
  const end = new Date(endDateObj);

  let currentDate = new Date(start.getFullYear(), start.getMonth(), 1); // Ensure day is set to 1
  const datesArray = [];

  while (currentDate <= end) {
    // Format the current date in 'YYYY-MM-DD' format
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-01`;
    datesArray.push(formattedDate);
    // Move to the next month
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return datesArray;
}

function isPeriodicalReport(reportTemplateID) {
  if (
    reportTemplateID === 16 ||
    reportTemplateID === 20 ||
    reportTemplateID === 11 ||
    reportTemplateID === 18 ||
    reportTemplateID === 24
  ) {
    return true;
  } else {
    return false;
  }
}
