import { BaseService } from '@/services/base.service';
import { PersonService } from '@/services/modules/person.service';
import { PersonEditorService } from '@/services/modules/personEditor.service';
import { CubeService } from '@/services/modules/cube.service';
import { AuthService } from '@/services/auth.service';
import { useRouter } from 'vue-router';
const router = useRouter();

interface authState {
  accessTokenExpDate: string;
  shouldAuthenticate: boolean;
  userInformation: any;
  userRemaxTitle: string;
  authenticated: authenticatedState;
  loadingAuthenticated: boolean;
  cubeToken: any;
  multipleAccesses: boolean;
  multipleAccessList: any[];
}

interface authenticatedState {
  ready: boolean;
  session: any;
  person: any;
  region: any;
  permissions: any;
  user: any;
}

const getDefaultAuthenticated = (): authenticatedState => ({
  ready: false,
  session: null,
  person: null,
  region: null,
  permissions: {},
  user: null,
});

const defaultState: authState = {
  accessTokenExpDate: '',
  shouldAuthenticate: false,
  userInformation: {},
  userRemaxTitle: '',
  authenticated: getDefaultAuthenticated(),
  loadingAuthenticated: false,
  cubeToken: null,
  multipleAccesses: false,
  multipleAccessList: [],
};

export default {
  namespaced: true,
  state: { ...defaultState },

  getters: {
    userInformation: (state) => state.userInformation,
    profilePic: (state) => state.profilePic,
    getCubeToken: (state) => state.cubeToken,
  },

  mutations: {
    setState(state, partialState) {
      const partial = JSON.parse(JSON.stringify(partialState));
      Object.keys(partial).forEach((key) => {
        state[key] = partial[key];
      });
    },
    SET_ATOKEN_EXP_DATE(state, expDate) {
      state.accessTokenExpDate = expDate;
    },
    SET_USER_INFORMATION(state, user) {
      state.userInformation = user;
    },
    setShouldAuthenticated(state, shouldAuthenticate) {
      state.shouldAuthenticate = shouldAuthenticate;
    },
    setAuthenticated(state, authenticated) {
      state.authenticated = { ...getDefaultAuthenticated(), ...authenticated };
    },

	setSession(state, session) {
		state.authenticated = {
			...state.authenticated,
			session: { ...state.authenticated.session, ...session, },
		};
	},

    setLoadingAuthenticated(state, loading) {
      state.loadingAuthenticated = loading;
    },

    setCubeToken(state, data) {
      state.cubeToken = data;
    },

    setMultipleAccesses(state, data) {
      state.multipleAccesses = data;
      console.log('multipleAccesses', data);
    },

    setMultipleAccessList(state, data) {
      state.multipleAccessList = data;
      console.log('multipleAccessList', data);
    },

    setUserRemaxTitle(state, data) {
      console.log('in store:', data);
      state.userRemaxTitle = data;

      console.log('state:', state.userRemaxTitle);
    },

    resetState(state: authState) {
      state = { ...defaultState };
    },
  },
  actions: {
    async updateUser({ commit }, payload) {
      const res = await PersonService.updateUser(payload);
      return res;
    },

    async loadAuthenticatedUserData({ commit, state }, isLogin = false) {
      let session = state.authenticated.session;
      let person = state.authenticated.person;
      let region = state.authenticated.region;
      try {
        const res = await CubeService.getCubeToken();
        if (res) {
          commit('setCubeToken', res.data.jsonWebToken);
        }
      } catch (e) {
        /**/
      }
      try {
        commit('setLoadingAuthenticated', !state.authenticated.ready);
        const sessionRes = await BaseService.get('permission/session', { sort: 'entryValue' });
        session = sessionRes?.data;

        commit('setShouldAuthenticated', false);

        if (session?.personID && session?.personID !== state.authenticated.session?.personID) {
          if (localStorage.getItem('person') == null || localStorage.getItem('person') == 'null') {
            const personRes = await BaseService.get(`person/${session?.personID}`);
            person = personRes?.data?.result || personRes?.data;

            if (person?.regionID) {
              const regionRes = await BaseService.get(`region/${person?.regionID}`);
              region = regionRes?.data?.result;
            }
          } else {
            person = JSON.parse(localStorage.getItem('person'));
          }
        }
        if (person != null || localStorage.getItem('person') != null || localStorage.getItem('person') != 'null') {
          if (localStorage.getItem('person') == null || localStorage.getItem('person') == 'null') {
            localStorage.setItem('person', JSON.stringify(person));
          }
          person = JSON.parse(localStorage.getItem('person'));
          localStorage.setItem('person', JSON.stringify(person));
        }
        if (region != null || localStorage.getItem('region') != null || localStorage.getItem('region') != 'null') {
          if (localStorage.getItem('region') == null || localStorage.getItem('region') == 'null') {
            localStorage.setItem('region', JSON.stringify(region));
          }
          region = JSON.parse(localStorage.getItem('region'));
          localStorage.setItem('region', JSON.stringify(region));
        } else {
          let regionRes = await BaseService.get(`region/${person?.regionID}`);
          region = regionRes?.data?.result;
        }
        const newState = {
          ready: true,
          session,
          person,
          region,
          user: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null,
        };
        commit('setAuthenticated', newState);
        commit('setLoadingAuthenticated', false);
        return newState;
      } catch (e) {
        // const json = JSON.stringify(e?.response?.data?.status || '{}');
        // if (json.match('4004')) commit('setShouldAuthenticated', true);
        AuthService.clearAuthData();
      }
    },

    async getProfilePicture({ commit }, hash) {
      if (hash) {
        const res = await PersonEditorService.getProfilePicture(hash);
        if (res.status === 200) {
          const API_URL = process.env.VUE_APP_API_URL;
          const imageSrc = API_URL + '/' + res.config.url;
          commit('setState', { profilePic: imageSrc });
          return imageSrc;
        }
      }
    },

    async getLoginIDByPersonID(_, personID) {
      if (personID) {
        const res = await BaseService.getLoginIDByPersonID(personID);
        if (res.status === 200) {
          return res.data.userName;
        }
      }
    },
  },
};
