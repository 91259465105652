import { levenshteinDistance } from '../helpers';
import * as reportTemplateList from '../templates';
import store from '@/store';
import { cloneDeep } from 'lodash';

export async function initializeReportTemplate(reportID) {
  try {
    let responseObject: any = {
      selectedColumns: [],
      nestedColumns: [],
      columnOptions: [],
      columnDefinitions: [],
    };
    let res;
    res = await store.dispatch('reports/selectReport', reportID);
    let templateResult;
    if (res.data.result) {
      templateResult = res.data.result;
    } else {
      templateResult = res.data;
    }

    if (
      templateResult.reportTemplateID !== 35 &&
      templateResult.reportTemplateID !== 49 &&
      templateResult.reportTemplateID !== 20
    ) {
      responseObject.columnDefinitions = reportTemplateList[templateResult.reportTemplateKey].columns;
      responseObject.nestedColumns = reportTemplateList[templateResult.reportTemplateKey].nestedColumns;
      const scopedReportFilter = JSON.parse(templateResult.reportFilter);
      if (scopedReportFilter?.show_columns?.length > 0) {
        let nonDifferences = 0;
        for (let i = 0; i < scopedReportFilter.show_columns.length; i++) {
          for (let j = 0; j < responseObject.columnDefinitions.length; j++) {
            if (scopedReportFilter.show_columns[i] === responseObject.columnDefinitions[j].name) {
              nonDifferences++;
            }
          }
        }
        if (nonDifferences === scopedReportFilter.show_columns.length) {
          responseObject.selectedColumns = scopedReportFilter.show_columns.map((col) => {
            return col;
          });
        } else {
          responseObject.selectedColumns = responseObject.columnDefinitions.map((col) => {
            return col.name;
          });
        }
      } else {
        responseObject.selectedColumns = responseObject.columnDefinitions.map((col) => {
          return col.name;
        });
      }
    }

    responseObject.settingsPanel = reportTemplateList[templateResult.reportTemplateKey].settingsPanel;
    if (templateResult.reportTemplateID === 35 || templateResult.reportTemplateID === 49) {
      responseObject.columnDefinitionsArray = reportTemplateList[templateResult.reportTemplateKey].ExcelSheets;
      responseObject.sheetTitles = reportTemplateList[templateResult.reportTemplateKey].ExcelSheetsTitles;
    }

    if (isPeriodicalReport(templateResult.reportTemplateID)) {
      responseObject.nestedColumns = cloneDeep(reportTemplateList[templateResult.reportTemplateKey].nestedColumns);
      responseObject.columnDefinitions = cloneDeep(reportTemplateList[templateResult.reportTemplateKey].columns);
      const yearPattern = /\b\d{4}\b/;
      let nestedColumns = reportTemplateList[templateResult.reportTemplateKey].nestedColumns.map((col) => col.name);
      let outerColumns = responseObject.columnDefinitions.map((col) => col.name).filter((item) => !yearPattern.test(item));
      const scopedReportFilter = JSON.parse(templateResult.reportFilter);
      if (scopedReportFilter.show_columns.length > 0) {
        nestedColumns = nestedColumns.filter((element) => scopedReportFilter.show_columns.includes(element));
        outerColumns = outerColumns.filter((element) => scopedReportFilter.show_columns.includes(element));
      }
      responseObject.selectedColumns = [...nestedColumns, ...outerColumns];
      responseObject.columnOptions = [
        ...responseObject.columnDefinitions.filter((item) => !yearPattern.test(item.name)),
        ...reportTemplateList[templateResult.reportTemplateKey].nestedColumns,
      ];
    } else {
      if (reportTemplateList[templateResult.reportTemplateKey].columns) {
      responseObject.columnOptions = [...reportTemplateList[templateResult.reportTemplateKey].columns];
      }
    }

    if (
      templateResult.reportTemplateID === 47 ||
      templateResult.reportTemplateID === 35 ||
      templateResult.reportTemplateID === 49
    ) {
      responseObject.templateData = {
        title: templateResult.title,
        templateTitle: templateResult.reportTemplateTitle,
        isPublic: templateResult.isPublic ? true : false,
        templateKey: templateResult.reportTemplateKey,
        templateID: templateResult.reportTemplateID,
        ID: templateResult.ID,
      };
      return responseObject;
    }

    const reportFilter = JSON.parse(templateResult.reportFilter);
    let validFromJsonKey;
    let validToJsonKey;
    if (
      templateResult.reportTemplateID === 11 ||
      templateResult.reportTemplateID === 13 ||
      templateResult.reportTemplateID === 20 ||
      templateResult.reportTemplateID === 18 ||
      templateResult.reportTemplateID === 24 ||
      templateResult.reportTemplateID === 16
    ) {
      validFromJsonKey = 'flt_date_start';
      validToJsonKey = 'flt_date_end';
    } else {
      validFromJsonKey = 'flt_monthdate_start';
      validToJsonKey = 'flt_monthdate_end';
    }
    responseObject.templateData = {
      regionID: reportFilter.flt_region || -2,
      title: templateResult.title,
      templateTitle: templateResult.reportTemplateTitle,
      isPublic: templateResult.isPublic ? true : false,
      status: reportFilter.flt_status || null,
      templateKey: templateResult.reportTemplateKey,
      templateID: templateResult.reportTemplateID,
      validFrom: reportFilter[validFromJsonKey],
      validTo: reportFilter[validToJsonKey],
      currency: reportFilter.flt_currency,
      singleDate: reportTemplateList[templateResult.reportTemplateKey].cubeParameters.singleDate ? true : false,
      isPeriodical: isPeriodicalReport(templateResult.reportTemplateID),
      periodFlag: parseInt(reportFilter['flt_period_switch']),
    };
    return responseObject;
  } catch (e) {
    /**/
  }
}

function isPeriodicalReport(reportTemplateID) {
  if (
    reportTemplateID === 16 ||
    reportTemplateID === 20 ||
    reportTemplateID === 11 ||
    reportTemplateID === 18 ||
    reportTemplateID === 24
  ) {
    return true;
  } else {
    return false;
  }
}
