
import { Options, Vue } from 'vue-class-component';
import PageHeader from '@/components/PageHeader.vue';
import {  Watch } from 'vue-property-decorator';
import { XIcon } from '@heroicons/vue/solid';


@Options({
  components: {
    PageHeader,
    XIcon
  },
})
export default class ErrorModal extends Vue {
  showModal = false;
  get errorList(){ return this.$store.getters['dom/getErrorList']; }

  @Watch('errorList', { deep: true })
  onDataChanged(v) {
    this.showModal = true
  }
}

