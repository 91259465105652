import { BaseService } from '../base.service';

const generateURLParams = (regionIDs, consultantID) => {
    let regionParams = ''
    let consultantParams = ''
    let combinedParams = ''
    
    // make sure there are region ids
    if (regionIDs && regionIDs.length > 0) regionParams = regionIDs.join(',')
    
    // make sure there are consultant ids
    if (consultantID) consultantParams = consultantID

    if (regionParams !== '') combinedParams += `regionIDs=${regionParams}&`
    // if (consultantParams !== '') combinedParams += `personIds=${consultantParams}` // commentted out for now to disable the dropdown from affecting the whole page data
    
    return combinedParams
}

export class FranchiseSalesDashboardService extends BaseService {
    static async getRestrictedData(regionIDs, consultantIDs) {
        const url = `v1/franchiseSalesDashboard/getRestrictedData/?${generateURLParams(regionIDs, consultantIDs)}`
        const res = await this.request().get(url)
        return { result: res.data?.data, message: res.data.message, headers: res.headers }
    }
    static async getUnrestrictedData(regionIDs, consultantIDs) {
        const url = `v1/franchiseSalesDashboard/getUnrestrictedData/?${generateURLParams(regionIDs, consultantIDs)}`
        const res = await this.request().get(url)
        return { result: res.data?.data, message: res.data.message, headers: res.headers }
    }

    static async getOffices() {
        const cachedData = localStorage.getItem('officeData');
        const cachedTimestamp = localStorage.getItem('officeDataTimestamp');
    
        // Check if cached data exists and if it's not expired
        if (cachedData && cachedTimestamp) {
            const currentTime = new Date().getTime();
            const expirationTime = parseInt(cachedTimestamp) + (60 * 60 * 1000); // 1 hour
    
            if (currentTime < expirationTime) {
                // Data is still valid, return cached data
                return JSON.parse(cachedData);
            }
        }
    
        // If cached data is not valid or does not exist, fetch fresh data
        const url = `/v1/franchiseSalesDashboard/getOfficeList`;
        const res = await this.request().get(url);
        const responseData = {
            result: res.data.data.result,
            message: res.data.message,
            headers: res.headers
        };
    
        // Cache the fresh data
        localStorage.setItem('officeData', JSON.stringify(responseData));
        localStorage.setItem('officeDataTimestamp', new Date().getTime().toString());
    
        return responseData;
    }
}