import TC from '../template_utilities/templateColumns';
import panel from './settingspanel.vue';

export const regional_office_counts_aggregated: any = {
  cubeParameters: {
    measures: [
      'OfficeStatusChangesCube.terminationCount',
      'OfficeStatusChangesCube.openingCount',
      'OfficeStatusChangesCube.processedCount',
    ],
    dimensions: ['OfficeStatusChangesCube.regionid', 'RegionCube.region'],
    mainCubeParameter: 'OfficeStatusChangesCube',
    timeParameter: 'OfficeStatusChangesCube.statusdate',
    containsCurrency: false
  },
  columns: [TC.region, TC.officeOpenedCount, TC.officeTerminationsCount],
  settingsPanel: panel,
};
