import moment from 'moment';
const isCheckboxAndRadioMultiple = (node) =>
  (node.props.type === 'checkbox' || node.props.type === 'radio') && node.props.options;
const isDatePicker = (n) => n.props.type === 'date';
export function addAsteriskPlugin(node) {
  node.on('created', () => {
    const schemaFn = node.props.definition.schema;
    node.props.definition.schema = (sectionsSchema = {}) => {
      const isRequired = node.props.parsedRules.some((rule) => rule.name === 'required');

      if (isRequired) {
        if (isCheckboxAndRadioMultiple(node)) {
          sectionsSchema.legend = {
            children: ['$label', '*'],
          };
        } else {
          sectionsSchema.label = {
            children: ['$label', '*'],
          };
        }
      }
      return schemaFn(sectionsSchema);
    };
  });
}
export function editDatePicker(node) {
  node.on('created', ({ payload }) => {
    const schemaFn = node.props.definition.schema;
    node.props.definition.schema = (sectionsSchema = {}) => {
      if (isDatePicker(node)) {
        node.on('input', ({ payload }) => {
        //   console.log('sdfsdfsdf');
        });
      }
      return schemaFn(sectionsSchema);
    };
  });
}
