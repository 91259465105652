import { promiseAll } from '@/utils/promiseAll';
import { BaseService } from '../../../services/base.service';
import { GlobalSearchService } from '../../../services/modules/globalSearch.service';

export async function init(store, params) {
  try {
    await promiseAll([search(store, {})]);
  } catch (e) {
    throw e;
  }
}

export async function search({ commit, state }, params) {
  const { queryParams } = { ...params } as any;
  commit('setState', { globalSearch: { ...state.globalSearch, loading: true } });
  const qParams = { ...state.globalSearch.queryParams, ...queryParams };
  const res = await GlobalSearchService.selectContactManagementLogList({
    ...qParams,
  });
  commit('setState', {
    globalSearch: {
      results: res,
      loading: false,
      query: qParams,
    },
  });
}
