import queryString from 'query-string';
import { BaseService } from '../base.service';

export class CompanyService extends BaseService {
  static get entity() {
    return 'company';
  }
 
  static async create(data = {}) {
    try {
      const response = await this.request({ auth: true }).post(`companies`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getAll({ currentPage, rowsPerPage, ...query }) {
    // currentPage = currentPage || 1;
    // rowsPerPage = rowsPerPage || 35;
    try {
      let response = await this.get(
        `companies?${queryString.stringify({
          //MAX-1129
          // limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
          sort: 'name',
          ...query,
        })}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getRegionsDropdown(query = {}) {
    try {
      let response = await this.get(
        `regions/dropdown?${queryString.stringify({
          sort: 'name',
        })}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getCompanyWithRegionID({ region_id, ...params }) {
    try {
      let response = await this.get(
        `companies?${queryString.stringify({
          'filter[regionID][eq]': region_id,
          sort: 'name',
          ...params,
        })}`
      );
      return response;
    } catch (error) {
      
      throw error;
    }
  }
  static async getById(id) {
    try {
      const response = await this.get(`company/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static history(id, params) {
    return this.get(`company/${id}/history`, {
      limit: '0,35',
      sort: '-changeDate',
      ...params,
    });
  }

  static notes(id, params) {
    return this.get(`company/${id}/note`, {
      limit: '0,35',
      sort: '-noteDate',
      ...params,
    });
  }

  static async updateNote(entity, id) {
    try {
      const response = await this.put(`note/${id}`, entity);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async deleteNote(id) {
    try {
      const response = await this.delete(`note/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
  static async insertNote(entity, id) {
    try {
      const response = await this.post(`company/${id}/note`, entity);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getOfficeList(id) {
    try {
      const response = await this.get(`company/${id}/offices`);
      
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async update(id, data = {}) {
    try {
      const response = await this.request({ auth: true }).put(`company/${id}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getSnapshotDropdown(office_id) {
    try {
      let response = await this.get(`company/${office_id}/snapshots`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getOfficeShareList(snapshot_id) {
    try {
      let response = await this.get(`companyShareholderSnapshot/${snapshot_id}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
  
  static async insertCompanySnapshot(company_id, payload) {
    try {
      let response = await this.post(`company/${company_id}/snapshots`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async updateCompanySnapshot(snapshotID, payload) {
    try {
      console.log(payload);
      let response = await this.put(`/companyShareholderSnapshot/${snapshotID}`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async remove(id) {
    try {
      const response = await this.request({ auth: true }).delete(`company/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static countries(params) {
    return this.get(`countries`, {
      sort: 'entryValue',
      ...params,
    });
  }

  static async get_countries_async(params) {

    try
    {
      return this.get(`countries`, {
        sort: 'entryValue',
        ...params,
      });
    }
    catch(error)
    {
       console.log("An error occured while fetching countries")
	   throw error;
    }
  
  }
}
