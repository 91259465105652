import { exchangeCurrency } from "./currency";

export function exchangeLocalMonthlyFees(fees: any[], { toCurrency, fromCurrency, rates }: {
	toCurrency: string;
	fromCurrency: string;
	rates: any[];
}) {
	return fees.map(f => {
		return {
			...f,
			minFeeLocalCurrency: !f.minFee ? null : exchangeCurrency({
				amount: f.minFee,
				rates,
				toCurrency,
				fromCurrency,
			}).amount,
			fixedFeeLocalCurrency: !f.fixedFee ? null : exchangeCurrency({
				amount: f.fixedFee,
				rates,
				toCurrency,
				fromCurrency,
			}).amount,
		};
	});
}

export function withExchangedCurrencies(application: any, { toCurrency, fromCurrency, rates }: {
	toCurrency: string;
	fromCurrency: string;
	rates: any[];
}) {
	const data = JSON.parse(JSON.stringify(application));

	return {
		...data,
		developmentSchedule: data.developmentSchedule.map(f => ({
			...f,
			minGrossRevenuesInLast12MonthsLocalCurrency: !f.minGrossRevenuesInLast12Months ? null : exchangeCurrency({
				amount: f.minGrossRevenuesInLast12Months,
				rates,
				toCurrency,
				fromCurrency,
			}).amount,
		})),
		franchiseAgreement: {
			...data.franchiseAgreement,
			franchiseRenewalFeeLocalCurrency: !data.franchiseAgreement.franchiseRenewalFee ? null : exchangeCurrency({
				amount: data.franchiseAgreement.franchiseRenewalFee,
				rates,
				toCurrency,
				fromCurrency,
			}).amount,
			paymentToREULocalCurrency: !data.franchiseAgreement.paymentToREU ? null : exchangeCurrency({
				amount: data.franchiseAgreement.paymentToREU,
				rates,
				toCurrency,
				fromCurrency,
			}).amount,
			prevYearRevenueLocalCurrency: !data.franchiseAgreement.prevYearRevenue ? null : exchangeCurrency({
				amount: data.franchiseAgreement.prevYearRevenue,
				rates,
				toCurrency,
				fromCurrency,
			}).amount,
			localFranchiseTransferFeeLocalCurrency: !data.franchiseAgreement.localFranchiseTransferFee ? null : exchangeCurrency({
				amount: data.franchiseAgreement.localFranchiseTransferFee,
				rates,
				toCurrency,
				fromCurrency,
			}).amount,
			localMembershipFeeLocalCurrency: !data.franchiseAgreement.localMembershipFee ? null : exchangeCurrency({
				amount: data.franchiseAgreement.localMembershipFee,
				rates,
				toCurrency,
				fromCurrency,
			}).amount,
			otherFeesLocalCurrency: !data.franchiseAgreement.otherFees ? null : exchangeCurrency({
				amount: data.franchiseAgreement.otherFees,
				rates,
				toCurrency,
				fromCurrency,
			}).amount,
			localMonthlyContinuingFees: exchangeLocalMonthlyFees(data.franchiseAgreement.localMonthlyContinuingFees, {
				toCurrency,
				fromCurrency,
				rates,
			}),
			localMonthlyPromotionAndDevelopmentFees: exchangeLocalMonthlyFees(data.franchiseAgreement.localMonthlyPromotionAndDevelopmentFees, {
				toCurrency,
				fromCurrency,
				rates,
			}),
		}
	};
}

export function exchangeFeeSegmentFees(segment: any, { toCurrency, fromCurrency, rates }: {
	toCurrency: string;
	fromCurrency: string;
	rates: any[];
}) {
	segment = JSON.parse(JSON.stringify(segment));

	return {
		...segment,
		franchiseFeeLocalCurrency: !segment.franchiseFee ? null : exchangeCurrency({
			amount: segment.franchiseFee,
			rates,
			toCurrency,
			fromCurrency,
		}).amount,
		renewalFeeLocalCurrency: !segment.renewalFee ? null : exchangeCurrency({
			amount: segment.renewalFee,
			rates,
			toCurrency,
			fromCurrency,
		}).amount,
		localMonthlyContinuingFees: exchangeLocalMonthlyFees(segment.localMonthlyContinuingFees, {
			toCurrency,
			fromCurrency,
			rates,
		}),
		localMonthlyPromotionAndDevelopmentFees: exchangeLocalMonthlyFees(segment.localMonthlyPromotionAndDevelopmentFees, {
			toCurrency,
			fromCurrency,
			rates,
		}),
	};
}

export function exchangeFeesMisc(data: any, { toCurrency, fromCurrency, rates, keys }: {
	toCurrency: string;
	fromCurrency: string;
	rates: any[];
	keys: string[];
}) {
	data = JSON.parse(JSON.stringify(data));

	keys.forEach(key => {
		if (data[key]) {
			data[key] = exchangeCurrency({
				amount: data[key],
				rates,
				toCurrency,
				fromCurrency,
			}).amount;
		}
	});

	return data;
}
