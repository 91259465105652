import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "border border-gray-300 min-w-full divide-y divide-gray-300" }
const _hoisted_2 = { class: "bg-gray-50" }
const _hoisted_3 = { class: "bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", _hoisted_2, [
      _createElementVNode("tr", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.columns, (col) => {
          return (_openBlock(), _createElementBlock("th", {
            key: col.name,
            scope: "col",
            class: "border-r border-gray-200 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
          }, _toDisplayString(col.name), 1 /* TEXT */))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    _createElementVNode("tbody", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.rows, (row, rowIndex) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: rowIndex,
          class: "text text-gray-900 text-center"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.columns, (column, columnIndex) => {
            return (_openBlock(), _createElementBlock("td", {
              key: `${rowIndex}${columnIndex}`,
              scope: "col",
              class: _normalizeClass(["border border-b border-gray-300 p-2", [columnIndex < ($props.columns.length - 1) ? 'border-r' : '']])
            }, _toDisplayString(column.cellRenderer ? 
                    column.cellRenderer({ row, rowIndex, cellIndex: columnIndex, cellValue: row[columnIndex] }) 
                    : 
                    ($props.cellRenderer ? $props.cellRenderer({ row, rowIndex, cellIndex: columnIndex, cellValue: row[columnIndex] }) : row[columnIndex])), 3 /* TEXT, CLASS */))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}