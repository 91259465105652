import service from '@/services/modules/country.service';

export default {
  namespaced: true,
  state: {
    currentCountry: {},
    currentCountryByKey: [],
    countryList: [],
    phoneCodes: [],
    languages: [],
    countryStates: {},
    loadingPhoneCodes: false,
    loadingCountryStates: false,
  },
  getters: {
    getCountryList: (state) => state.countryList,
  },
  mutations: {
    SET_CURRENT_COUNTRY(state, country) {
      state.currentCountry = country;
    },
    SET_COUNTRY_BY_KEY(state, data) {
      state.currentCountryByKey = data;
    },
    SET_COUNTRY_LIST(state, countryList) {
      state.countryList = countryList;
    },
    setCountryStates(state, data) {
      state.countryStates[data.country_id] = data.states || [];
    },
    setLoadingCountryStates(state, loading) {
      state.loadingCountryStates = loading;
    },
    setLoadingPhoneCodes(state, loading) {
      state.loadingPhoneCodes = loading;
    },
    setPhoneCodes(state, data) {
      state.phoneCodes = data;
    },
    setLanguages(state, data) {
      state.languages = data;
    },
  },
  actions: {
    getById({ commit }) {
      return service.getById(1).then((res) => commit('SET_CURRENT_COUNTRY', res.data.result));
    },
    getCountryByKey({ commit }, key) {
      try {
        return service.getCountryByKey(key).then((res) => commit('SET_COUNTRY_BY_KEY', res?.data?.result));
      } catch (error) {
        //console.log(error);
      }
      
    },
    getAll({ commit }, params = {}) {
      return service.getAllCountries(params).then((res) => commit('SET_COUNTRY_LIST', res.data.result));
    },
    async getCountryStates({ commit }, country_id) {
      commit('setLoadingCountryStates', true);
      try {
        const res = await service.getCountryStates(country_id);
        const data = res?.data?.result || [];
        commit('setLoadingCountryStates', false);
        commit('setCountryStates', {
          country_id,
          states: data,
        });
        return data;
      } catch (e) {
        commit('setLoadingCountryStates', false);
        throw e;
      }
    },
    async getPhoneCodes({ commit }) {
      commit('setLoadingPhoneCodes', true);
      try {
        service.getPhoneCodesList();
        const res = await service.getPhoneCodesList();
        const data = res?.data?.result || [];
        commit('setLoadingPhoneCodes', false);
        commit('setPhoneCodes', data);
        return data;
      } catch (e) {
        commit('setLoadingPhoneCodes', false);
        throw e;
      }
    },
    async getLanguages({ commit }) {
      try {
        service.getPhoneCodesList();
        const res = await service.getLanguages();
        const data = res?.data?.result || [];
        commit(
          'setLanguages',
          data.map((l) => ({ label: l.entryValue, value: l.entryKey }))
        );
        return res;
      } catch (e) {
        throw e;
      }
    },
  },
};
