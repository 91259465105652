import * as mockData from '@/store/mock-data';
import * as types from '@/types';

export default {
    namespaced: true,

    state: {
        loadingData: false,
        data: [],
        filters: [],
        loadingFilters: false,
        currentSystemPermission: null,
        loadingCurrentSystemPermission: false,
    },

    actions: {
        async getCurrentSystemPermission({ commit }) {
            commit('setCurrentSystemPermissionLoading', true);
            // make api call...
            const res = await new Promise(resolve => {
            setTimeout(() => {
                commit('setCurrentSystemPermissionData', mockData.currentSystemPermission);
                commit('setCurrentSystemPermissionLoading', false);
                resolve(mockData.currentSystemPermission);
                }, 1500);
            });
            return res;
        },

        async getSystemPermissions({ commit }) {
            commit('setSystemPermissionsLoading', true);
            // make api call...
            const res = await new Promise(resolve => {
            setTimeout(() => {
                commit('setSystemPermissionsData', mockData.systemPermissions);
                commit('setSystemPermissionsLoading', false);
                resolve(mockData.systemPermissions);
                }, 1500);
            });
            return res;
        },

        async getSystemPermissionsFilters({ commit }) {
            commit('setSystemPermissionsFiltersLoading', true);
            // make api call...
            const res = await new Promise(resolve => {
            setTimeout(() => {
                commit('setSystemPermissionsFiltersData', mockData.systemPermissionsFilters);
                commit('setSystemPermissionsFiltersLoading', false);
                resolve(mockData.systemPermissionsFilters);
            }, 1500);
            });
            return res;
        },
    },

    mutations: {
        setCurrentSystemPermissionLoading(state, loadingState) {
            state.loadingCurrentSystemPermission = loadingState;
        },
        
        setCurrentSystemPermissionData(state, data: types.CurrentSystemPermission) {
            state.currentSystemPermission = data;
        },

        setSystemPermissionsLoading(state, loadingState) {
            state.loadingData = loadingState;
        },

        setSystemPermissionsData(state, data: types.SystemPermission[] = []) {
            state.data = data;
        },

        setSystemPermissionsFiltersLoading(state, loadingState) {
            state.loadingFilters = loadingState;
        },

        setSystemPermissionsFiltersData(state, data: types.TableFilter[] = []) {
            state.filters = data;
        },
    },
};
