    export default {
      prevConstituentMemberIDRecurring: {
        data: 'prev_constituent_member_id',
        name: 'Prev Constituent Member ID',
      },
      newConstituentMemberIDRecurring: {
        data: 'constituent_member_id',
        name: 'New Constituent Member ID',
      },
      constituentMemberIDRecurringOutcome: {
        data: 'constituent_member_id_outcome',
        name: '',
      },
      prevPersonUniqueID: {
        data: 'prev_person_unique_id',
        name: 'Prev Person Unique ID',
      },
      newPersonUniqueID: {
        data: 'person_unique_id',
        name: 'New Person Unique ID',
      },
      personUniqueIDOutcome: {
        data: 'person_unique_id_outcome',
        name: '',
      },
      prevPersonLegacyID: {
        data: 'prev_legacy_member_id',
        name: 'Prev Legacy Member ID',
      },
      newPersonLegacyID: {
        data: 'legacy_member_id',
        name: 'New Legacy Member ID',
      },
      personLegacyIDOutcome: {
        data: 'legacy_member_id_outcome',
        name: '',
      },
      prevfirstName: {
        data: 'prev_firstname',
        name: 'Prev First Name',
      },
      newfirstName: {
        data: 'firstname',
        name: 'New First Name',
      },
      firstNameOutcome: {
        data: 'firstname_outcome',
        name: '',
      },
      prevLastName: {
        data: 'prev_lastname',
        name: 'Prev Last Name',
      },
      newLastName: {
        data: 'lastname',
        name: 'New Last Name',
      },
      lastNameOutcome: {
        data: 'lastname_outcome',
        name: '',
      },
      prevPrimaryTitle: {
        data: 'prev_primary_title',
        name: 'Prev Primary Title',
      },
      newPrimaryTitle: {
        data: 'primary_title',
        name: 'New Primary Title',
      },
      primaryTitleOutcome: {
        data: 'primary_title_outcome',
        name: '',
      },
      prevTitleValidTo: {
        data: 'prev_primary_title_valid_to',
        name: 'Prev Primary Title Valid To',
      },
      newTitleValidFrom: {
        data: 'primary_title_valid_from',
        name: 'New Primary Title Valid From',
      },
      titleValidFromOutcome: {
        data: 'primary_title_valid_from_outcome',
        name: '',
      },
      prevStatusMember: {
        data: 'prev_status_member',
        name: 'Prev Status Member',
      },
      newStatusMember: {
        data: 'status_member',
        name: 'Status Member',
      },
      statusMemberOutcome: {
        data: 'status_member_outcome',
        name: 'Status Member Outcome',
      },
      prevADA: {
        data: 'prev_annual_dues_anniversary',
        name: 'Prev Annual Dues Anniversary',
      },
      newADA: {
        data: 'annual_dues_anniversary',
        name: 'Annual Dues Anniversary',
      },
      ADAOutcome: {
        data: 'annual_dues_anniversary_outcome',
        name: '',
      },
      prevCommencementDate: {
        data: 'prev_commencement_date',
        name: 'Prev Commencement Date',
      },
      newCommencementDate: {
        data: 'commencement_date',
        name: 'Commencement Date',
      },
      commencementDateOutcome: {
        data: 'commencement_date_outcome',
        name: '',
      },
      prevTeamStatus: {
        data: 'prev_team_status',
        name: 'Prev Team Status',
      },
      newTeamStatus: {
        data: 'team_status',
        name: 'Team Status',
      },
      teamStatusOutcome: {
        data: 'team_status_outcome',
        name: '',
      },
      prevTeamStatusDate: {
        data: 'prev_team_status_date',
        name: 'Prev Team Status Date',
      },
      newTeamStatusDate: {
        data: 'team_status_date',
        name: 'Team Status Date',
      },
      teamStatusDateOutcome: {
        data: 'team_status_date_outcome',
        name: '',
      },
      prevTeamName: {
        data: 'prev_team_name',
        name: 'Prev Team Name',
      },
      newTeamName: {
        data: 'team_name',
        name: 'New Team Name',
      },
      teamNameOutcome: {
        data: 'team_name_outcome',
        name: '',
      },
      prevTeamID: {
        data: 'prev_team_id',
        name: 'Prev Team ID',
      },
      newTeamID: {
        data: 'team_id',
        name: 'New Team ID',
      },
      teamIDOutcome: {
        data: 'team_id_outcome',
        name: '',
      },
      prevOfficeUniqueID: {
        data: 'prev_office_unique_id',
        name: 'Prev Office Unique ID',
      },
      newOfficeUniqueID: {
        data: 'office_unique_id',
        name: 'New Office Unique ID',
      },
      officeUniqueIDOutcome: {
        data: 'office_unique_id_outcome',
        name: '',
      },
      prevOfficeConstituentID: {
        data: 'prev_office_constituent_id',
        name: 'Prev Office Constituent ID',
      },
      newOfficeConstituentID: {
        data: 'office_constituent_id',
        name: 'New Office Constituent ID',
      },
      officeConstituentIDOutcome: {
        data: 'office_constituent_id_outcome',
        name: '',
      },
      prevOfficeLegacy: {
        data: 'prev_office_legacy_id',
        name: 'Prev Office Legacy ID',
      },
      newOfficeLegacy: {
        data: 'office_legacy_id',
        name: 'New Office Legacy ID',
      },
      officeLegacyOutcome: {
        data: 'office_legacy_id_outcome',
        name: '',
      },
      prevOfficeName: {
        data: 'prev_office_name',
        name: 'Prev Office Name',
      },
      newOfficeName: {
        data: 'office_name',
        name: 'New Office Name',
      },
      officeNameOutcome: {
        data: 'office_name_outcome',
        name: '',
      },
      prevFranchiseSize: {
        data: 'prev_franchise_size',
        name: 'Prev Franchise Size',
      },
      newFranchiseSize: {
        data: 'franchise_size',
        name: 'New Franchise Size',
      },
      franchiseSizeOutcome: {
        data: 'franchise_size_outcome',
        name: '',
      },
      prevGender: {
        data: 'prev_gender',
        name: 'Prev Gender',
        transformHTML: (value) => {
          return value === 'M' ? 'Male' : 'Female';
        },
      },
      newGender: {
        data: 'gender',
        name: 'New Gender',
        transformHTML: (value) => {
          return value === 'M' ? 'Male' : 'Female';
        },
      },
      genderOutcome: {
        data: 'gender_outcome',
        name: '',
      },
      prevSocialSecurityNumber: {
        data: 'prev_social_security_number',
        name: 'Prev Social Security Number',
      },
      newSocialSecurityNumber: {
        data: 'social_security_number',
        name: 'New Social Security Number',
      },
      socialSecurityNumberOutcome: {
        data: 'social_security_number_outcome',
        name: '',
      },
      prevBirthDate: {
        data: 'prev_birthdate',
        name: 'Prev Birthdate',
      },
      newBirthDate: {
        data: 'birthdate',
        name: 'New Birthdate',
      },
      birthDateOutcome: {
        data: 'birthdate_outcome',
        name: '',
      },
      prevPrimaryEmail: {
        data: 'prev_primary_email',
        name: 'Prev Primary Email',
      },
      newPrimaryEmail: {
        data: 'primary_email',
        name: 'New Primary Email',
      },
      primaryEmailOutcome: {
        data: 'primary_email_outcome',
        name: '',
      },
      prevPrimaryPhone: {
        data: 'prev_primary_phone_number',
        name: 'Prev Primary Phone',
      },
      newPrimaryPhone: {
        data: 'primary_phone_number',
        name: 'New Phone Number',
      },
      primaryPhoneOutcome: {
        data: 'primary_phone_number_outcome',
        name: '',
      },
      prevRegion: {
        data: 'prev_region_name',
        name: 'Prev Region',
      },
      newRegion: {
        data: 'region_name',
        name: 'New Region',
      },
      regionOutcome: {
        data: 'region_name_outcome',
        name: '',
      },
      enteredBy: {
        data: 'entered_by',
        name: 'Entered By',
      },
      enteredOn: {
        data: 'entered_on',
        name: 'Entered On',
      },
    };