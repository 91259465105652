import { PersonEditorService } from '../../../services/modules/personEditor.service';
import { init, getPerson } from './init';
import { getDefaultState } from './constants';
import { promiseAll } from '@/utils/promiseAll';
import moment from 'moment';

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    setDefaultState(state) {
      const _defaultState = getDefaultState();
      Object.keys(_defaultState).forEach((key) => {
        state[key] = _defaultState[key];
      });
    },

    setState(state, partialState) {
      const partial = JSON.parse(JSON.stringify(partialState));
      Object.keys(partial).forEach((key) => {
        state[key] = partial[key];
      });
    },
  },
  getters: {
    profilePic: (state) => state.profilePic,
  },

  actions: {
    getPerson(store, person_id) {
      return getPerson(store, person_id || store.state.person?.ID);
    },

    init(store, person_id) {
      return init(store, person_id);
    },

    async getRegionsDropdown({ commit }) {
      try {
        commit('setState', { loadingRegionsDropdown: true });
        const res = await PersonEditorService.regionsDropdown();
        commit('setState', {
          loadingRegionsDropdown: false,
          regionsDropdown: res?.data?.result || [],
        });
        return res;
      } catch (e) {
        commit('setState', { loadingRegionsDropdown: false });
        throw e;
      }
    },

    async insertCheck({ commit }, params) {
      if (params.birthDate !== undefined) {
        params.birthDate = moment(params.birthDate).format('YYYY-MM-DD'); //convert to format stored on DB table
      }
      try {
        const res = await PersonEditorService.get(`persons/insertCheck/${JSON.stringify(params)}`, { sort: 'firstName' });
        commit('setState', { insertCheckResults: res?.data?.result });
        return res;
      } catch (e) {
        throw e;
      }
    },

    async save(store, { person }) {
      const { commit, state } = store;
      let personID = state.person?.ID;
      try {
        commit('setState', { saving: true });
        const [savePersonResponse] = await promiseAll(state.insertCheckResults?.length ? [] : [
			personID ? 
				(person ? PersonEditorService.updatePerson(personID, person) : null) 
				: 
				PersonEditorService.insertPerson(person),
		]);
        commit('setState', { saving: false });
        return { savePersonResponse };
      } catch (e) {
        commit('setState', { saving: false });
        throw e;
      }
    },

    async remaxTitles({ state, commit }, params) {
      try {
        const [remaxTitles, remaxTitlesActiveAndFuture, primaryTitleHistories] = await promiseAll([
          PersonEditorService.remaxTitles(state?.person?.ID, params),
          PersonEditorService.remaxTitles(state?.person?.ID, { only_active: undefined, active_and_future: 1, sort: '-valid_from', }),
          PersonEditorService.primaryTitleHistories(state?.person?.ID),
        ]);
        commit('setState', {
          remaxTitles: {
            ...state.remaxTitles,
            ...params,
            list: remaxTitles?.data?.result || [],
            activeAndFuture: remaxTitlesActiveAndFuture?.data?.result || [],			
          },
		  primaryTitleHistories: primaryTitleHistories?.data?.result || [],
        });
      } catch (e) {
        throw e;
      }
    },

    async getEditHistory({ commit, state }, _params: any = {}) {
      const { person_id, ...params } = _params;
      try {
        const rowsPerPage = params.rowsPerPage || state.editHistory.rowsPerPage || 35;
        let currentPage = params.currentPage || state.editHistory.currentPage || 1;

        const res = await PersonEditorService.editHistory(person_id || state?.person?.ID, {
          ...params,
          //MAX-1129
          //limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
        });

        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        currentPage = currentPage > totalPages ? totalPages : currentPage;

        commit('setState', {
          editHistory: {
            list: res?.data?.result || [],
            totalRows,
            rowsPerPage,
            currentPage,
            totalPages,
          },
        });

        return res;
      } catch (e) {
        throw e;
      }
    },

    async getNotes({ commit, state }, _params: any = {}) {
      const { person_id, ...params } = _params;
      try {
        const rowsPerPage = params.rowsPerPage || state.notes.rowsPerPage || 35;
        let currentPage = params.currentPage || state.notes.currentPage || 1;

        const res = await PersonEditorService.note(person_id || state?.person?.ID, {
          ...params,
          //MAX-1129
          //limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
        });

        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        currentPage = currentPage > totalPages ? totalPages : currentPage;

        commit('setState', {
          notes: {
            list: res?.data?.result || [],
            totalRows,
            rowsPerPage,
            currentPage,
            totalPages,
          },
        });

        return res;
      } catch (e) {
        throw e;
      }
    },

    async saveNote({ state }, { person_id, entity }) {
      try {
        let res = null;
        if (entity.ID) {
          res = await PersonEditorService.updateNote(entity.ID, entity);
        } else {
          res = await PersonEditorService.insertNote(person_id || state?.person?.ID, entity);
        }
        return res;
      } catch (e) {
        throw e;
      }
    },

    async deleteNote(_, note_id) {
      try {
        const res = await PersonEditorService.deleteNote(note_id);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async InsertProfilePicture(_, params) {
      return await PersonEditorService.insertProfilePicture(params);
    },
    async getProfilePicture({ commit }, hash) {
		if (hash) {
			const res = await PersonEditorService.getProfilePicture(hash);
      if (res.status === 200 ) {
        const API_URL = process.env.VUE_APP_API_URL;
        const imageSrc = API_URL + '/' + res.config.url;
        commit('setState', { profilePic: imageSrc });
        return imageSrc;
      }
		}
    },

    async getSinglePerson(_, person_id) {
      return await PersonEditorService.getSinglePerson(person_id);
    },
  },
};
