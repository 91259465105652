import { getDefaultState } from './constants';
import { init, groups, regionDropdown} from './init';

export default {
	namespaced: true,

	state: getDefaultState(),

	mutations: {
		setDefaultState(state) {
            const _defaultState = getDefaultState();
            Object.keys(_defaultState).forEach((key) => { state[key] = _defaultState[key]; });
        },

        setState(state, partialState) {
            const partial = JSON.parse(JSON.stringify(partialState));
            Object.keys(partial).forEach((key) => { state[key] = partial[key]; });
        },

		resetTeamsFilters(state) {
			const list = state.groups.list;
			state.groups = {
				...getDefaultState().groups,
				list,
			};
		},
	},

	actions: {
		init(store, params) { return init(store, params); },

		groups(store, params) { return groups(store, params); },

		regionDropdown(store, params) { return regionDropdown(store, params); },

		resetTeamsFilters(store) { 
			store.commit('resetTeamsFilters');
			return groups(store, getDefaultState().groups); 
		},
	},
};
  