import axios from 'axios';
import { AuthService } from '@/services/auth.service';
import store from '../store';
import { computed } from 'vue';
const API_URL = process.env.VUE_APP_API_URL;
const cubeToken = computed(() => store.state.auth.cubeToken);
//const cubeURL = 'https://cube.staging.remaxeu-datahub.bwscloud.tech/cubejs-api/v1/load';
//const cubeURL = 'https://api.staging.remaxeu-datahub.bwscloud.tech/v1/cubes/runQuery';
const cubeURL = `${API_URL}/v1/cubes/runQuery`
export class Http {
  constructor() {
    this.instance = axios.create({
      baseURL: API_URL,
    });
    return this.init();
  }
  init() {
    this.instance.interceptors.request.use(
      (request) => {
        if (request.url === 'cube') {
          request.baseURL = cubeURL;
          request.url = '';
          //request.headers.authorization = cubeToken.value.toString();
          //from now on cube queries go through the normal backend
          request.headers.authorization = AuthService.getBearer();
        } else {
          if (request?.data?.type) {
            request.responseType = 'blob';
          }
          request.headers.authorization = AuthService.getBearer();
        }
        // if (AuthService.isAccessTokenExpired()) {
        //   return AuthService.debounceRefreshTokens()
        //     .then(response => {
        //       AuthService.setBearer(response.data.accessToken)
        //       request.headers.authorization = AuthService.getBearer()
        //       return request
        //     }).catch(error => Promise.reject(error))
        // } else {
        return request;
        // }
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    return this.instance;
  }
}
