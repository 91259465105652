import cmApplicationTypes from "@/constants/cmApplicationTypes";

export const getDefaultState = () => ({
	loadingList: false, 

	regions: [],

	systemAccesses: [],

	access: null,

	regionCurrencyMap: {},
	
	list: {
		data: [],
		loading: false,
		filters: {},
	},

	shareholderTypes: [
		{ label: 'Active Partner', value: '1', },
		{ label: 'Silent Partner', value: '2', },
	],

	paymentCurrency: {
		iso: 'EUR',
		name: 'Euro',
	},

    approversList: [
		{ label: 'Kurt Lukas', value: '81130', },
		{ label: 'Adrian Burry', value: '81139', },
	],

    exchangeRates: [],

    applicationTypes: cmApplicationTypes,
});
