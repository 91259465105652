<template>
  <div class="ui-toast component" :class="classList" @click="remove">
    <font-awesome-icon class="mr-2" :icon="`fa-solid ${getIcon}`" />
    {{ item.message }}
    <div>x</div>
  </div>
</template>

<script>
export default {
  name: 'UiToast',
  props: {
    item: {
      type: Object,
    },
  },

  methods: {
    remove() {
      this.$emit('remove', this.item.id);
    },
  },

  computed: {
    classList() {
      return this.item.type;
    },
    getIcon() {
      switch (this.item.type) {
        case 'success':
          return 'fa-circle-check';
        case 'info':
          return 'fa-circle-info';
        case 'warning':
          return 'fa-warning';
        case 'error':
          return 'fa-circle-exclamation';
        default:
          return 'fa-circle-info';
      }
    },
  },

  mounted() {
    if (this.item.duration) {
      const timer = setTimeout(() => {
        clearTimeout(timer);
        this.remove();
      }, this.item.duration);
    }
  },
};
</script>

<style lang="scss">
.ui-toast.component {
  cursor: pointer;
  padding: 15px;
  background-color: #343a40;
  border-radius: 0.25em;
  box-shadow: 0 1px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
  margin: 15px 0;
  display: flex;
  align-items: center;
  div {
    margin-left: auto;
    font-size: 1.3rem;
  }
  &.default {
    border-left: 5px solid #fff;
    color: white;
  }
  &.success {
    border-left: 5px solid rgb(59, 245, 59);
    color: rgb(59, 245, 59);
  }
  &.info {
    border-left: 5px solid blue;
    color: blue;
  }
  &.warning {
    border-left: 5px solid orange;
    color: orange;
  }
  &.error {
    border-left: 5px solid red;
    color: red;
  }
}
</style>
