import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      $props.transparent ? '' : 'bg-white', 
      $props.noShadow ? '' : 'shadow', 
      'rounded-lg', 
      'divide-y', 
      'divide-gray-200',
    //   'rounded-tl-none'
    ])
  }, [
    (_ctx.$slots.CardHeader)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([
			`text-${$props.titleSize || '2xl'}`,
			$props.noPadding ? '' : 'px-4 py-5 sm:px-6'
		])
        }, [
          _renderSlot(_ctx.$slots, "CardHeader")
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    (_ctx.$slots.CardContent)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass([$props.noPadding ? '' : 'px-4 py-5 sm:p-6'])
        }, [
          _renderSlot(_ctx.$slots, "CardContent")
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    (_ctx.$slots.CardFooter)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass([$props.noPadding ? '' : 'px-4 py-4 sm:px-6'])
        }, [
          _renderSlot(_ctx.$slots, "CardFooter")
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}