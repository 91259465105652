import { BaseService } from '@/services/base.service';

export const defaultSystemAccess = {
  ID: null,
  createdAt: null,
  modifiedAt: null,
  personFullDisplayName: null,
  personID: null,
  personName: null,
  relationID: null,
  relationRegionID: null,
  remaxTitle: null,
  roleSystemAccessTierID: null,
  systemAccessPackages: [],
  systemAccessRoleID: null,
  systemAccessRoleName: null,
  systemAccessTierID: null,
  uniquePersonID: null,
};

export async function getSystemAccess() {
  try {
    const user = JSON.parse(localStorage.userInfo);
    const systemAccessesRes = await BaseService.get('systemAccesses');
    const systemAccessesItem = (systemAccessesRes?.data?.result || []).filter((a) => a.personID === user?.personID)[0];
    const systemAccessRes = await BaseService.get(`systemAccess/${systemAccessesItem?.ID}`);
    const systemAccess = {
      ...defaultSystemAccess,
      ...systemAccessRes?.data?.result,
    };
    return systemAccess;
  } catch (e) {
    throw e;
  }
}

export function getCMFeeApprovalPermissions(systemAccess, opts: any = {}) {
  let canWrite = false;
  let canRead = false;
  (systemAccess?.systemAccessPackages || []).forEach((a) => {
    switch (a.systemAccessPackageID) {
      case 107: //Fee approval queue
        canRead = canRead || ['W', 'R'].includes(a.accessLevel);
        canWrite = canWrite || a.accessLevel === 'W';
        break;
      default:
      // do nothing
    }
  });
  return { canRead, canWrite };
}
