export default {
  namespaced: true,

  state: {
	countriesList: [],
	phonecodesList: [],
	phonecodesWithoutISOList: [],
  },

  mutations: {
    setState(state, partialState) {
        const partial = JSON.parse(JSON.stringify(partialState));
        Object.keys(partial).forEach((key) => { state[key] = partial[key]; });
    },
  },
  
  actions: {

  },
};
