import { NotesService } from '../../services/modules/notes.service';

const defaultState = {
    notes: {},
    loadingNotes: false,
};

export default {
  namespaced: true,
  state: defaultState,

  mutations: {
     setNotes(state, data) {
        state.notes = { ...state.notes, ...data, };
     },

     setLoadingNotes(state, loading) {
        state.loadingNotes = loading;
     },

     resetState(state) {
        state = { ...state, ...defaultState };
     },
  },

  actions: {
    async getNotes({ commit }, { apiEntity, apiParams, apiQuery, }) {
        try {
            commit('setLoadingNotes', true);
            const res = await NotesService.getNotes(apiEntity, apiParams || [], apiQuery || {});
            commit('setNotes', { [apiEntity]: res.data.result || [] });
            commit('setLoadingNotes', false);
            return res;
        } catch(e) { commit('setLoadingNotes', false); throw e; }
    }
  },
};
