export const getDefaultState = () => ({
	region: null,

	currencyPayment: null,
	
	annualDuesReportGetOpenInfo: null,
	
	monthlyAnnualDuesOverview: {
		list: [],
		loading: false,
		totalRows: 0,
		currentPage: 1,
		totalPages: 1,
		rowsPerPage: 12,
		queryParams: {
			sort: '-annualDuesReportYear,-annualDuesReportMonth',
		},
	},
});
