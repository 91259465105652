import { CubeService } from '@/services/modules/cube.service';
let monthKeys = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [], 11: [] };
function reportMonth() {
  let curMonth = new Date().getMonth();
  let curDay = new Date().getDate();
  if (curDay > 15) {
    return curMonth < 10 ? '0' + curMonth : curMonth;
  } else {
    curMonth = curMonth - 1;
    return curMonth < 10 ? '0' + curMonth : curMonth;
  }
}
export default {
  namespaced: true,
  state: {
    onHoldOffices: null,
    onHoldOverdueOffices: null,
    renewalPendingOffices: null,
    upcomingRenewals12Months: null,
    officeOverviewTableData1: null,
    officeOverviewTableData1PreviousYear: null,
    officeOverviewTableData2AgentCount: null,
    officeOverviewTableData2: null,
    officeOverviewTableData4: null,
    officeOverviewTableData5: [],
    unfilteredData: null,
    trendAvgAgentPerOffice: '',
    gciContributionsByPrimaryTitlesPie: null,
    gciContributionsByPrimaryTitlesPiePreviousYear: null,
    gciContributionsByPrimaryTitlesPieOther: null,
    gciContributionsByPrimaryTitlesPieOtherPreviousYear: null,
    officeCubeData: null,//slowly deprecating this
    recrutingsCubeData: null,
    totalActiveOfficesCard: null,
    regionRank: null,
    officeStatuses: null,
    personGCIForOffice: null,
    allGCI: null,
    agentCounts: null,
    testQuery: null,
    gciContributionsByPrimaryTitle: null,
    gciByAgents: null,
    gciByBroker: null,
    gciByOther: null,
    gciByPrincipal: null,
    officeTenure: null,
    businessPlanSectionData: null,
    businessPlanKPIData: null,

    ytdGCIandTransactions: null,
    ytdOfficeStatuses: null,
    agentRecruitedAndTerminated: null,
    netGci: 0,
    netGciSelectedYear: 0,
    netGciPreviousYear: 0,
    netGciMonthly: null,
    netGciMonthlyPreviousYear: 0,
    netTransactions: 0,
    netTransactionsPreviousYear: 0,

    //office overview dashboard
    businessDeveloperIDs: [],
    businessDeveloperDatahubIDs: [],
    selectedBDOffices: [],
    officeGCITrend: null,
    officeTransactionTrend: null,
    officeAgentProductivity: null,
    officeYTDSalesData: null,
    officeYTDRentalData: null,
    officeYtdGCIPerAgentTenure: null,
    terminatedAgentCounts: null,
    monthlyTerminatedAgents: [],
    newTerminatedAgentsCount: [],
    kpiNewTerminatedAgentsCount: [],
    monthlyAgentCounts: [],
    monthlyRecruitedAgents: monthKeys,
    agentsCountPerMonth: monthKeys,
    agentsCountPerPeriod: [],
    terminatedAgentsCountPerMonth: monthKeys,
    newAndTerminatedAgentsCount: [],
    twelveMonthsNewAndTerminatedAgentsCount: [],
    terminatedAgentCountsByReason: [],
    allAgentsCount: [],
    ytdAgentRecruited: 0,
    ytdAgentTerminated: 0,
    fullWeekAgentRecruited: [],
    businessPlanMonthlyAgentCounts: [],
    bpKpis: [],
    bpkpiValues: {
      agents_rec_per_net_growth: 0,//REC/NET ratio from Business Plan
      agents_terminated: 0,//Terminated agents
      agents_recruited: 0, //Recruited agents
      agents_agent_count: 0, //Agents
      agents_net_growth: 0 //Agent Net growth
    },
    weekRegionRank: 0,
    bpAgentCountTotal: 0,
    previousYearAgentsCount: 0,
    fullWeekAgentRecruitedCount: 0,
    ytdAgentNetGrowthPerRegion: 0,
    ytdAgentRecruitedCountPerRegion: 0,
    ytdAgentTerminatedCountPerRegion: 0,
    ytdGCIOfficeTenure: [],
    agentsCountPerOffice: [],
    regionId: null,
    netMonth: new Date().getMonth(), //defaulted to current month
    selectedMonth: new Date().getMonth(), //defaulted to current month
    selectedYear: new Date().getFullYear(), //defaulted to current year
    startDate: new Date().getFullYear() + '-01-01', //defaulted to first day of the year
    // endDate: new Date(new Date().getFullYear(), 11, 31), //defaulted to last day of the year
    //default endDate to 16 of the current month based endDate of billing period 15 of every month
    endDate: `${new Date().getFullYear()}-${ reportMonth() }-15`,
    reportingPeriodForDatePicker: null,
    
    selectedYearCommissionAndTransactionChartData: null,
    selectedYearCommissionAndTransactionChartDataGCI: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    previousYearCommissionAndTransactionChartDataGCI: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    twoYearsAgoCommissionAndTransactionChartDataGCI: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],

    selectedYearCommissionAndTransactionChartDataTrans: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    previousYearCommissionAndTransactionChartDataTrans: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    twoYearsAgoCommissionAndTransactionChartDataTrans: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],

    selectedYearCommissionAndTransactionChartDataTransactions: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    previousYearCommissionAndTransactionChartDataTransactions: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    twoYearsAgoCommissionAndTransactionChartDataTransactions: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],

    agentRecruitedAndTerminatedMonthly: null,
    agentRecruitedAndTerminatedMonthlyCurrentYear: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    agentRecruitedAndTerminatedMonthlyPreviousYear: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    agentRecruitedAndTerminatedMonthlyTwoYearsAgo: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  
    agentTerminatedMonthlyCurrentYear: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    agentTerminatedMonthlyPreviousYear: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    agentTerminatedMonthlyTwoYearsAgo: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],

    ytdGCI: 0,
    ytdGCIPreviousYear: 0,
    ytdTransactions: 0,
    ytdTransactionsPreviousYear: 0,

    activeOfficesCard: 0,
    ytdOfficeCounts: 0,
    ytdOfficeCountsSelectedYear: 0,
    ytdOfficeCountSelectedYear: 0,
    ytdOfficeCountPreviousYear: 0,

    ytdProcessedOfficeCount: 0,
    ytdProcessedOfficeCountPreviousYear: 0,
    ytdOpeningsOfficeCount: 0,
    ytdOpeningsOfficeCountPreviousYear: 0,
    ytdTerminationsOfficeCount: 0,
    ytdTerminationsOfficeCountPreviousYear: 0,
    ytdRecruitedAgentCount: 0,
    ytdRecruitedAgentCountPreviousYear: 0,
    ytdTerminatedAgentCount: 0,
    ytdTerminatedAgentCountPreviousYear: 0,

    ytdAgentCountByRegion: 0,
    ytdAgentCountByRegionSelectedYear: 0,
    ytdAgentCountPreviousYear: 0,

    ytdOfficeStatusesSignedLastYear: 0,
    ytdSalesProcessedLastYear: 0,

    //Relates to red line target indicator
    performanceVBusinessPlanGCI: 0,
    performanceVsBusinessPlan: 0,
    performanceVsBusinessPlanGCI: 0,
    performanceVsBusinessPlanTransactions: 0,
    performanceVsBusinessPlanOffices: 0,
    performanceVsBusinessPlanAgents: 0,
    performanceVsBusinessPlanSalesProcessed: 0,
    performanceVsBusinessPlanRecruitedAgents: 0,
    performanceVsBusinessPlanOpenedOffices: 0,
    performanceVsBusinessPlanTerminatedAgents: 0,
    performanceVsBusinessPlanTerminatedOffices: 0,
    performanceVsBusinessPlanAgentNetGrowth: 0,

    //Relates to max value for the chart
    performanceVBusinessPlanGCITotal: null,
    performanceVsBusinessPlanTotal: null,
    performanceVsBusinessPlanGCITotal: null,
    performanceVsBusinessPlanTransactionsTotal: null,
    performanceVsBusinessPlanOfficesTotal: null,
    performanceVsBusinessPlanAgentsTotal: null,
    performanceVsBusinessPlanSalesProcessedTotal: null,
    performanceVsBusinessPlanRecruitedAgentsTotal: null,
    performanceVsBusinessPlanOpenedOfficesTotal: null,
    performanceVsBusinessPlanTerminatedAgentsTotal: null,
    performanceVsBusinessPlanTerminatedOfficesTotal: null,
    performanceVsBusinessPlanAgentNetGrowthTotal: null,

    performanceVsBusinessPlanGCITargetBar: null,
    performanceVsBusinessPlanGCITotalBar: null,
    performanceVsBusinessPlanTargetBar: null,
    performanceVsBusinessPlanTotalBar: null,
    performanceVsBusinessPlanOfficesTargetBar: null,
    performanceVsBusinessPlanOfficesTotalBar: null,
    performanceVsBusinessPlanAgentsTargetBar: null,
    performanceVsBusinessPlanAgentsTotalBar: null,
    performanceVsBusinessPlanSalesProcessedTargetBar: null,
    performanceVsBusinessPlanSalesProcessedTotalBar: null,
    performanceVsBusinessPlanAgentCountRecruitedTargetBar: null,
    performanceVsBusinessPlanAgentCountRecruitedTotalBar: null,
    performanceVsBusinessPlanOpenedOfficesTargetBar: null,
    performanceVsBusinessPlanOpenedOfficesTotalBar: null,
    performanceVsBusinessPlanTerminatedAgentsTargetBar: null,
    performanceVsBusinessPlanTerminatedAgentsTotalBar: null,
    performanceVsBusinessPlanTerminatedOfficesTargetBar: null,
    performanceVsBusinessPlanTerminatedOfficesTotalBar: null,
    performanceVsBusinessPlanAgentNetGrowthTargetBar: null,
    performanceVsBusinessPlanAgentNetGrowthTotalBar: null,
    officesPerfAgents: [],
    activeOfficesYTDGCI: [],
    officesYTDGCI: [],
    officeYTDGCI: [],
    billableTitlesAgents: [],
    officesPerfGCI: [],
    officeById: [],
    activeOffices: [],
    filteredOfficeMembers: [],
    newAgentCounters: [],
    unproductiveOfficesCardSelected: false,
    newTerminatedAgentCounters: [],
    prevousYearAgentsCount: [],
    officesPerfGCIPreviousYear: [],
    baseTableData: [],
    lastYearAgentsCountPerRegion: [],
  },
  getters: {
    getOfficeCubeData: (state: any) => state.officeCubeData,
    getRecruitingsCubeData: (state: any) => state.recrutingsCubeData,
    getOfficeStatuses: (state: any) => state.officeStatuses,
    getPersonGCIForOffice: (state: any) => state.personGCIForOffice,
    getGCYByBrokers: (state: any) => state.gciByBrokers,
    getAllGCI: (state: any) => state.allGCI,
    getAgentCounts: (state: any) => state.agentCounts,
    getMonthlyAgentCounts: (state: any) => state.monthlyAgentCounts,
    getTwelveMonthsNewAndTerminatedAgentsCount: (state: any) => state.twelveMonthsNewAndTerminatedAgentsCount,
    getNewAndTerminatedAgentsCount: (state: any) => state.newAndTerminatedAgentsCount,
    getYTDGCIOfficeTenure: (state: any) => state.ytdGCIOfficeTenure,
    getOfficeTenureAndGCIs: (state: any) => state.ytdGCIOfficeTenure,
    getOfficeById: (state: any) => state.officeById,
    getFilteredOfficeMembers: (state: any) => state.filteredOfficeMembers,
    getTerminatedAgentCounts: (state: any) => state.terminatedAgentCounts,
    getNewTerminatedAgentsCount: (state: any) => state.newTerminatedAgentsCount,
    getKPINewTerminatedAgentsCount: (state: any) => state.kpiNewTerminatedAgentsCount,
    getAllAgentsCount: (state: any) => state.allAgentsCount,
    getTerminatedAgentCountsByReason: (state: any) => state.terminatedAgentCountsByReason,
    getMonthlyTerminatedAgents: (state: any) => state.monthlyTerminatedAgents,
    getTestQuery: (state: any) => state.testQuery,
    getGCIContributionsByPrimaryTitle: (state: any) => state.gciContributionsByPrimaryTitle,
    getGCIByAgents: (state: any) => state.gciByAgents,
    getGCIByBroker: (state: any) => state.gciByBroker,
    getGCIByOther: (state: any) => state.gciByOther,
    getGCIByPrincipal: (state: any) => state.gciByPrincipal,
    getOfficeTenure: (state: any) => state.officeTenure,
    getYTDGCIandTransactions: (state: any) => state.ytdGCIandTransactions,
    getYTDOfficeStatuses: (state: any) => state.ytdOfficeStatuses,
    getAgentRecruitedAndTerminated: (state: any) => state.agentRecruitedAndTerminated,
    getSelectedYear: (state: any) => state.selectedYear,
    getOfficeGCITrend: (state: any) => state.officeGCITrend,
    getOfficeTransactionTrend: (state: any) => state.officeTransactionTrend,
    getOfficeAgentProductivity: (state: any) => state.officeAgentProductivity,
    getAgentRecruitedAndTerminatedMonthly: (state: any) => state.agentRecruitedAndTerminatedMonthly,
    getFullWeekAgentRecruited: (state: any) => state.fullWeekAgentRecruited,
    getBPlanMonthlyAgentCounts: (state: any) => state.businessPlanMonthlyAgentCounts,
    getBPKpis: (state: any) => state.bpKpis,
    getOfficesPerfAgents: (state: any) => state.officesWithZeroRec,
    getAgentsCountPerOffice: (state: any) => state.agentsCountPerOffice,
    getActiveOfficesYTDGCI: (state: any) => state.activeOfficesYTDGCI,
    getOfficesYTDGCI: (state: any) => state.officesYTDGCI,
    // getOfficeYTDGCI: (state: any) => state.officeYTDGCI,
    getOfficesPerfGCI: (state: any) => state.officesPerfGCI,
    getNewTerminatedAgentCount: (state: any) => state.newTerminatedAgentCounters,
  },
  mutations: {
    setUnproductiveOfficesCardSelected(state, data) {
      state.unproductiveOfficesCardSelected = data;
    },
    setNewTerminatedAgentCount(state, data) {
      state.newTerminatedAgentCounters = data;
    },
    setUnfilteredData(state, data) {
      state.unfilteredData = data;
    },
    setTrendAvgAgentPerOffice(state, data) {
      state.trendAvgAgentPerOffice = data;
    },
    setOnHoldOffices(state, data) {
      state.onHoldOffices = data;
    },
    setOnHoldOverdueOffices(state, data) {
      state.onHoldOverdueOffices = data;
    },
    setRenewalPendingOffices(state, data) {
      state.renewalPendingOffices = data;
    },
    setUpcomingRenewals12Months(state, data) {
      state.upcomingRenewals12Months = data;
    },
    setOfficeOverviewTableData1(state, data) {
      state.officeOverviewTableData1 = data;
    },
    setOfficeOverviewTableData1PreviousYear(state, data) {
      state.officeOverviewTableData1PreviousYear = data;
    },
    setOfficeOverviewTableData2AgentCount(state, data) {
      state.officeOverviewTableData2AgentCount = data;
    },
    setOfficeOverviewTableData2(state, data) {
      state.officeOverviewTableData2 = data;
    },
    setOfficeOverviewTableData4(state, data) {
      state.officeOverviewTableData4 = data;
    },
    setOfficeOverviewTableData5(state, data) {
      state.officeOverviewTableData5 = data;
    },
    setOfficeOverviewTableData6(state, data) {
      state.officeOverviewTableData6 = data;
    },
    setGciContributionsByPrimaryTitlesPie(state, data) {
      state.getYTDOfficeStatuses = data;
    },
    setGciContributionsByPrimaryTitlesPiePreviousYear(state, data) {
      state.gciContributionsByPrimaryTitlesPiePreviousYear = data;
    },
    setGciContributionsByPrimaryTitlesPieOther(state, data) {
      state.gciContributionsByPrimaryTitlesPieOther = data;
    },
    setGciContributionsByPrimaryTitlesPieOtherPreviousYear(state, data) {
    state.gciContributionsByPrimaryTitlesPieOtherPreviousYear = data;
  },

    setOfficeCubeData(state, data) {
      state.officeCubeData = data;
    },
    setPersonGCIForOffice(state, data) {
      state.personGCIForOffice = data;
    },

    setOfficeStatuses(state, data) {
      state.officeStatuses = data;
    },
    setRecruitingsCubeData(state, data) {
      state.recrutingsCubeData = data;
    },
    setAllGCI(state, data) {
      state.allGCI = data;
    },
    setAgentCounts(state, data) {
      state.agentCounts = data;
    },
    setMonthlyAgentCounts(state, data) {
      state.monthlyAgentCounts = data;
    },
    setTerminatedAgentCounts(state, data) {
      state.terminatedAgentCounts = data;
    },
    setYTDGCIOfficeTenure(state, data) {
      state.ytdGCIOfficeTenure = data;
    },
    setOfficeById(state, data) {
      state.officeById = data;
    },
    setActiveOffices(state, data) {
      state.activeOffices = data;
    },
    setLastYearAgentsCountPerRegion(state, data) {
      state.lastYearAgentsCountPerRegion = data;
    },
    setFilteredOfficeMembers(state, data) {
      state.filteredOfficeMembers = data;
    },
    setNewTerminatedAgentsCount(state, data) {
      state.newTerminatedAgentsCount = data;
    },
    setKPINewTerminatedAgentsCount(state, data) {
      state.kpiNewTerminatedAgentsCount = data;
    },
    setAllAgentsCount(state, data) {
      state.allAgentsCount = data;
    },
    setTwelveMonthsNewAndTerminatedAgentsCount(state, data) {
      state.twelveMonthsNewAndTerminatedAgentsCount = data;
    },
    setNewAndTerminatedAgentsCount(state, data) {
      state.newAndTerminatedAgentsCount = data;
    },
    setTerminatedAgentCountsByReason(state, data) {
      state.terminatedAgentCountsByReason = data;
    },
    setMonthlyTerminatedAgents(state, data) {
      state.monthlyTerminatedAgents = data;
    },
    setTestQuery(state, data) {
      state.testQuery = data;
    },
    setGCIContributionsByPrimaryTitle(state, data) {
      state.gciContributionsByPrimaryTitle = data;
    },
    setGCIByAgents(state, data) {
      state.gciByAgents = data;
    },
    setGCIByBroker(state, data) {
      state.gciByBroker = data;
    },
    setGCIByOther(state, data) {
      state.gciByOther = data;
    },
    setGCIByPrincipal(state, data) {
      state.gciByPrincipal = data;
    },
    setOfficeTenure(state, data) {
      state.officeTenure = data;
    },

    setYTDGCIandTransactions(state, data) {
      state.ytdGCIandTransactions = data;
    },
    setYTDGCI(state, data) {
      state.ytdGCI = data;
    },
    setYTDGCIPreviousYear(state, data) {
      state.ytdGCIPreviousYear = data;
    },
    setYTDTransactions(state, data) {
      state.ytdTransactions = data;
    },
    setYTDTransactionsPreviousYear(state, data) {
      state.ytdTransactionsPreviousYear = data;
    },

    setYTDOfficeCount(state, data) {
      state.ytdOfficeCount = data;
    },
    setYTDOfficeCountPreviousYear(state, data) {
      state.ytdOfficeCountPreviousYear = data;
    },
    setYTDProcessedOfficeCount(state, data) {
      state.ytdProcessedOfficeCount = data;
    },
    setYTDProcessedOfficeCountPreviousYear(state, data) {
      state.ytdProcessedOfficeCountPreviousYear = data;
    },
    setYTDOpeningsOfficeCount(state, data) {
      state.ytdOpeningsOfficeCount = data;
    },
    setYTDOpeningsOfficeCountPreviousYear(state, data) {
      state.ytdOpeningsOfficeCountPreviousYear = data;
    },
    setYTDTerminationsOfficeCount(state, data) {
      state.ytdTerminationsOfficeCount = data;
    },
    setYTDTerminationsOfficeCountPreviousYear(state, data) {
      state.ytdTerminationsOfficeCountPreviousYear = data;
    },

    setYTDOfficeStatuses(state, data) {
      state.ytdOfficeStatuses = data;
    },

    setYTDOfficeStatusesSignedLastYear(state, data) {
      state.ytdOfficeStatusesSignedLastYear = data;
    },

    setYTDSalesProcessedLastYear(state, data){
      state.ytdSalesProcessedLastYear = data;
    },

    setYTDOfficeStatusesPreviousYear(state, data) {
      state.ytdOfficeStatusesPreviousYear = data;
    },
    setActiveOfficesCard(state, data) {
      state.activeOfficesCard = data;
    },
    setYTDOfficeCounts(state, data) {
      state.ytdOfficeCounts = data;
    },
    setYTDOfficeCountSelectedYear(state, data) {
      state.ytdOfficeCountsSelectedYear = data;
    },
    setYTDOfficeCountsPreviousYear(state, data) {
      state.ytdOfficeCountsPreviousYear = data;
    },

    setYTDAgentCountByRegion(state, data) {
      state.ytdAgentCountByRegion = data;
    },
    setYTDAgentCountByRegionSelectedYear(state, data) {
      state.ytdAgentCountByRegionSelectedYear = data;
    },
    setYTDAgentCountByRegionPreviousYear(state, data) {
      state.ytdAgentCountPreviousYear = data;
    },

    setAgentRecruitedAndTerminated(state, data) {
      state.agentRecruitedAndTerminated = data;
    },
    setYTDRecruitedAgentCount(state, data) {
      state.ytdRecruitedAgentCount = data;
    },
    setYTDRecruitedAgentCountPreviousYear(state, data) {
      state.ytdRecruitedAgentCountPreviousYear = data;
    },
    setYTDTerminatedAgentCount(state, data) {
      state.ytdTerminatedAgentCount = data;
    },
    setFullWeekAgentRecruited(state, data) {
      state.fullWeekAgentRecruited = data;
    },
    setBPlanMonthlyAgentCounts(state, data) {
      state.businessPlanMonthlyAgentCounts = data;
    },
    setBPKpis(state, data) {
      state.bpKpis = data;
    },
    setBillableTitlesAgents(state, data) {
      state.billableTitlesAgents = data;
    },
    setOfficesPerfAgents(state, data) {
      state.officesPerfAgents = data;
    },
    setAgentsCountPerOffice(state, data) {
      state.agentsCountPerOffice = data;
    },
    setActiveOfficesYTDGCI(state, data) {
      state.activeOfficesYTDGCI = data;
    },
    setOfficesYTDGCI(state, data) {
      state.officesYTDGCI = data;
    },
    setOfficeYTDGCI(state, data) {
      state.officeYTDGCI = data;
    },
    setOfficesPerfGCI(state, data) {
      state.officesPerfGCI = data;
    },
    setOfficesPerfGCIPreviousYear(state, data) {
      state.officesPerfGCIPreviousYear = data;
    },
    setYTDTerminatedAgentCountPreviousYear(state, data) {
      state.ytdTerminatedAgentCountPreviousYear = data;
    },
    //cards
    setNETGci(state, data) {
      state.netGci = data;
    },
    setNetGciPreviousYear(state, data) {
      state.netGciPreviousYear = data;
    },
    setNetTransactions(state, data) {
      state.netTransactions = data;
    },
    setNetTransactionsPreviousYear(state, data) {
      state.netTransactionsPreviousYear = data;
    },
    //charts
    setNetGciMonthly(state, data) {
      state.netGciMonthly = data;
    },
    setNetGciSelectedYear(state, data) {
      state.netGciSelectedYear = data;
    },
    setNetGciMonthlyPreviousYear(state, data) {
      state.netGciMonthlyPreviousYear = data;
    },

    setSelectedYear(state, data) {
      state.selectedYear = data;
    },
    setBusinessPlanSectionData(state, data) {
      state.businessPlanSectionData = data;
    },
    setBusinessPlanKPIData(state, data) {
      state.businessPlanKPIData = data;
    },
    setOfficeGCITrend(state, data) {
      state.officeGCITrend = data;
    },
    setOfficeTransactionTrend(state, data) {
      state.officeTransactionTrend = data;
    },
    setOfficeAgentProductivity(state, data) {
      state.officeAgentProductivity = data;
    },
    setSelectedYearCommissionAndTransactionChartData(state, data) {
      state.selectedYearCommissionAndTransactionChartData = data;
    },
    setSelectedYearCommissionAndTransactionChartDataGCI(state, data) {
      state.selectedYearCommissionAndTransactionChartDataGCI = data;
    },
    setPreviousYearCommissionAndTransactionChartDataGCI(state, data) {
      state.previousYearCommissionAndTransactionChartDataGCI = data;
    },
    setTwoYearsAgoCommissionAndTransactionChartDataGCI(state, data) {
      state.twoYearsAgoCommissionAndTransactionChartDataGCI = data;
    },

    setSelectedYearCommissionAndTransactionChartDataTransactions(state, data) {
      state.selectedYearCommissionAndTransactionChartDataTransactions = data;
    },
    setPreviousYearCommissionAndTransactionChartDataTransactions(state, data) {
      state.previousYearCommissionAndTransactionChartDataTransactions = data;
    },
    setTwoYearsAgoCommissionAndTransactionChartDataTransactions(state, data) {
      state.twoYearsAgoCommissionAndTransactionChartDataTransactions = data;
    },

    setAgentRecruitedAndTerminatedMonthly(state, data) {
      state.agentRecruitedAndTerminatedMonthly = data;
    },

    setAgentRecruitedMonthlyCurrentYear(state, data) {
      state.agentRecruitedAndTerminatedMonthlyCurrentYear = data;
    },
    setAgentRecruitedMonthlyPreviousYear(state, data) {
      state.agentRecruitedAndTerminatedMonthlyPreviousYear = data;
    },
    setAgentRecruitedMonthlyTwoYearsAgo(state, data) {
      state.agentRecruitedAndTerminatedMonthlyTwoYearsAgo = data;
    },

    setAgentTerminatedMonthlyCurrentYear(state, data) {
      state.agentTerminatedMonthlyCurrentYear = data;
    },
    setAgentTerminatedMonthlyPreviousYear(state, data) {
      state.agentTerminatedMonthlyPreviousYear = data;
    },
    setAgentTerminatedMonthlyTwoYearsAgo(state, data) {
      state.agentTerminatedMonthlyTwoYearsAgo = data;
    },

    setSummaryYear(state, data) {
      state.selectedYear = data;
    },
    setNetMonth(state, data) {
      state.netMonth = data;
    },
    setSummaryMonth(state, data) {
      state.selectedMonth = data;
    },

    setPerformanceVBusinessPlanGCI(state, data) {
      state.performanceVBusinessPlanGCI = data;
    },

    setPerformanceVsBusinessPlan(state, data) {
      state.performanceVsBusinessPlan = data;
    },

    setPerformanceVsBusinessPlanGCITargetBar(state, data) {
      state.performanceVsBusinessPlanGCITargetBar = data;
    },
    setPerformanceVsBusinessPlanGCITotalBar(state, data) {
      state.performanceVsBusinessPlanGCITotalBar = data;
    },

    setPerformanceVsBusinessPlanTransactionsTargetBar(state, data) {
      state.performanceVsBusinessPlanTransactionsTargetBar = data;
    },
    setPerformanceVsBusinessPlanTransactionsTotalBar(state, data) {
      state.performanceVsBusinessPlanTransactionsTotalBar = data;
    },

    setPerformanceVsBusinessPlanOfficesTargetBar(state, data) {
      state.performanceVsBusinessPlanOfficesTargetBar = data;
    },
    setPerformanceVsBusinessPlanOfficesTotalBar(state, data) {
      state.performanceVsBusinessPlanOfficesTotalBar = data;
    },

    setPerformanceVsBusinessPlanAgentsTargetBar(state, data) {
      state.performanceVsBusinessPlanAgentsTargetBar = data;
    },
    setPerformanceVsBusinessPlanAgentsTotalBar(state, data) {
      state.performanceVsBusinessPlanAgentsTotalBar = data;
    },

    setPerformanceVsBusinessPlanSalesProcessedTargetBar(state, data) {
      state.performanceVsBusinessPlanSalesProcessedTargetBar = data;
    },
    setPerformanceVsBusinessPlanSalesProcessedTotalBar(state, data) {
      state.performanceVsBusinessPlanSalesProcessedTotalBar = data;
    },

    setPerformanceVsBusinessPlanAgentCountRecruitedTargetBar(state, data) {
      state.performanceVsBusinessPlanAgentCountRecruitedTargetBar = data;
    },
    setPerformanceVsBusinessPlanAgentCountTotalBar(state, data) {
      state.performanceVsBusinessPlanAgentCountRecruitedTotalBar = data;
    },

    setPerformanceVsBusinessPlanOpenedOfficesTargetBar(state, data) {
      state.performanceVsBusinessPlanOpenedOfficesTargetBar = data;
    },
    setPerformanceVsBusinessPlanOpenedOfficesTotalBar(state, data) {
      state.performanceVsBusinessPlanOpenedOfficesTotalBar = data;
    },

    setPerformanceVsBusinessPlanTerminatedAgentsTargetBar(state, data) {
      state.performanceVsBusinessPlanTerminatedAgentsTargetBar = data;
    },
    setPerformanceVsBusinessPlanTerminatedAgentsTotalBar(state, data) {
      state.performanceVsBusinessPlanTerminatedAgentsTotalBar = data;
    },

    setPerformanceVsBusinessPlanTerminatedOfficesTargetBar(state, data) {
      state.performanceVsBusinessPlanTerminatedOfficesTargetBar = data;
    },
    setPerformanceVsBusinessPlanTerminatedOfficesTotalBar(state, data) {
      state.performanceVsBusinessPlanTerminatedOfficesTotalBar = data;
    },

    setPerformanceVsBusinessPlanAgentNetGrowthTargetBar(state, data) {
      state.performanceVsBusinessPlanAgentNetGrowthTargetBar = data;
    },
    setPerformanceVsBusinessPlanAgentNetGrowthTotalBar(state, data) {
      state.performanceVsBusinessPlanAgentNetGrowthTotalBar = data;
    },

    setDatePickerReportingPeriod(state, data) {
      state.reportingPeriodForDatePicker = data;
    },

    //BD filter in Office Overview
    setBDListForRegion(state, data) {
      state.businessDeveloperIDs = data;
    },
    setBDListWithDatahubOfficeID(state, data) {
      state.businessDeveloperDatahubIDs = data;
    },
    setBDFirstAndLastName(state, data) {
      state.businessDeveloperFirstAndLastName = data;
    },
    setBDListForSelectedPerson(state, data) {
      state.selectedBDOffices = data;
    },

    RESET_STATE(state) {
      // Reset each module's state to its initial values
      Object.assign(state, getDefaultState());
    },

  },
  actions: {
    async getOnHoldOffices({ commit, state }, params) {
      try {
        const res = await CubeService.getOnHoldOffices(params);
        commit('setOnHoldOffices', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getOnHoldOverdueOffices({ commit, state }, params) {
      try {
        const res = await CubeService.getOnHoldOverdueOffices(params);
        commit('setOnHoldOverdueOffices', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getRenewalPendingCount({ commit, state }, params) {
      try {
        const res = await CubeService.getRenewalPendingCount(params);
        if(res.data.data == undefined) {
          commit('setRenewalPendingOffices', res.data);
        } else {
        commit('setRenewalPendingOffices', res.data.data);
        }
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getUpcomingRenewals12Months({ commit, state }, params) {
      try {
        const res = await CubeService.getUpcomingRenewals12Months(params);
        if(res.data.data == undefined) {
          commit('setUpcomingRenewals12Months', res.data);
        } else {
          commit('setUpcomingRenewals12Months', res.data.data);
        }       
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getOfficeOverviewTableData1({ commit, state }, params) {
      try {
        const res = await CubeService.getOfficeOverviewTableData1(params);
        commit('setOfficeOverviewTableData1', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getOfficeOverviewTableData1PreviousYear({ commit, state }, params) {
      try {
        const res = await CubeService.getOfficeOverviewTableData1PreviousYear(params);
        commit('setOfficeOverviewTableData1PreviousYear', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getOfficeOverviewTableData2AgentCount({ commit, state }, params) {
      try {
        const res = await CubeService.getOfficeOverviewTableData2AgentCount(params);
        commit('setOfficeOverviewTableData2AgentCount', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getOfficeOverviewTableData2({ commit, state }, params) {
      try {
        const res = await CubeService.getOfficeOverviewTableData2(params);
        commit('setOfficeOverviewTableData2', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getOfficeOverviewTableData4({ commit, state }, params) {
      try {
        const res = await CubeService.getOfficeOverviewTableData4(params);
        commit('setOfficeOverviewTableData4', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getOfficeOverviewTableData5({ commit, state }, params) {
      try {
        const res = await CubeService.getOfficeOverviewTableData5(params);
        commit('setOfficeOverviewTableData5', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getOfficeOverviewTableData6({ commit, state }, params) {
      try {
        const res = await CubeService.getOfficeOverviewTableData6(params);
        commit('setOfficeOverviewTableData6', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getGciContributionsByPrimaryTitlesPie({ commit, state }, params) {
      try {
        const currentYear = await CubeService.getGciContributionsByPrimaryTitlesPie(params);
        const lastYear = await CubeService.getGciContributionsByPrimaryTitlesPiePreviousYear(params);
        const currentYearOther = await CubeService.getGciContributionsByPrimaryTitlesPieOther(params);
        const lastYearOther = await CubeService.getGciContributionsByPrimaryTitlesPieOtherPreviousYear(params);
        commit('setGciContributionsByPrimaryTitlesPie', currentYear.data.data);
        commit('setGciContributionsByPrimaryTitlesPiePreviousYear',lastYear.data.data);
        commit('setGciContributionsByPrimaryTitlesPieOther', currentYearOther.data.data);
        commit('setGciContributionsByPrimaryTitlesPieOtherPreviousYear', lastYearOther.data.data);
      } catch (e) {
        throw e;
      }
    },

    async getOfficeCubeData({ commit, state }, params) {
      try {
        const res = await CubeService.getOfficeCubeData(params);
        commit('setOfficeCubeData', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },
    async getPersonGCIForOffice({ commit, state }, params) {
      try {
        const res = await CubeService.getPersonGCIForOffice();
        commit('setPersonGCIForOffice', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getOfficeStatuses({ commit, state }, params) {
      try {
        const res = await CubeService.getOfficeStatuses(params);
        commit('setOfficeStatuses', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getRecruitingsCubeData({ commit, state }, params) {
      try {
        const res = await CubeService.getRecruitingsCubeData();

        commit('setRecruitingsCubeData', res.data.data);

        return res;
      } catch (e) {
        throw e;
      }
    },

    async getRegionRank({ commit, state }, params) {
      try {
        const res = await CubeService.getRegionRank();
        commit('regionRank', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getAgentCounts({ commit, state }, params) {
      try {
        const res = await CubeService.getAgentCounts();
        commit('setAgentCounts', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getMonthlyAgentCounts({ commit, state }, params) {
      try {
        const res = await CubeService.getMonthlyAgentCounts(params);
        commit('setMonthlyAgentCounts', res.data ?  res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getTerminatedAgentCounts({ commit, state }, params: any) {
      try {
        const res = await CubeService.getTerminatedAgentCounts(params);
        commit('setTerminatedAgentCounts', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getYTDGCIOfficeTenure({ commit, state }, params: any) {
      try {
        const res = await CubeService.getYTDGCIOfficeTenure(params);
        commit('setYTDGCIOfficeTenure', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getRegionalRanking({ context }, params: any) {
      try {
        const res = await CubeService.getRegionalRanking(params);
        return res.data.data;
      } catch (e) {
        throw e;
      }
    },

    async getOfficeById({ commit, state }, officeID: any) {
      try {
        const res = await CubeService.getOfficeById(officeID);
        commit('setOfficeById', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getActiveOffices({ commit, state }, params) {
      try {
        const res = await CubeService.getActiveOffices(params);
        commit('setActiveOffices', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getLastYearAgentsCountPerRegion({ commit, state }, params) {
      try {
        const res = await CubeService.getLastYearAgentsCountPerRegion(params);
        commit('setLastYearAgentsCountPerRegion', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getFilteredOfficeMembers({ commit, state }, filters: any) {
      try {
        const res = await CubeService.getFilteredOfficeMembers(filters);
        commit('setFilteredOfficeMembers', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getOfficeStaff(_, payload: any) {
      try {
        const res = await CubeService.getOfficeStaff(payload);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getKPINewTerminatedAgentsCount({ commit, state }, params: any) {
      try {
        const res = await CubeService.getKPINewTerminatedAgentsCount(params);
        commit('setKPINewTerminatedAgentsCount', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },
    async getNewTerminatedAgentsCount({ commit, state }, params: any) {
      try {
        const res = await CubeService.getNewTerminatedAgentsCount(params);
        commit('setNewTerminatedAgentsCount', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getAllAgentsCount({ commit, state }, params: any) {
      try {
        const res = await CubeService.getAllAgentsCount(params.startDate, params.endDate);
        commit('setAllAgentsCount', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getTwelveMonthsNewAndTerminatedAgentsCount({ commit, state }, params: any) {
      try {
        const res = await CubeService.getTwelveMonthsNewAndTerminatedAgentsCount(params);
        commit('setTwelveMonthsNewAndTerminatedAgentsCount', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getNewAndTerminatedAgentsCount({ commit, state }, params: any) {
      try {
        const res = await CubeService.getNewAndTerminatedAgentsCount(params);
        commit('setNewAndTerminatedAgentsCount', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getTerminatedAgentCountsByReason({ commit, state }, params: any) {
      try {
        const res = await CubeService.getTerminatedAgentCountsByReason(params);
        commit('setTerminatedAgentCountsByReason', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getMonthlyTerminatedAgents({ commit, state }, params: any) {
      try {
        const res = await CubeService.getMonthlyTerminatedAgents(params);
        commit('setMonthlyTerminatedAgents', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },
    async getTestQuery({ commit, state }, params) {
      try {
        const res = await CubeService.getTestQuery();
        commit('setTestQuery', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getGCIByPrincipal({ commit, state }, params) {
      try {
        const res = await CubeService.getGCIByPrincipal();
        commit('setGCIByPrincipal', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getGCIByOther({ commit, state }, params) {
      try {
        const res = await CubeService.getGCIByOther();
        commit('setGCIByOther', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getGCIByAgents({ commit, state }, params) {
      try {
        const res = await CubeService.getGCIByAgents();
        commit('setGCIByAgents', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getGCIByBroker({ commit, state }, params) {
      try {
        const res = await CubeService.getGCIByBroker();
        commit('setGCIByBroker', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getGCIContributionsByPrimaryTitle({ commit, state }, params) {
      try {
        const res = await CubeService.getGCIContributionsByPrimaryTitle();
        commit('setGCIContributionsByPrimaryTitle', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getOfficeTenure({ commit, state }, params) {
      try {
        const res = await CubeService.getOfficeTenure();
        commit('setOfficeTenure', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getYTDGCIandTransactions({ commit, state }, params) {
      try {
        const res = await CubeService.getYTDGCIandTransactions(params);
        commit('setYTDGCIandTransactions', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getYTDOfficeStatuses({ commit, state }, params) {
      try {
        const res = await CubeService.getYTDOfficeStatuses(params);
        commit('setYTDOfficeStatuses', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getYTDOfficeStatusesSignedLastYear({ commit, state }, params) {
      try {
        const res = await CubeService.getYTDOfficeStatusesSignedLastYear(params);
        commit('setYTDOfficeStatusesSignedLastYear', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },
    
    async getActiveOfficesCard({ commit, state }, params) {
      try {
        const res = await CubeService.getActiveOfficesCard(params);
        commit('setActiveOfficesCard', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getYTDOfficeCounts({ commit, state }, params) {
      try {
        const res = await CubeService.getYTDOfficeCounts(params);
        commit('setYTDOfficeCounts', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getYTDOfficeCountsPreviousYear({ commit, state }, params) {
      try {
        const res = await CubeService.getYTDOfficeCountsPreviousYear(params);
        commit('setYTDOfficeCountsPreviousYear', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getYTDAgentCountByRegion({ commit, state }, params) {
      try {
        const res = await CubeService.getYTDAgentCountByRegion(params);
        commit('setYTDAgentCountByRegion', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getYTDAgentCountByRegionPreviousYear({ commit, state }, params) {
      try {
        const res = await CubeService.getYTDAgentCountByRegionPreviousYear(params);
        commit('setYTDAgentCountByRegionPreviousYear', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getAgentRecruitedAndTerminated({ commit, state }, params) {
      try {
        const res = await CubeService.getAgentRecruitedAndTerminated(params);
        commit('setAgentRecruitedAndTerminated', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getNETGci({ commit, state }, params) {
      try {
        const res = await CubeService.getNETGci(params);
        commit('setNETGci', res.data.data);
        const res2 = await CubeService.getNETGciMonthly(params);
        commit('setNetGciMonthly', res2.data.data);
        const res3 = await CubeService.getNETGciMonthlyPreviousYear(params);
        commit('setNetGciMonthlyPreviousYear', res3.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getAgentRecruitedAndTerminatedMonthly({ commit, state }, params) {
      try {
        const res = await CubeService.getAgentRecruitedAndTerminatedMonthly(params);
        commit('setAgentRecruitedAndTerminatedMonthly', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getOfficeGCITrend({ commit, state }, params) {
      try {
        const res = await CubeService.getGCITrendByOffice();
        commit('setOfficeGCITrend', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getOfficeTransactionTrend({ commit, state }, params) {
      try {
        const res = await CubeService.getTransactionTrendByOffice();
        commit('setOfficeGCITrend', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getOfficeAgentProductivity({ commit, state }, params) {
      try {
        const res = await CubeService.getOfficeAgentProductivityByOffice();
        commit('setOfficeGCITrend', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getFullWeekAgentRecruited({ commit, state }, params: any) {
      try {
        const res = await CubeService.getFullWeekAgentRecruited(params);
        commit('setFullWeekAgentRecruited', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getBPlanMonthlyAgentCounts({ commit, state }, params: any) {
      try {
        const res = await CubeService.getBPlanMonthlyAgentCounts(params);
        commit('setBPlanMonthlyAgentCounts', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getBPKpis({ commit, state }, params: any) {
      try {
        const res = await CubeService.getBPKpis(params);
        commit('setBPKpis', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getBillableTitlesAgents({ commit, state }, params: any) {
      try {
        const res = await CubeService.getBillableTitlesAgents(params);
        commit('setBillableTitlesAgents', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getOfficesPerfAgents({ commit, state }, params: any) {
      try {
        const res = await CubeService.getOfficesPerfAgents(params);
        commit('setOfficesPerfAgents', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getAgentsCountPerOffice({ commit, state }, params: any) {
      try {
        const res = await CubeService.getAgentsCountPerOffice(params);
        commit('setAgentsCountPerOffice', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getActiveOfficesYTDGCI({ commit, state }, params: any) {
      try {
        const res = await CubeService.getActiveOfficesYTDGCI(params);
        commit('setActiveOfficesYTDGCI', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getOfficesYTDGCI({ commit, state }, params: any) {
      try {
        const res = await CubeService.getOfficesYTDGCI(params);
        commit('setOfficesYTDGCI', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getNewTerminatedAgentCount({ commit, state }, params: any) {
      try {
        const res = await CubeService.getNewTerminatedAgentCount(params);
        if(res.data.data == undefined) {
          commit('setNewTerminatedAgentCount', res.data);
        } else {
          commit('setNewTerminatedAgentCount', res.data.data);
        }
        // commit('setNewTerminatedAgentCount', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getOfficesPerfGCI({ commit, state }, params: any) {
      try {
        const res = await CubeService.getOfficesPerfGCI(params);
        commit('setOfficesPerfGCI', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getOfficesPerfGCIPreviousYear({ commit, state }, params: any) {
      try {
        const res = await CubeService.getOfficesPerfGCIPreviousYear(params);
        commit('setOfficesPerfGCIPreviousYear', res.data ? res.data.data : []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getPerformanceVBusinessPlanGCI({ commit, state }, params) {
      try {
        const res = await CubeService.getPerformanceVBusinessPlanGCI();
        commit('setPerformanceVBusinessPlanGCI', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getPerformanceVsBusinessPlan({ commit, state }, params) {
      try {
        const res = await CubeService.getPerformanceVsBusinessPlan(params);
        commit('setPerformanceVsBusinessPlan', res.data.data);
        return res;
      } catch (e) {
        throw e;
      }
    },

    //BD filter in Office Overview
    async getBDListForRegion({commit, state}, params){
      try {
        const res = await CubeService.getBDListForRegion(params);
        commit('setBDListForRegion', res);
        const res2 = await CubeService.getBDListWithDatahubOfficeID(res);
        commit('setBDListWithDatahubOfficeID', res2);
        const res3 = await CubeService.getBDFirstAndLastName(res);
        commit('setBDFirstAndLastName', res3);
        return;
      }
      catch (e) {
        throw e;
      }
    },

   async getSelectedBDOffices({commit, state}, params){
    try{
      const res = await CubeService.getSelectedBDOffices(params);
      commit('setBDListForSelectedPerson', res);
      return;
    } catch (e) {
      throw e;
    }
    },
   
  async getOfficeYTDGCI({commit, state}, params){
    try{
      const res = await CubeService.getOfficeGciByYear(params.officeID, new Date().getFullYear());
      commit('setOfficeYTDGCI', res);
      return Array.isArray(res) && res.length ? res[0] : null;
    } catch (e) {
      throw e;
    }
  }

  },
};

function getDefaultState() {
  return {
    // Your default state values
    onHoldOffices: null,
    onHoldOverdueOffices: null,
    renewalPendingOffices: null,
    upcomingRenewals12Months: null,
    officeOverviewTableData1: null,
    officeOverviewTableData1PreviousYear: null,
    officeOverviewTableData2AgentCount: null,
    officeOverviewTableData2: null,
    officeOverviewTableData4: null,
    officeOverviewTableData5: [],
    unfilteredData: null,
    trendAvgAgentPerOffice: '',
    gciContributionsByPrimaryTitlesPie: null,
    gciContributionsByPrimaryTitlesPiePreviousYear: null,
    gciContributionsByPrimaryTitlesPieOther: null,
    gciContributionsByPrimaryTitlesPieOtherPreviousYear: null,
    officeCubeData: null,//slowly deprecating this
    recrutingsCubeData: null,
    totalActiveOfficesCard: null,
    regionRank: null,
    officeStatuses: null,
    personGCIForOffice: null,
    allGCI: null,
    agentCounts: null,
    testQuery: null,
    gciContributionsByPrimaryTitle: null,
    gciByAgents: null,
    gciByBroker: null,
    gciByOther: null,
    gciByPrincipal: null,
    officeTenure: null,
    businessPlanSectionData: null,
    businessPlanKPIData: null,

    ytdGCIandTransactions: null,
    ytdOfficeStatuses: null,
    agentRecruitedAndTerminated: null,
    netGci: 0,
    netGciSelectedYear: 0,
    netGciPreviousYear: 0,
    netGciMonthly: null,
    netGciMonthlyPreviousYear: 0,
    netTransactions: 0,
    netTransactionsPreviousYear: 0,

    //office overview dashboard
    officeGCITrend: null,
    officeTransactionTrend: null,
    officeAgentProductivity: null,
    officeYTDSalesData: null,
    officeYTDRentalData: null,
    officeYtdGCIPerAgentTenure: null,
    terminatedAgentCounts: null,
    monthlyTerminatedAgents: [],
    newTerminatedAgentsCount: [],
    kpiNewTerminatedAgentsCount: [],
    monthlyAgentCounts: [],
    monthlyRecruitedAgents: monthKeys,
    agentsCountPerMonth: monthKeys,
    agentsCountPerPeriod: [],
    terminatedAgentsCountPerMonth: monthKeys,
    newAndTerminatedAgentsCount: [],
    twelveMonthsNewAndTerminatedAgentsCount: [],
    terminatedAgentCountsByReason: [],
    allAgentsCount: [],
    ytdAgentRecruited: 0,
    ytdAgentTerminated: 0,
    fullWeekAgentRecruited: [],
    businessPlanMonthlyAgentCounts: [],
    bpKpis: [],
    bpkpiValues: {
      agents_rec_per_net_growth: 0,//REC/NET ratio from Business Plan
      agents_terminated: 0,//Terminated agents
      agents_recruited: 0, //Recruited agents
      agents_agent_count: 0, //Agents
      agents_net_growth: 0 //Agent Net growth
    },
    weekRegionRank: 0,
    bpAgentCountTotal: 0,
    previousYearAgentsCount: 0,
    fullWeekAgentRecruitedCount: 0,
    ytdAgentNetGrowthPerRegion: 0,
    ytdAgentRecruitedCountPerRegion: 0,
    ytdAgentTerminatedCountPerRegion: 0,
    ytdGCIOfficeTenure: [],
    agentsCountPerOffice: [],
    regionId: null,
    netMonth: new Date().getMonth(), //defaulted to current month
    selectedMonth: new Date().getMonth(), //defaulted to current month
    selectedYear: new Date().getFullYear(), //defaulted to current year
    startDate: new Date().getFullYear() + '-01-01', //defaulted to first day of the year
    // endDate: new Date(new Date().getFullYear(), 11, 31), //defaulted to last day of the year
    //default endDate to 16 of the current month based endDate of billing period 15 of every month
    endDate: `${new Date().getFullYear()}-${ reportMonth() }-15`,
    reportingPeriodForDatePicker: null,
    
    selectedYearCommissionAndTransactionChartData: null,
    selectedYearCommissionAndTransactionChartDataGCI: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    previousYearCommissionAndTransactionChartDataGCI: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    twoYearsAgoCommissionAndTransactionChartDataGCI: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],

    selectedYearCommissionAndTransactionChartDataTrans: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    previousYearCommissionAndTransactionChartDataTrans: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    twoYearsAgoCommissionAndTransactionChartDataTrans: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],

    selectedYearCommissionAndTransactionChartDataTransactions: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    previousYearCommissionAndTransactionChartDataTransactions: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    twoYearsAgoCommissionAndTransactionChartDataTransactions: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],

    agentRecruitedAndTerminatedMonthly: null,
    agentRecruitedAndTerminatedMonthlyCurrentYear: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    agentRecruitedAndTerminatedMonthlyPreviousYear: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    agentRecruitedAndTerminatedMonthlyTwoYearsAgo: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  
    agentTerminatedMonthlyCurrentYear: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    agentTerminatedMonthlyPreviousYear: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    agentTerminatedMonthlyTwoYearsAgo: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],

    ytdGCI: 0,
    ytdGCIPreviousYear: 0,
    ytdTransactions: 0,
    ytdTransactionsPreviousYear: 0,

    activeOfficesCard: 0,
    ytdOfficeCounts: 0,
    ytdOfficeCountsSelectedYear: 0,
    ytdOfficeCountSelectedYear: 0,
    ytdOfficeCountPreviousYear: 0,

    ytdProcessedOfficeCount: 0,
    ytdProcessedOfficeCountPreviousYear: 0,
    ytdOpeningsOfficeCount: 0,
    ytdOpeningsOfficeCountPreviousYear: 0,
    ytdTerminationsOfficeCount: 0,
    ytdTerminationsOfficeCountPreviousYear: 0,
    ytdRecruitedAgentCount: 0,
    ytdRecruitedAgentCountPreviousYear: 0,
    ytdTerminatedAgentCount: 0,
    ytdTerminatedAgentCountPreviousYear: 0,

    ytdAgentCountByRegion: 0,
    ytdAgentCountByRegionSelectedYear: 0,
    ytdAgentCountPreviousYear: 0,

    //Relates to red line target indicator
    performanceVBusinessPlanGCI: 0,
    performanceVsBusinessPlan: 0,
    performanceVsBusinessPlanGCI: 0,
    performanceVsBusinessPlanTransactions: 0,
    performanceVsBusinessPlanOffices: 0,
    performanceVsBusinessPlanAgents: 0,
    performanceVsBusinessPlanSalesProcessed: 0,
    performanceVsBusinessPlanRecruitedAgents: 0,
    performanceVsBusinessPlanOpenedOffices: 0,
    performanceVsBusinessPlanTerminatedAgents: 0,
    performanceVsBusinessPlanTerminatedOffices: 0,
    performanceVsBusinessPlanAgentNetGrowth: 0,

    //Relates to max value for the chart
    performanceVBusinessPlanGCITotal: null,
    performanceVsBusinessPlanTotal: null,
    performanceVsBusinessPlanGCITotal: null,
    performanceVsBusinessPlanTransactionsTotal: null,
    performanceVsBusinessPlanOfficesTotal: null,
    performanceVsBusinessPlanAgentsTotal: null,
    performanceVsBusinessPlanSalesProcessedTotal: null,
    performanceVsBusinessPlanRecruitedAgentsTotal: null,
    performanceVsBusinessPlanOpenedOfficesTotal: null,
    performanceVsBusinessPlanTerminatedAgentsTotal: null,
    performanceVsBusinessPlanTerminatedOfficesTotal: null,
    performanceVsBusinessPlanAgentNetGrowthTotal: null,

    performanceVsBusinessPlanGCITargetBar: null,
    performanceVsBusinessPlanGCITotalBar: null,
    performanceVsBusinessPlanTargetBar: null,
    performanceVsBusinessPlanTotalBar: null,
    performanceVsBusinessPlanOfficesTargetBar: null,
    performanceVsBusinessPlanOfficesTotalBar: null,
    performanceVsBusinessPlanAgentsTargetBar: null,
    performanceVsBusinessPlanAgentsTotalBar: null,
    performanceVsBusinessPlanSalesProcessedTargetBar: null,
    performanceVsBusinessPlanSalesProcessedTotalBar: null,
    performanceVsBusinessPlanAgentCountRecruitedTargetBar: null,
    performanceVsBusinessPlanAgentCountRecruitedTotalBar: null,
    performanceVsBusinessPlanOpenedOfficesTargetBar: null,
    performanceVsBusinessPlanOpenedOfficesTotalBar: null,
    performanceVsBusinessPlanTerminatedAgentsTargetBar: null,
    performanceVsBusinessPlanTerminatedAgentsTotalBar: null,
    performanceVsBusinessPlanTerminatedOfficesTargetBar: null,
    performanceVsBusinessPlanTerminatedOfficesTotalBar: null,
    performanceVsBusinessPlanAgentNetGrowthTargetBar: null,
    performanceVsBusinessPlanAgentNetGrowthTotalBar: null,
    officesPerfAgents: [],
    activeOfficesYTDGCI: [],
    officesYTDGCI: [],
    officeYTDGCI: [],
    billableTitlesAgents: [],
    officesPerfGCI: [],
    officeById: [],
    activeOffices: [],
    filteredOfficeMembers: [],
    newAgentCounters: [],
    unproductiveOfficesCardSelected: false,
    newTerminatedAgentCounters: [],
    prevousYearAgentsCount: [],
    officesPerfGCIPreviousYear: [],
    baseTableData: [],
    lastYearAgentsCountPerRegion: [],
  };
}
