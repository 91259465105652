import TC from '../template_utilities/templateColumns';
import NC from '../template_utilities/PeriodicalNestedColumns';
import panel from './settingspanel.vue';

export const regional_performance_periodical_optimized: any = {
  cubeParameters: {
    measures: [
      'PerformanceCube.gciForPeriod',
      'PerformanceCube.gciForPeriodEur',
      'PerformanceCube.gciForPeriodRxd',
      'PerformanceCube.transactionForPeriod',
      'PerformanceCube.volumeForPeriod',
      'PerformanceCube.volumeForPeriodEur',
      'PerformanceCube.volumeForPeriodRxd',
    ],
    dimensions: ['PerformanceCube.regionid', 'RegionCube.reportingcurrency', 'RegionCube.region'],
    OfficePerformanceCube: 'PerformanceCube',
    timeParameter: 'PerformanceCube.transactiondate',
    mainCubeParameter: 'PerformanceCube',
    filterParameter: 'PerformanceCube.transactiondate',
  },
  alternativeCubeParameters: {
    measures: ['billableTitleChanges.newAgentCount', 'billableTitleChanges.terminationAgentCount'],
    dimensions: ['billableTitleChanges.regionid', 'RegionCube.region', 'RegionCube.reportingcurrency'],
    order: [['RegionCube.region', 'asc']],
    mainCubeParameter: 'billableTitleChanges',
    filterParameter: 'billableTitleChanges.regionid',
    timeParameter: 'billableTitleChanges.recruitingdate',
  },
  columns: [TC.region],
  nestedColumns: NC.teamPerformanceAggregated,
  pivot: {
    x: ['measures'],
    y: ['OfficePerformanceCube.transactiondate', 'RegionCube.reportingcurrency'],
  },
  settingsPanel: panel,
};
