export const getDefaultState = () => ({
	personAnnualDues: {
		list: [],
		loading: false,
		totalRows: 0,
		currentPage: 1,
		totalPages: 1,
		rowsPerPage: 35,
		queryParams: {
			sort: 'displayName',
		},
	},
});
