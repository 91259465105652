export const getDefaultState = () => ({
	roles: [],
	
	contacts: {
		list: [],
		loading: false,
		totalRows: 0,
		currentPage: 1,
		totalPages: 1,
		rowsPerPage: 30,
		queryParams: {
			filter: {
				filter_placeholder: 'flt',
				is_preview: '0',
				flt_external_regional: [ '0', '3', '1', '2' ],
				flt_status: '0'
			},
			datatableSettings: {
				"draw": "0",
				"columns": [
					{
						"data": "contact_type",
						"name": "",
						"searchable": "true",
						"orderable": "true",
						"search": {
							"value": "",
							"regex": "false"
						}
					},
					{
						"data": "fullname",
						"name": "",
						"searchable": "true",
						"orderable": "true",
						"search": {
							"value": "",
							"regex": "false"
						}
					},
					{
						"data": "remax_title",
						"name": "",
						"searchable": "true",
						"orderable": "true",
						"search": {
							"value": "",
							"regex": "false"
						}
					},
					{
						"data": "display_title",
						"name": "",
						"searchable": "true",
						"orderable": "true",
						"search": {
							"value": "",
							"regex": "false"
						}
					},
					{
						"data": "region_name",
						"name": "",
						"searchable": "true",
						"orderable": "true",
						"search": {
							"value": "",
							"regex": "false"
						}
					},
					{
						"data": "company_name_cont",
						"name": "",
						"searchable": "true",
						"orderable": "true",
						"search": {
							"value": "",
							"regex": "false"
						}
					},
					{
						"data": "email",
						"name": "",
						"searchable": "true",
						"orderable": "true",
						"search": {
							"value": "",
							"regex": "false"
						}
					},
					{
						"data": "phone",
						"name": "",
						"searchable": "true",
						"orderable": "true",
						"search": {
							"value": "",
							"regex": "false"
						}
					},
					{
						"data": "action_tools",
						"name": "",
						"searchable": "true",
						"orderable": "false",
						"search": {
							"value": "",
							"regex": "false"
						}
					}
				],
				"order": [
					{
						"column": "1",
						"dir": "asc"
					}
				],
				"start": "0",
				"length": "",
				"search": {
					"value": "",
					"regex": "false"
				}
			},
		},
	},
});
