

export default function subtractOneDayIfLeapYear(dateString) {
    const [year, month, day] = dateString.split('-').map(Number);

    if (month === 2 && day === 29) {
        const previousDay = day - 1;
        const previousMonth = month;
        const previousYear = year;
        
        return `${previousYear}-${previousMonth.toString().padStart(2, '0')}-${previousDay.toString().padStart(2, '0')}`;
    }

    return dateString;
}