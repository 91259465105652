export const accessRoles = [
    {
        "id": 1,
        "name": "SysAdmin - k.schneider",
        "slug": "sysadmin_-_k_schneider"
    },
    {
        "id": 2,
        "name": "Europe Admin",
        "slug": "europe_admin"
    },
    {
        "id": 3,
        "name": "Broker/Owner",
        "slug": "broker_owner"
    },
    {
        "id": 4,
        "name": "Regional Admin",
        "slug": "regional_admin"
    },
    {
        "id": 5,
        "name": "Regional Director",
        "slug": "regional_director"
    },
    {
        "id": 6,
        "name": "Regional View (limited)",
        "slug": "regional_view__limited_"
    },
    {
        "id": 7,
        "name": "Regional View (All)",
        "slug": "regional_view__all_"
    },
    {
        "id": 8,
        "name": "Europe View",
        "slug": "europe_view"
    },
    {
        "id": 9,
        "name": "Regional Admin (ISR)",
        "slug": "regional_admin__isr_"
    }
];
