import { promiseAll } from '@/utils/promiseAll';
import { TeamEditorService } from '../../../services/modules/teamEditor.service';

export async function init(store, teamID) {
	teamID = teamID || store.state.team?.ID;
	try {
		store.commit('setState', { loading: true });
		await promiseAll([
			team(store, teamID),
			changeHistory(store, {}, teamID),
		]);
		store.commit('setState', { loading: false });
	} catch(e) { console.log(e); throw e; }
}

export async function team(store, teamID, params = {}) {
	try {
		store.commit('setState', { loading: true })
		if (teamID) {
			const [
				team,
				teamLeader,
			] = await promiseAll([
				TeamEditorService.team(teamID),
				TeamEditorService.teamLeader(teamID),
				persons(store, {}, teamID),
			]);
			 
			let office = null;
			if(team.data?.officeID){
				office = await TeamEditorService.office(team.data?.officeID);
			} 
			else{ 
				office = await TeamEditorService.office(team?.data?.result?.officeID);
			}
			if(office.data?.result!==undefined){

				await store.commit('setState', {
					loading: false,
					team: team.data?.result,
					teamLeader: teamLeader?.data,
					office: office?.data?.result,
				});

			}else{
				await store.commit('setState', {
					loading: false,
					team: team?.data,
					teamLeader: teamLeader?.data,
					office: office?.data,
				});
			}
		}
	} catch(e) { 
		console.log('error initialising team editor:', e)
		console.log(e); /**/ }
}

export async function persons({ commit, state }, params = {}, teamID?: any) {
	try {
		teamID = state.team?.ID || teamID;
		if (teamID) {
			try {
				const { queryParams, ..._params } = { ...params, } as any;
				const qParams = { ...state.persons.queryParams, ...queryParams, };

				commit('setState', { persons: { ...state.persons, loading: true, queryParams: qParams, }, });

				const res = await TeamEditorService.persons(teamID, { ...qParams, });
				commit('setState', {
					persons: {
						..._params,
						loading: false,
						list: res?.data?.result || [],
						queryParams: qParams,
					},
				});
			} catch(e) { 
				commit('setState', { persons: { ...state.persons, loading: false, }, });
				throw e; 
			}
		}
	} catch(e) { console.log(e); /**/ }
}

export async function changeHistory({ commit, state }, params = {}, teamID?: any) {	
	try {
		teamID = state.team?.ID || teamID;
		if (teamID) {
			const { queryParams, ..._params } = { ...params, } as any;
			const currentPage = _params.currentPage || 1;
			const rowsPerPage = _params.rowsPerPage || 35;
			const qParams = { ...state.history.queryParams, ...queryParams, };

			commit('setState', { history: { ...state.history, loading: true, queryParams: qParams, }, });

			const res = await TeamEditorService.history(teamID, {
				...qParams,
				//MAX-1129
				//limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
			});
			const totalRows = res?.data?.info?.numRows || 0;
			commit('setState', {
				history: {
					..._params,
					currentPage,
					rowsPerPage,
					loading: false,
					totalRows,
					totalPages: Math.ceil(totalRows / rowsPerPage),
					list: res?.data?.result || [],
					queryParams: qParams,
				},
			});
		}
	} catch(e) { 
		console.log(e);
		commit('setState', { history: { ...state.history, loading: false, }, });
		throw e; 
	}
}
