import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option) => {
      return (_openBlock(), _createElementBlock("label", {
        key: option,
        class: "mr-4 hover:cursor-pointer"
      }, [
        _withDirectives(_createElementVNode("input", {
          class: "mr-2",
          type: "radio",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($props.modelValue) = $event)),
          value: option.value
        }, null, 8 /* PROPS */, _hoisted_1), [
          [_vModelRadio, $props.modelValue]
        ]),
        _createElementVNode("b", null, _toDisplayString(option.label), 1 /* TEXT */)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}