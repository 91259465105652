import { getDefaultState } from './constants';
import { NotificationService } from '@/services/modules/notifications.service';

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    setDefaultState(state) {
      const _defaultState = getDefaultState();
      Object.keys(_defaultState).forEach((key) => {
        state[key] = _defaultState[key];
      });
    },

    setState(state, partialState) {
      const partial = JSON.parse(JSON.stringify(partialState));
      Object.keys(partial).forEach((key) => {
        state[key] = partial[key];
      });
    },
  },

  actions: {
    async getNotifications({ commit, state }) {
      try {
        commit('setState', { notification: state });
        const res = await NotificationService.getNotifications();
        commit('setState', { notification: res });
        return res;
      } catch (e) {
        commit('setState', { notification: state });
      }
    },

    async getAllUnreadNotifications({ commit, state }) {
      try {
        commit('setState', { viewAll: { loading: true } });
        const res = await NotificationService.getAllUnreadNotifications();
        commit('setState', { unreadNotifications: res?.data?.info.numRows, viewAll: { loading: false } });
        return res;
      } catch (e) {
        commit('setState', { notification: state });
      }
    },

    async removeNotification({ commit, state }, id) {
      try {
        const res = await NotificationService.removeNotification(id);
        return res;
      } catch (e) {
        //
      }
    },

    async viewNotifications({ commit, state }, id) {
      try {
        const res = await NotificationService.viewNotification(id);
        return res;
      } catch (e) {
        //
      }
    },

    async viewAllNotifications({ commit, state }, params) {
      const currentPage = params.currentPage || 1;
      const rowsPerPage = 35;
      try {
        commit('setState', { viewAll: { ...state.notification, loading: true, searchValue: params.searchValue } });
        const res = await NotificationService.viewAllNotifications(params);
        const totalRows = res?.data?.info.numRows || 0;
        commit('setState', {
          viewAll: {
            notification: res,
            currentPage,
            rowsPerPage,
            loading: false,
            totalRows,
            totalPages: Math.ceil(totalRows / rowsPerPage),
          },
        });
        return res;
      } catch (e) {
        //
      }
    },

    async viewFilteredNotifications({ commit, state }, params) {
      console.log(params);
      const currentPage = params.currentPage || 1;
      const rowsPerPage = 35;
      try {
        commit('setState', { viewAll: { ...state.notification, loading: true } });
        const res = await NotificationService.viewFilteredNotifications(params);
        const totalRows = res?.data?.info.numRows || 0;
        console.log({ res });
        commit('setState', {
          viewAll: {
            notification: res,
            currentPage,
            rowsPerPage,
            loading: false,
            totalRows,
            totalPages: Math.ceil(totalRows / rowsPerPage),
          },
        });
        return res;
      } catch (e) {
        //
      }
    },

    async getNotificationTypes({ commit, state }) {
      try {
        const res = await NotificationService.getNotificationTypes();
        return res;
      } catch (e) {
        //
      }
    },
  },
};
