
import { Options, Vue } from 'vue-class-component';
import SideBar from '@/components/Sidebar.vue';
import TopNavigation from '@/components/TopNavigation.vue';
import UiToastList from '@/components/UiToastList.vue';
import { AuthService } from '@/services/auth.service';
import errorModal from '@/components/ErrorModal.vue';
import VueSimpleContextMenu from 'vue-simple-context-menu';
import UiRoot from '@/components/ui/Root.vue';
import Accordion from '@/components/ui/Accordion.vue';
import '@vuepic/vue-datepicker/dist/main.css';

@Options({
  components: {
    SideBar,
    TopNavigation,
    UiToastList,
    errorModal,
    VueSimpleContextMenu,
    UiRoot,
    Accordion,
  },
  async created() {
    document.title = 'RE/MAX Europe Data Hub';
    const apiKey = localStorage.getItem('bearer');
    const user = localStorage.getItem('userInfo');
    AuthService.setBearer(apiKey);
    AuthService.setUserInfo(JSON.parse(user));
    if (user && JSON.parse(user).profilePictureHash) {
      await this.$store.dispatch('auth/getProfilePicture', JSON.parse(user).profilePictureHash);
    }
    this.$store.commit('auth/SET_USER_INFORMATION', JSON.parse(user));
  },
})
export default class App extends Vue {
  get getSideBarState() {
    return this.$store.getters['dom/getSideBarState'];
  }

  CustomContextMenuOptions = [
    {
      name: 'Open link in new tab',
      slug: 'open_link_in_new_tab',
    },
  ];

  CustomContextMenuOpen(event, item) {
    const contextMenu = this.$refs.CustomContextMenu2 as any;
    contextMenu.showMenu(event, item);
  }
  CustomContextMenuClick(event) {
    let route = this.$router.resolve({ path: event.item });
    window.open(route.href);
  }
}
