export function companyToSelectOption(c) {
	let label = c.name || c.corporateName;
	const labelNoHTML = label;
	if (c.companyIsActive === 'i') {
		label += ' - <span class="text-red-400">inactive</span>';
	} else {
		label += ' - <span class="text-green-400">active</span>';
	}
	return {
		value: c.ID,
		label,
		labelNoHTML,
		data: c,				
	};
}
