
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Getter } from 's-vuex-class';
import store from '@/store';

// import { SearchIcon } from "@heroicons/vue/solid";
import VueSimpleContextMenu from 'vue-simple-context-menu';

@Options({
  components: {
    // SearchIcon,
    VueSimpleContextMenu,
  },
})
export default class Breadcrumbs extends Vue {
  get breadcrumbs() {
    var result = [];

    var ExactMatch = this.$route.matched[this.$route.matched.length - 1];

    if (ExactMatch.props.default !== false) {
      const ExactMatchProp = ExactMatch.props as any;
      if (typeof ExactMatchProp.default.breadcrumbs_ancestry !== 'undefined') {
        var breadcrumb = {};
        ExactMatchProp.default.breadcrumbs_ancestry.forEach(function (item, index) {
          var route = this.resolveRouteByPath(this.$router.options.routes[0].children, item);
          if (route) {
            var breadcrumb = this.getBreadcrumbNameAndUrl(route);

            result.push(breadcrumb);
          } else {
            // console.log({
            //   conditional: 'route',
            //   details: item,
            // });
          }
        }, this);

        var route = this.resolveRouteByPath(this.$router.options.routes[0].children, ExactMatch.path);
        breadcrumb = this.getBreadcrumbNameAndUrl(route);

        result.push(breadcrumb);
      }
    }
    return result;
  }

  get breadCrumbName() {
    if (this.$route.fullPath.includes('company'))
      return (
        ' - ' + store.state.company.currentCompany.corporateName + ', ' + store.state.company.currentCompany.uniqueCompanyID
      );
    console.log(store.state.regionEditor.region);
    if (this.$route.fullPath.includes('regions')) return ' - ' + store.state.regionEditor.region.name;
    return '';
  }

  CustomContextMenuOpen(event, item) {
    const contextMenu = this.$refs.CustomContextMenu as any;
    contextMenu.showMenu(event, item);
  }
  CustomContextMenuClick(event) {
    let route = this.$router.resolve({ path: event.item });
    window.open(route.href);
  }
  resolveRouteByPath(haystack, needle) {
    var match = false;
    haystack.forEach(function (item, index) {
      if (needle == item.path) {
        match = item;
      }
      if (typeof item.children !== 'undefined') {
        item.children.forEach(function (item_2, index_2) {
          if (needle == item_2.path) {
            match = item_2;
          }
          if (typeof item_2.children !== 'undefined') {
            item_2.children.forEach(function (item_3, index_3) {
              if (needle == item_3.path) {
                match = item_3;
              }
            });
          }
        });
      }
    });
    return match;
  }
  getBreadcrumbNameAndUrl(route) {
    var breadcrumb_name = route.props.breadcrumb_name;
    if (breadcrumb_name.startsWith(':')) {
      var breadcrumb_dynamic_name_key = breadcrumb_name.substring(1);
      var breadcrumb_dynamic_name = this.$route.params[breadcrumb_dynamic_name_key];
      breadcrumb_name = breadcrumb_dynamic_name;
    }

    var path_array_1 = route.path.split('/');
    var path_array_2 = [];
    var path = route.path;

    if (path.includes(':')) {
      path_array_1.forEach(function (item, index) {
        if (item.startsWith(':')) {
          var path_element_string_key = item.substring(1);
          var path_element_string = this.$route.params[path_element_string_key];
          item = encodeURIComponent(path_element_string);
          path_array_2.push(item);
        } else {
          path_array_2.push(item);
        }
        path = path_array_2.join('/');
      }, this);
    }

    var breadcrumb = {
      breadcrumb_name: breadcrumb_name,
      path: path,
    };
    return breadcrumb;
  }

  CustomContextMenuOptions = [
    {
      name: 'Open link in new tab',
      slug: 'open_link_in_new_tab',
    },
  ];
}
