const formatterUS = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
const formatterDE = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
const userInfo = JSON.parse(localStorage.getItem('userInfo'));
let globalLocale;
if (userInfo) {
  globalLocale = userInfo.decimalSeperator;
}
const formatter = globalLocale === '.' ? formatterDE : formatterUS;

export default {
  teamPerformanceAggregated: [
    {
      data: 'newAgentCount',
      name: 'New Agents',
      nestedColumn: true,
    },
    {
      data: 'terminationAgentCount',
      name: 'Terminations',
      nestedColumn: true,
    },
    {
      data: 'gciForPeriod',
      name: 'GCI',
      transformHTML: (value, currency = null) => {
        return currency + ' ' + formatter.format(value);
      },
      nestedColumn: true,
    },
    {
      data: 'transactionForPeriod',
      name: 'Transactions',
      nestedColumn: true,
    },
    {
      data: 'volumeForPeriod',
      name: 'Volumes',
      nestedColumn: true,
      transformHTML: (value, currency = null) => {
        return currency + ' ' + formatter.format(value);
      },
    },
  ],
  agentPerformanceCombined: [
    {
      data: 'gciForPeriod',
      name: 'GCI',
      transformHTML: (value, currency = null) => {
        return currency + ' ' + formatter.format(value);
      },
      nestedColumn: true,
    },
    {
      data: 'transactionForPeriod',
      name: 'Transactions',
      nestedColumn: true,
    },
    {
      data: 'volumeForPeriod',
      name: 'Volumes',
      nestedColumn: true,
      transformHTML: (value, currency = null) => {
        return currency + ' ' + formatter.format(value);
      },
    },
  ],
  officePerformancePeriodical: [
    {
      data: 'agntnewcntForPeriod',
      name: 'New Agents',
      transformReport: (value) => {
        if (!value) return 0;
        let number = parseFloat(value);
        return number;
      },
    },
    {
      data: 'agntreactivationcntForPeriod',
      name: 'Reactivated Agents',
      transformReport: (value) => {
        if (!value) return 0;
        let number = parseFloat(value);
        return number;
      },
    },
    {
      data: 'agnttrfincntForPeriod',
      name: 'Transfered Agents In',
      transformReport: (value) => {
        return parseInt(value);
      },
    },
    {
      data: 'agnttrfoutcntForPeriod',
      name: 'Transfered Agents Out',
      transformReport: (value) => {
        if (!value) return 0;
        return parseInt(value);
      },
    },
    {
      data: 'agntterminationcntForPeriod',
      name: 'Terminations',
      transformReport: (value) => {
        return parseInt(value);
      },
    },
    {
      data: 'gciForPeriod',
      name: 'GCI',
      transformHTML: (value, currency = null) => {
        return currency + ' ' + formatter.format(value);
      },
      nestedColumn: true,
    },
    {
      data: 'transactionForPeriod',
      name: 'Transactions',
      nestedColumn: true,
    },
    {
      data: 'volumeForPeriod',
      name: 'Volumes',
      nestedColumn: true,
      transformHTML: (value, currency = null) => {
        return currency + ' ' + formatter.format(value);
      },
    },
  ],
  RegionalOfficeCountsPeriodical: [
    {
      data: 'openingCount',
      name: 'New Offices Opened',
      transformHTML: (value) => {
        if (!value) return 0;
        return parseInt(value);
      },
    },
    {
      data: 'terminationCount',
      name: 'Terminations',
      transformHTML: (value) => {
        if (!value) return 0;
        return parseInt(value);
      },
    },
  ],
};
