import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';

export class RolesService extends BaseService {
  static get entity() {
    return 'roles';
  }

  static async getRolesForRegionDropDown() {
    try {
      let response = await this.request().get(`${this.entity}/region?sort=remaxTitle`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
