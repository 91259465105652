import { AnnualDuesPaymentEditorService} from '../../../services/modules/annualDuesPayment.service';

function getPaginated(state, res) {
    const totalRows = Number(res?.data?.info?.numRows || '0');
    const rowsPerPage = state?.rowsPerPage || 35;
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const currentPage = (state?.currentPage || 1) > totalPages ? totalPages : (state?.currentPage || 1);
    return  {
        list: res?.data?.result || [],
        totalRows,
        rowsPerPage,
        currentPage,
        totalPages,
    };
}

//in the editor, this function returns the selected payment
export async function init(store, paymentID) {
    const { state, commit } = store;

	if (paymentID){
        await getPayment(store, paymentID);
    } 
}

export async function getPayment(store, paymentID) {
    const { commit, state} = store;

    paymentID = paymentID || store.state.office.ID;

    commit('setState', { loadingPayment: true, });

    const paymentRes = await AnnualDuesPaymentEditorService.payment(paymentID);
    const payment = paymentRes?.data?.result;

    if(payment){
        commit('setState', {
            payment: { payment },
        });
    }
    //done loading, reset payment
    commit('setState', { loadingPayment: false, });
}
