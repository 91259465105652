import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Typography"], {
      class: "mb-5 font-black text-black",
      size: "md"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($setup.props.title), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_apexchart, {
      width: `${$setup.props.width != undefined ? $setup.props.width : 380}`,
      height: `${$setup.props.height != undefined ? $setup.props.height : 380}`,
      type: "donut",
      options: $setup.props.chartOptions,
      series: $setup.props.series,
      tooltip: _ctx.custom
    }, null, 8 /* PROPS */, ["width", "height", "options", "series", "tooltip"])
  ]))
}