<template>
  <div v-if="this.$route.name !== 'login'">
      <transition-group name="slide" tag="div" class="ui-toast-list component">
        <UiToast
          @remove="onRemove"
          @isLoginPage="isLoginPage"
          v-for="item in toastsList"
          :item="item"
          :key="item.id"
        />
      </transition-group>
    </div>
</template>

<script>
import UiToast from './UiToast'
export default {
  name: 'UiToastList',

  components: {
    UiToast
  },

  watch: {
    $route: {
      handler: 'clearToastList',
      deep: 'true'
    }
  },

  computed: {
    toastsList () {
      return this.$store.state.toast.toastsList
    }
  },

  methods: {
    onRemove (id) {
      this.$store.commit('toast/REMOVE', { id })
    },
    clearToastList () {
      if (this.toastsList.length) {
        this.$store.commit('toast/CLEAR_LIST')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-toast-list.component {
  bottom: 50px;
  right: 50px;
  width: 300px;
  position: fixed;
  z-index: 9999;
}

.slide-leave-active,
.slide-enter-active {
  transition: all 0.2s ease;
}
.slide-enter-from {
  transform: translate(100%, 0);
}
.slide-leave-to {
  opacity: 0;
}

</style>
